import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, Button } from '@fernleaf/util';
import { getCustomer } from '../store/redux';
import apiGetOrders from '../api/getOrders';

import OrderDetail from './OrderDetail/OrderDetail';
import classes from './Orders.module.css';
import PickupProcedure from '../PickupProcedure/PickupProcedure';
import ReceiptModal from '../ReceiptModal/ReceiptModal';

// const sortByOrderDate = ( a, b ) => (
//     new Date( a.orderDate ) < new Date( b.orderDate ) ? +1 :
//     new Date( a.orderDate ) > new Date( b.orderDate ) ? -1 :
//                                                          0
// );

const sortByPickupDate = ( a, b ) => (
    new Date( a.pickupDateAndTime ) < new Date( b.pickupDateAndTime ) ? +1 :
    new Date( a.pickupDateAndTime ) > new Date( b.pickupDateAndTime ) ? -1 :
                                                         0
);

const dateFrom = ( date, short ) => (
    new Date( date ).toString( 'en-US' ).substr( 4, short ? 6 : 11 )
);

const Orders = () => {
    const dispatch = useDispatch();
    const user = useSelector( selectors.getUser );
    const customer = useSelector( getCustomer );
    const [ emailValues, setEmailValues ] = useState( null );
    const [ loaded, setLoaded ] = useState( false );
    const [ orders, setOrders ] = useState( null );
    const [ showOrder, setShowOrder ] = useState( null );

    useEffect( () => {
        if ( ! orders && user ) {
            apiGetOrders( 
                customer ? customer.id : user.id, 
                ( orders ) => {
                    setOrders( 
                        orders && orders
                            .filter( order => order.squarePaymentId )
                            .sort( sortByPickupDate ) );
                    setLoaded( true );
                }, dispatch );
        }
    }, [ customer, user, orders, dispatch ] );

    const hideEmailPopup = useCallback( (e) => {
        e && e.stopPropagation && e.stopPropagation();
        setEmailValues( null );
    }, [ setEmailValues ] )

    const showEmailPopup = useCallback( (e) => {
        e.stopPropagation();
        const orderId = e.target.getAttribute( 'data-orderid' );
        for ( const order of orders ) {
            if ( orderId === order._id ) {
                setEmailValues(
                    { orderId: order._id, email: customer ? customer.email : order.email }
                );
                break;
            }            
        }
    }, [ customer, orders ] );

    return ( ! Object.isEmpty( user ) && 
        <React.Fragment>
            <div className={ classes.Main } >
                <h2>Completed Orders</h2>
                { ! orders || orders.length === 0 ?
                    <div className={ classes.NoOrders }>
                        { loaded ? "You have no completed orders." : "" }
                    </div>
                :
                    <div className={ classes.Orders } >
                        <div className={ [ classes.Order, classes.Header ].join( ' ' ) } >
                            <div className={ classes.Date } >Order Date</div>
                            <div className={ classes.Date } >Pickup Date</div>
                            <div className={ classes.Receipt }>Receipt</div>
                        </div>
                        { orders && orders.map( order => (
                            <div key={ order._id } onClick={ () => setShowOrder( order ) }
                                className={ classes.Order } 
                            >
                                <div className={ classes.Date } >
                                    { dateFrom( order.orderDate ) }
                                </div>
                                <div className={ classes.Date } >
                                    { dateFrom( order.pickupDateAndTime, true ) }
                                </div>
                                <div className={ classes.Receipt }>
                                    { order.squareReceiptLink ?
                                        <a href={ order.squareReceiptLink } target='_blank'
                                            rel='noopener noreferrer'
                                            onClick={ e => e.stopPropagation() }
                                        >Receipt</a>
                                    : order.squareReceiptNumber ?
                                        <div># { order.squareReceiptNumber }</div>
                                    :
                                        null
                                    }
                                </div>
                                { order.squareReceiptLink &&
                                    <Button className={ classes.SendButton }
                                        data-orderid={ order._id }
                                        onClick={ showEmailPopup }
                                    >
                                        Send Receipt
                                    </Button>
                                }
                            </div>
                        ) ) }
                        { emailValues &&
                            <ReceiptModal
                                orderId={ emailValues.orderId }
                                initialEmail={ emailValues.email }
                                onReceiptSent={ hideEmailPopup }
                                onClose={ hideEmailPopup }
                            />
                        }
                    </div>
                }
                <div className={ classes.Footer } >
                    For order details click on the order date.
                </div>
            </div>

            { showOrder && 
                <OrderDetail order={ showOrder } onClose={ () => setShowOrder( null ) } >
                    <div className={ classes.PickupProcedure } >
                        <h3>Pickup Procedure:</h3>
                        <PickupProcedure />
                    </div>
                </OrderDetail>
            }
        </React.Fragment>
    )
}

export default Orders;