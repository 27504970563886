import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import './index.css';
import './SquarePaymentForm.css';
import { Library } from '@fernleaf/util/dist';
import '@fernleaf/util/dist/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

Library( React );

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={ store }>
                <App />
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById( 'root' )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
