import React, { useCallback, useState, useRef, useEffect, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Thumbnail } from '@fernleaf/util';

import { anyAvailable } from '../stockControl/stockControl';
import { 
    getMenus, getProductSelectorId, getItemOrderControls, showProducts, getCustomer,
    getPickupDate, setPickupDate, getSearch, setSearch, 
    setShowMainPage
} from '../store/redux';

import Alerts from '../Alerts/Alerts';
import ProductOrder from './ProductOrder/ProductOrder';
import classes from './Products.module.css';

const formatPrice = price => {
    if ( typeof price !== 'number' ) {
        return price;
    }
    return ( price / 100 ).toFixed( 2 );
}

const Products = () => {
    const dispatch = useDispatch();
    const menus = useSelector( getMenus );
    const selectorId = useSelector( getProductSelectorId );
    const orderingForCustomer = useSelector( getCustomer );
    const pickupDate = useSelector( getPickupDate );
    const itemOrderControls = useSelector( getItemOrderControls );
    const search = useSelector( getSearch );
    const [ selectedProduct, setSelectedProduct ] = useState();
    const [ previousSectionId, setPreviousSectionId ] = useState();
    const [ returnToMain, setReturnToMain ] = useState( false );
    
    const sectionRef = useRef();

    useEffect( () => {
        if ( selectorId !== previousSectionId ) {
            setPreviousSectionId( selectorId );
            if ( selectorId && sectionRef && sectionRef.current ) {
                sectionRef.current.scrollIntoView( { behavior: 'smooth' } );
            }
        }
    }, [ selectorId, previousSectionId ] );

    useEffect( () => {
        if ( orderingForCustomer && orderingForCustomer.inPerson && ! pickupDate ) {
            dispatch( setPickupDate( new Date() ) );
        }
    }, [ orderingForCustomer, pickupDate, dispatch ] );

    const availableItem = useCallback( ( variation ) => ( 
        anyAvailable( variation, itemOrderControls )
    ), [ itemOrderControls ] );

    const showSection = useCallback( (e) => {
        e.stopPropagation();
        dispatch( setSearch( null ) );
        const currentSectionId = e.target.getAttribute( 'data-sectionid' );
        dispatch( showProducts( currentSectionId === previousSectionId ? null : currentSectionId ) )
    }, [ previousSectionId, dispatch ] );

    const hideSections = useCallback( (e) => {
        e.stopPropagation();
        setReturnToMain( true );
        if ( orderingForCustomer ) {
            dispatch( setShowMainPage( true ) );    
        }
    }, [ setReturnToMain, orderingForCustomer, dispatch ] );

    const sectionHeader = useCallback( section => (
        <div key={ section._id } className={ classes.Header }
            data-sectionid={ section._id }
            onClick={ showSection } 
        >
            { section.header }
        </div>
    ), [ showSection ] );

    if ( localStorage.adminParms && ! orderingForCustomer ) { // Admin ordering - wait for customer to load
        return <div>&nbsp;</div>
    }

    if ( ! menus ) { // Wait until menus have loaded
        return <div>&nbsp;</div>
    }

    if ( returnToMain ) {
        return <Redirect to='/' />;
    }

    let variations = [];
    const sections = [];

    let defaultPicture = '/pictures/logos/logo-online.png';
    let currentSection = null;
    let currentSectionIndex = -1;
    let searchSection;
    let searchExpression;
    let searchItems = {};

    if ( search ) {
        searchSection = {
            _id: 'search',
            header: `Searching for: ${ search }`,
            type: 'Items',
            url: '/pictures/other/looking-glass.svg',
            items: []
        }
        sections.push( searchSection );
        searchExpression = new RegExp( search, 'i' );
    }

    for ( const menu of menus ) {
        if ( menu.type === 'Admin' && ! orderingForCustomer ) {
            // Only show Admin sections to admin users
            continue;
        }

        for ( let index = 0; index < menu.sections.length; index++ ) {
            const section = menu.sections[ index ];
            if ( section.type !== 'Items' || ! section.url || section.items.length === 0 ) {
                continue;
            }
            
            if ( orderingForCustomer && orderingForCustomer.storeOrder ) {
                if ( menu.type !== 'Admin' &&
                    ( menu.minLeadTime.units === 'Minutes' || section.note === 'available today' ) 
                ) {
                    continue;
                }
            }

            const items = orderingForCustomer ? section.items : section.items.filter( availableItem );
            if ( items.length === 0 ) {
                continue;
            }

            sections.push( section );

            if ( search ) {
                for ( const item of items ) {
                    if (
                        searchExpression.test( item.squareCategoryName )  ||
                        searchExpression.test( item.squareItemName )      ||
                        searchExpression.test( item.squareVariationName ) ||
                        searchExpression.test( item.squareDescription )
                    ) {
                        if ( ! searchItems[ item.squareVariationId ] ) {
                            searchItems[ item.squareVariationId ] = true;
                            searchSection.items.push( item );
                        }
                    }
                };
            }

            // TODO - other selectors if ( selectorId === 'ALL' || selectorId === menu._id || selectorId === section._id ) {
            if ( selectorId === section._id ) {
                variations = variations.concat( items );
                currentSection = section;
                defaultPicture = section.url;
                currentSectionIndex = sections.length - 1;
            }
        }
    }

    if ( orderingForCustomer ) {
        // If admin then order sections by name
        sections.sort( ( a, b ) => {
            if ( a === searchSection ) {
                return -1;
            }
            if ( b === searchSection ) {
                return +1;
            }
            return a.header.localeCompare( b.header );
        } );
        if ( currentSection ) {
            currentSectionIndex = sections.indexOf( currentSection );
        }
    }

    if ( search ) {
        currentSection = searchSection;
        currentSectionIndex = 0;
        variations = searchSection.items;
        variations.sort( ( a, b ) => (
            a.squareCategoryName.localeCompare( b.squareCategoryName ) ||
            a.squareItemName.localeCompare( b.squareItemName )         ||
            a.squareVariationName.localeCompare( b.squareVariationName )
        ) );
    }

    if ( ! orderingForCustomer && ! search && ( ! currentSection || variations.length === 0 ) ) {
        return <Redirect to='/' />;
    }

    for ( let index = 1; index < variations.length; index++ ) {
        if ( variations[ index ].squareItemName === variations[ index - 1 ].squareItemName ) {
            variations[ index ].isSibling = true;
            variations[ index - 1 ].isSibling = true;
        }
    }

    const products = [];
    let last;
    let lastPrice;
    for ( let index = 0; index < variations.length; index++ ) {
        const variation = variations[ index ];
        if ( variation.squareItemName === 'Partial Payment' ) {
            continue; // Not user selectable - used for split payments only
        }
        if ( ! variation.pictureURLs || variation.pictureURLs.length === 0 ) {
            variation.pictureURLs = defaultPicture;
        }
        if ( index === 0 || variation.squareItemId !== products[ last ].squareItemId ) {
            products.push( {    
                squareItemId: variation.squareItemId,
                squareItemName: variation.squareItemName,
                squareDescription: variation.squareDescription,           
                pictures: variation.pictureURLs,
                pricePoints: []
            } );
            last = products.length - 1;
        }

        const pricePoints = products[ last ].pricePoints;
        const displayName = ! variation.isSibling &&
            ( variation.squareVariationName.match( / *regular */i ) || 
                variation.squareVariationName.trim() === '' 
            )
                ? null : variations[ index ].squareVariationName.trim();

        if ( pricePoints.length === 0 || pricePoints[ lastPrice ].price !== variation.squarePrice ) {
            pricePoints.push ( {
                variations: [ { ...variation, displayName } ],
                price: variation.squarePrice
            } )
            lastPrice = pricePoints.length - 1;
        } else {
            pricePoints[ lastPrice ].variations.push( { ...variation, displayName } );
        }
    }

    const order = ( event, product ) => {
        event.stopPropagation();
        setSelectedProduct( product );
    }

    return (
        <Fragment>
            { orderingForCustomer &&
                <Alerts />
            }

        <section className={ classes.Main } >

            { sections.filter( 
                ( _s, index ) => index < currentSectionIndex 
            ).map( sectionHeader ) }

            { currentSection && <React.Fragment>
                <div onClick={ showSection } className={ classes.CurrentHeader } ref={ sectionRef } >
                    { currentSection.header }
                </div>
                { products.map( ( product, index ) =>
                    <div key={ index } onClick={ (e) => order( e, product ) } 
                        className={ classes.Product }
                    >
                        <Thumbnail pictures={ product.pictures } onClick={ ( e ) => order( e, product ) }
                            className={ classes.ThumbnailSize }
                        />
                        <div className={ classes.ProductNames } >
                            <div className={ classes.ItemName }>{ product.squareItemName }</div>
                            { ( product.pricePoints.length > 1 || 
                                product.pricePoints[ 0 ].variations[ 0 ].displayName ) &&
                                product.pricePoints.map( pricePoint => 
                                    <div key={ currentSection.header + pricePoint.variations[ 0 ]._id } 
                                        className={ [ 
                                            classes.PricePoints, 
                                            classes.VariationNames 
                                        ].join( ' ' ) }
                                    >
                                        { pricePoint.variations
                                            .map( variation => variation.displayName ).join( ', ' )
                                        }
                                    </div> 
                                )
                            }
                        </div>
                        <div className={ classes.PricePoints } >
                            { product.pricePoints.map( pricePoint => 
                                <div key={ currentSection.header + pricePoint.variations[ 0 ]._id } 
                                    className={ classes.Price } >
                                    { formatPrice( pricePoint.price ) }
                                </div>
                            ) }
                        </div>
                    </div>
                ) }
                </React.Fragment>
            }

            { sections.filter( 
                ( _s, index ) => index > currentSectionIndex 
            ).map( sectionHeader ) }

            <div className={ classes.Header } onClick={ hideSections }>Main page</div>
            { selectedProduct && 
                <ProductOrder product={ selectedProduct } 
                    onCancel={ () => setSelectedProduct( null ) }
                />
            }
        </section>
        </Fragment>
    );
};

export default Products;