import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from '@fernleaf/util';

import {
    addToCart,
    removeFromCart,
    changeCartDetails,
    getOrderItemCount, 
    getOrderItemDetails 
} from '../../../store/redux';

import { ADD, REMOVE } from '../orderActions'
import classes from './CakeDetails.module.css';

const CakeDetails = ( { orderItem, orderAction, onClose } ) => {
    const dispatch = useDispatch();
    let count = useSelector( getOrderItemCount( orderItem.squareVariationId ) );
    const details = useSelector( getOrderItemDetails( orderItem.squareVariationId ) );
    const [ messages, setMessages ] = useState( 
        orderAction === ADD ? [ ...details ].concat( [ '' ] ) : [ ...details ]
    );

    if ( orderAction === ADD ) {
        count++;
    }

    const setMessageFor = ( e, index ) => {
        const newMessages = [ ...messages ];
        newMessages[ index ] = e.target.value;
        setMessages( newMessages );
    }

    const addToOrder = () => {
        dispatch( addToCart( orderItem, messages ) );
        onClose();
    }

    const changeOrder = () => {
        dispatch( changeCartDetails( orderItem, messages ) );
        onClose();
    }
    
    const removeItemAt = index => {
        const newMessages = [ ...messages ];
        newMessages.splice( index, 1 );
        dispatch( removeFromCart( orderItem, newMessages ) );
        onClose();
    }

    return (
        <Modal onClick={ onClose } className={ classes.Modal } 
            contentClassName={ classes.Content }
        >
            <div onClick={ (e) => e.stopPropagation() } className={ classes.Messages } >
                <div className={ classes.Heading } >
                    { `If you would like ${ count === 1 ? 'a message' : 'messages' 
                        } on the ${ orderItem.displayName 
                        } ${ orderItem.squareItemName }${ count === 1 ? '' : 's' 
                        } enter ${ count === 1 ? 'it' : 'them' } below.`
                    }
                </div>

                { messages.map( ( message, index ) =>
                    <div key={ index } className={ classes.MessageLine } >
                        { orderAction === REMOVE ?
                            <Button onClick={ () => removeItemAt( index ) } 
                                className={ classes.Remove }
                            >
                                Remove
                            </Button>
                        :
                            <label>Message{ count > 1 ? ' ' + ( index + 1 ) : '' }:&nbsp;</label>
                        }
                        <input onChange={ (e) => setMessageFor( e, index ) }
                            value={ messages[ index ] } placeholder='No message'
                            maxLength='55' disabled={ orderAction === REMOVE }
                            className={ classes.Message } 
                        />
                    </div>
                ) }

                <div className={ classes.Buttons } >
                    { orderAction !== REMOVE &&
                        <Button onClick={ orderAction === ADD ? addToOrder : changeOrder } >
                            { orderAction === ADD ? 'Add' : 'Change' }
                        </Button>
                    }
                    <Button onClick={ onClose } className={ classes.Cancel } >Cancel</Button>
                </div>
            </div>
        </Modal>
    )
}

export default CakeDetails;