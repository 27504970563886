import React from 'react';
import { format } from 'date-fns';
import { Modal } from '@fernleaf/util';

import CartContents from '../../Cart/CartContents/CartContents';
import CartTotals from '../../Cart/CartTotals/CartTotals';

import classes from './OrderDetail.module.css';

const formatTime = date => (
    date.getHours() === 0 && date.getMinutes() === 0 ? 'Soonest' :
    format( date, 'h:mm aaaa' ).replace( /\./g, '' )
);

const formatDateTime = date => {
    const datePart = format( date, 'MMM d, ' );
    const timePart = formatTime( date );
    return datePart + timePart;
}

const LabeledField = ( { label, value, date, time } ) => {
    value = value && value.trim().length > 0 ? 
                ( date || time ) ? new Date( value ) : value.trim() 
                : null;
    return ( value &&
        <div className={ classes.Field } >
            <div className={ classes.FieldLabel } >{ label }</div>
            <div className={ classes.FieldValue } >
                { 
                    time ? formatDateTime( value ) :
                    date ? format( value, 'MMM d, yyyy' ) :
                    value 
                }
            </div>
        </div>
    );
};
const isAnyPresent = ( thing, fields ) => {
    if ( ! Array.isArray( fields ) ) {
        fields = [ fields ];
    }
    for( const field of fields ) {
        const value = thing[ field ];
        if ( value && value.trim().length > 0 ) {
            return true;
        }
    }
}

const OrderDetail = ( { order, onClose, children } ) => (
    <Modal show onClick={ onClose } className={ classes.Modal } 
        contentClassName={ classes.OrderDetail } noX
    >
        <div className={ classes.X } >&times;</div>

        <div className={ classes.ContactInfo } >
            { order.name === 'SAVED CART' ?
                <div className={ classes.ContactHeader } >Saved cart details:</div>
            :
                <React.Fragment>
                    <div className={ classes.ContactHeader } >Order details:</div>
                    <LabeledField label='Name' value={ order.name } />
                    <LabeledField label='Email' value={ order.email } />
                    <LabeledField label='Phone' value={ order.phone } />
                    { order.taxCert &&
                        <LabeledField label='Tax Cert' value={ order.taxCert } />
                    }
                </React.Fragment>
            }
            <LabeledField label='Order date' value={ order.orderDate } date />
            <LabeledField label='Pickup date/time' value={ order.pickupDateAndTime } time />
        </div>

        { isAnyPresent( order, [ 'pickupName', 'pickupPhone' ] ) &&
            <div className={ classes.PickupInfo } >
                <div className={ classes.ContactHeader } >Pickup details:</div>
                <LabeledField label='Pickup name' value={ order.pickupName } />
                <LabeledField label='Pickup phone' value={ order.pickupPhone } />
            </div>
        }
        { order.pickedUp &&
            <LabeledField label='Picked Up' value={ order.pickedUp } time />
        }

        <div className={ classes.Items } >
            <CartContents cart={ order.items } />
        </div>

        <CartTotals cart={ order.items } tipAmount={ order.tipAmount } taxCert={ order.taxCert } />

        {
            children
        }
    </Modal>
);

export default OrderDetail;