import { post } from './fetch';

const apiCancelCheckout = async ( orderId, swipeCancelled, dispatch ) => {
    post( 'apiCancelCheckout', 
        '/api/square/terminal/cancel-checkout', 
        { orderId },
        swipeCancelled,
        dispatch
    );
}

export default apiCancelCheckout;
