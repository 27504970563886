import React, { useState, useEffect } from "react";
import FadeInOut from "../FadeInOut/FadeInOut";
import Modal from "./Modal";

const TransitionModal = ( props ) => (
    <FadeInOut in={ props.in } timeout={ props.timeout || 1000 } 
        unmountOnExit={ ! props.keepMountedOnExit }
        onEnter={ props.onEnter }
        onExited={ props.onExited } >

        { ( { state } ) => {
            const className = props.classes[ props.baseClass + "-" + state ];
            return (
                <Modal className={ className } rounded={ props.rounded } noX={ props.noX }
                    onClick={ props.onClick } >
                    
                    { props.children }
                </Modal>
            );
        } }
    </FadeInOut>
)

export default TransitionModal;