export const SET_THEME        = 'SET_THEME';

export const SET_EMAIL_STATUS = 'SET_EMAIL_STATUS';
export const SET_USER         = 'SET_USER';
export const INITIALIZED      = 'INITIALIZED';

export const DO_NOTHING       = 'DO_NOTHING';
export const SET_ERROR        = 'SET_ERROR';

export const action = ( type, args ) => ( { type: type, ...args } );

export const setDarkTheme = ()               => action( SET_THEME, { useDarkTheme: true } );
export const setLightTheme = ()              => action( SET_THEME, { useDarkTheme: false } );

export const initEmailReset = ( emailReset ) => action( SET_EMAIL_STATUS, emailReset );
export const resetEmailSent = ()             => action( SET_EMAIL_STATUS, { emailSent: true } );
export const emailResetExpired = ()          => action( SET_EMAIL_STATUS, { isExpired: true } );
export const emailNotFound = ()              => action( SET_EMAIL_STATUS, { notFound: true } );
export const emailBadPassword = ( password ) => action( SET_EMAIL_STATUS, { badPassword: password } );
export const showEmailMessage = ( message )  => action( SET_EMAIL_STATUS, { message } );
export const emailOK = ()                    => action( SET_EMAIL_STATUS, {} );

export const initialize = ()                 => action( INITIALIZED );
export const login = ( user )                => action( SET_USER, { ...user } );
export const logout = ()                     => action( SET_USER, {} );

export const doNothing = ()                  => action( DO_NOTHING );
export const setError = ( error )            => action( SET_ERROR, { error } );