import React, { useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { LoginDialog, actions, selectors } from '@fernleaf/util';
import { getCustomer } from '../store/redux';

import Toolbar from './Navigation/Toolbar/Toolbar';
import SystemMenu from './Navigation/SystemMenu/SystemMenu';

import classes from './Layout.module.css';

const { logout, emailOK } = actions;
const { getUser } = selectors;

const Layout = ( { children, location, history } ) => {
    const dispatch = useDispatch();
    const emailStatus = useSelector( selectors.getEmailStatus );
    const user = useSelector( getUser );
    const customer = useSelector( getCustomer );
    const [ loginRequested, setLoginRequested ] = useState( false );
    const [ showSystemMenu, setShowSystemMenu ] = useState( false );

    const showLogin = (
        loginRequested ||
        ! Object.isEmpty( emailStatus ) ||
        ( location.pathname === '/checkout' && ! ( user && user.id ) ) ||
        ( location.pathname === '/orders' && ! ( user && user.id ) ) ||
        ( location.pathname === '/saved' && ! ( user && user.id ) )
    );

    let loginReturnPath;

    if ( showLogin ) {
        localStorage.setItem( 'loginReturnPath', location.pathname );
    } else {
        loginReturnPath = localStorage.loginReturnPath;
    }
     
    if ( loginReturnPath ) {
        if ( loginReturnPath !== location.pathname ) {
            return <Redirect to={ loginReturnPath } />;
        }
        localStorage.removeItem( 'loginReturnPath' );
    }

    const closeLoginDialog = () => {
        setLoginRequested( false );
        dispatch( emailOK() );
        if ( ! ( user && user.id ) ) {  // Some pages require a valid login
            if ( location.pathname === '/checkout' ) {  // User cancelled checkout login
                history.push( '/cart' );                // go back to the cart.
                localStorage.removeItem( 'loginReturnPath' );
            } else if ( location.pathname === '/orders' || location.pathname === '/saved' ) {
                history.push( '/' );                // go back to the cart.
                localStorage.removeItem( 'loginReturnPath' );
            }
        }
    }

    const closeSystemMenu = () => setShowSystemMenu( false );
    const toggleSystemMenu = (e) => {
        e.stopPropagation();
        setShowSystemMenu( ! showSystemMenu );
    }

    const endSession = async () => {
        if ( user.id ) {
            dispatch( logout() );
            await fetch( '/auth/logout' );
        }
    }

    const toggleSession = async () => {
        if ( user.id ) {
            if ( ! customer ) { // Prevent logout if staff is ordering for a customer
                await endSession();
            }
        } else {
            setLoginRequested( true );
        }
    }

    return (
        <div onClick={ closeSystemMenu } >
            <Toolbar user={ user } toggleSession={ toggleSession }
                showSearch={ location.pathname === '/' || location.pathname === '/products' } 
                toggleMenu={ toggleSystemMenu } />
            <SystemMenu show={ showSystemMenu } toggleSession={ toggleSession } />
            <main className={ classes.Content } >
                { children }
            </main>
            <LoginDialog returnTo='shop' show={ showLogin } onClick={ closeLoginDialog } />
       </div>
    );
}

export default withRouter( Layout );