import React from 'react';
import userIcon from '../assets/images/user.png';
import classes from './UserIcon.module.css';

const UserIcon = ( { active, picture } ) => (
    <img 
        src={ picture || userIcon } 
        className= { [ 
            picture ? classes.Picture : classes.UserIcon,
            active ? classes.Active : ''
         ].join(' ') } 
    />
)

export default UserIcon