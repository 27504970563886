const THEME_SERVER = process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : '';

const Theme = ( { useDarkTheme } ) => {

    let fileNo = 1;

    const loadCss = ( cssFile ) => {
        const elementId = `fernleaf-theme-${ fileNo++ }`;

        const themeCssFile = document.createElement( 'link' );

        themeCssFile.setAttribute( 'id', elementId );
        themeCssFile.setAttribute( 'rel', 'stylesheet' );
        themeCssFile.setAttribute( 'type', 'text/css' );
        themeCssFile.setAttribute( 'href', `${ THEME_SERVER }/fernleaf.${ cssFile }.css` );

        const currentFile = document.getElementById( elementId );
        
        if ( ! currentFile ) {
            document.getElementsByTagName( 'head' )[ 0 ].appendChild( themeCssFile );
        } else {
            currentFile.replaceWith( themeCssFile );
        }
    }
    
    loadCss( 'common' );
    loadCss( useDarkTheme ? 'dark' : 'light' );

    return null;
}

export default Theme;