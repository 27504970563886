import { post } from './fetch';

const apiCreatePartialPaymentOrder = async ( paymentAmount, storedBalanceId, setOrder, dispatch ) => {
    await post( 'apiCreatePartialPaymentOrder', 
        '/api/order/create-partial-payment', 
        { paymentAmount, storedBalanceId },
        setOrder,
        dispatch
    );
}

export default apiCreatePartialPaymentOrder;
