import { get } from './fetch';

const apiGetCustomer = async ( customerId, setCustomer, dispatch ) => {
    await get( 'apiGetCustomer', 
        `/api/customer/user/${ customerId }`, setCustomer,
        dispatch
    );
}

export default apiGetCustomer;
