import { post } from './fetch';

const apiPayOrder = async ( 
    orderId, amount, tipAmount, nonce, typeDetail, verifiedBalance, setPayment, dispatch 
) => {
    const values = { orderId, amount, tipAmount, nonce };
    if ( nonce === 'CASH' ) {
        values.tenderedAmount = typeDetail;
    } else if ( nonce === 'CHECK' || nonce === 'OTHER_GIFT_CARD' ) {
        values.paymentId = typeDetail;
        values.verifiedBalance = verifiedBalance;
    }
    
    await post( 'apiPayOrder', 
        '/api/square/process-payment', 
        values,
        setPayment,
        dispatch
    );
}

export default apiPayOrder;
