import { post } from './fetch';

const apiCreateOrUpdateOrder = async ( 
    contact, pickupContact, pickupDate, cart, cartNotes, orderTotal, orderId, setOrders, dispatch 
) => {
    await post( 'apiCreateOrUpdateOrder', 
        '/api/order/create', 
        { contact, pickupContact, pickupDate, cart, cartNotes, orderTotal, orderId },
        setOrders,
        dispatch
    );
}

export default apiCreateOrUpdateOrder;
