import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal, orderItemUtils } from '@fernleaf/util';

import apiReportSystemError from '../../api/reportSystemError';
import apiPayOrder from '../../api/payOrder';

import { clearCart, setPickupDate } from '../../store/redux';

import classes from './CheckDialog.module.css';

const focusInput = ( inputField ) => inputField && inputField.focus();

const CheckDialog = ( { 
    order, setOrder, paymentTotal, tipAmount, NavigationButtons, onCancel 
} ) => {
    const dispatch = useDispatch();
    const [ blockInput, setBlockInput ] = useState( false );
    const [ checkAmount, setCheckAmount ] = useState( 0 );
    const [ checkNumber, setCheckNumber ] = useState( '' );
    const [ checkPayment, setCheckPayment ] = useState( null );
    const [ timeoutId, setTimeoutId ] = useState( null );
    const [ message, setMessage ] = useState( 'Check amount is required' );
    
    const validateInput = ( checkAmount, checkNumber ) => {
        if ( ! checkAmount ) {
            setMessage( 'Check amount is required' );
        } else if ( checkAmount !== paymentTotal + ( tipAmount || 0 ) ) {
            setMessage( 'Check amount must match order total (including tip, if any)' );
        } else if ( ! checkNumber || checkNumber.trim() === '' ) {
            setMessage( 'Check number is required' );
        }
    }

    const processCheckEntry = ( e ) => {
        clearTimeout( timeoutId );
        setMessage( null );
        const newValue = orderItemUtils.processDollarEntry( e, checkAmount, setCheckAmount );
        setTimeoutId( setTimeout( () => validateInput( newValue, checkNumber ), 1500 ) );
    };

    const processCheckNumber = ( e ) => {
        clearTimeout( timeoutId );
        e.stopPropagation();
        setMessage( null );
        const newValue = e.target.value;
        setCheckNumber( newValue );
        setTimeoutId( setTimeout( () => validateInput( checkAmount, newValue ), 1500 ) );
    };

    const completeCheckPayment = useCallback( (e) => {
        setBlockInput( true );
        apiPayOrder( order._id, paymentTotal, tipAmount, 'CHECK', checkNumber.trim(), null,
            ( reply ) => {
                let message = 'Check payment failed - CHECK FOR ERROR EMAIL.';
                if ( reply && reply.errors && reply.errors[ 0 ].code === 'TIMEOUT' ) {
                    message = 'Check payment timed out - CHECK IN SQUARE TO SEE IF PAYMENT COMPLETED.';
                }
                if ( ! reply.payment ) {
                    const unexpected = JSON.stringify( reply || 'PAYMENT WAS NULL' );
                    apiReportSystemError( 
                        'SHOP010 - Unexpected issue in Square check payment', unexpected, dispatch 
                    );
                    alert( message );
                } else {
                    dispatch( clearCart() );
                    dispatch( setPickupDate( null ) );
                    setOrder( reply.order );
                    setCheckPayment( reply.payment );
                }
                setBlockInput( false );
            },
            dispatch
        );
    }, [ 
        order, paymentTotal, tipAmount, checkNumber, 
        setOrder, setCheckPayment, setBlockInput, dispatch 
    ] );

    return (
        <React.Fragment>
            { checkPayment ?
                <div onClick={ e => e.stopPropagation() } >
                    <div>Check payment has been accepted.</div>
                    <NavigationButtons order={ order } />
                </div>
            :
                <div onClick={ (e) => e.stopPropagation() } >
                    <div className={ classes.Legend }>Enter check amount:</div>
                    <div className={ classes.Message } >{ message || ' ' }</div>
                    <table>
                        <tbody>
                            <tr>
                                <td>Check amount:</td>
                                <td>
                                    <input type='text'
                                        defaultValue={ 
                                            orderItemUtils.formatDollarAmount( checkAmount ) 
                                        } 
                                        placeholder='Check amount' 
                                        className={ classes.CheckAmountInput }
                                        onInput={ processCheckEntry } ref={ focusInput }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Check number:</td>
                                <td>
                                    <input type='text'
                                        defaultValue={ checkNumber } 
                                        placeholder='Check number' 
                                        className={ classes.CheckNumberInput }
                                        onInput={ processCheckNumber }
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Button onClick={ completeCheckPayment }
                        className={ classes.Disabled }
                        disabled={ 
                            ! checkAmount || checkAmount !== paymentTotal + ( tipAmount || 0 ) ||
                            ! checkNumber || checkNumber.trim() === ''
                        }
                    >
                        Accept Check
                    </Button>
                    <Button onClick={ onCancel } >Cancel</Button>
                </div>
            }

            { blockInput && <Modal noX showSpinner /> }

        </React.Fragment>
    )
}

export default CheckDialog;