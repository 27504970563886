import { getWithRetry } from './fetch';

const apiCodeVersion = async ( setCodeVersion, dispatch ) => {
    await getWithRetry( 'apiCodeVersion', 
        '/api/code-version', setCodeVersion,
        dispatch, 60, 2000
    );
}

export default apiCodeVersion;
