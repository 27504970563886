import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import { Button, Modal, selectors } from '@fernleaf/util';

import { 
    getCart, getCartCount, clearCart,
    getCartNotes, changeCartNotes,
    getPickupDate, getCustomer, getOrderToPay,
    returnToAdminAction, 
    setPickupDate
} from '../store/redux';

import apiSaveCart from '../api/saveCart';
import apiCreateStoreOrder from '../api/createStoreOrder';

import ProductOrder from '../Products/ProductOrder/ProductOrder';
import PriceOverrides from '../PriceOverrides/PriceOverrides';
import CartContents from './CartContents/CartContents';
import CartTotals from './CartTotals/CartTotals';
import classes from './Cart.module.css';

const formatDate = date => date.toString( 'en-US' ).substr( 0, 10 );
const formatTime = date => (
    date.getHours() === 0 && date.getMinutes() === 0 ? 'Soonest' :
    format( date, 'h:mm aaaa' ).replace( /\./g, '' )
);

const Cart = () => {
    const dispatch = useDispatch();
    const user = useSelector( selectors.getUser );
    const cart = useSelector( getCart );
    const cartNotes = useSelector( getCartNotes );
    const cartCount = useSelector( getCartCount );
    const pickupDate = useSelector( getPickupDate );
    const customer = useSelector( getCustomer );
    const orderToPay = useSelector( getOrderToPay );
    const [ changeOrderItem, setChangeOrderItem ] = useState( null );
    const [ showClearCart, setShowClearCart ] = useState( false );
    const [ kitchenOrder, setKitchenOrder ] = useState( false );
    const [ showConfirmStoreOrder, setShowConfirmStoreOrder ] = useState( false );
    const [ outOfStock, setOutOfStock ] = useState( null );
    const [ priceOverrideItem, setPriceOverrideItem ] = useState( null );

    const confirmEmptyCart = (e) => {
        e.stopPropagation();
        setShowClearCart( true );
    };

    const emptyCart = (e) => {
        e.stopPropagation();
        dispatch( clearCart() );
        dispatch( setPickupDate( null ) );
        setShowClearCart( false );
    };

    const confirmStoreOrder = (e) => {
        e.stopPropagation();
        setShowConfirmStoreOrder( true );
    };
    const confirmKitchenOrder = (e) => {
        setKitchenOrder( true );
        confirmStoreOrder( e );
    }
    const confirmPackOrder = (e) => {
        setKitchenOrder( false );
        confirmStoreOrder( e );
    }

    const createStoreOrder = (e) => {
        e.stopPropagation();
        apiCreateStoreOrder(
            user.id, kitchenOrder ? pickupDate : null, cart, cartNotes,
            ( errors ) => {
                if ( Object.isEmpty( errors ) ) {
                    dispatch( returnToAdminAction() );
                } else {
                    setShowConfirmStoreOrder( false );
                    setOutOfStock( errors.outOfStock );
                }
            },
            dispatch
        );
    };

    const changePickupDate = useCallback( (e) => {
        e.stopPropagation();
        document.getElementById( 'fernPickupDateTime' ).click();
    }, [] );

    const checkDate = useCallback( (e) => {
        if ( ! pickupDate ) {
            e.preventDefault();
            changePickupDate( e );
        }
    }, [ pickupDate, changePickupDate ] );

    const changePricePoint = useCallback( () => ( {    
        squareItemId: changeOrderItem.variation.squareItemId,
        squareItemName: changeOrderItem.variation.squareItemName,
        squareDescription: changeOrderItem.variation.squareDescription,           
        pictures: changeOrderItem.variation.pictureURLs,
        pricePoints: [ {
            variations: [ changeOrderItem.variation ],
            price: changeOrderItem.variation.squarePrice
        } ]
    } ), [ changeOrderItem ] );

    const updateCartNotes = useCallback( (e) => {
        dispatch( changeCartNotes( e.target.value ) );
    }, [ dispatch ] );

    const saveCart = useCallback( () => {
        if ( Object.isEmpty( user ) ) {
            alert( 'To save a cart you must be logged in. Try again once you have logged.' );
            document.getElementById( 'fernLoginToggle' ).click();
        } else {
            const orderId = localStorage.cartOrderId;
            apiSaveCart( 
                customer ? customer.id : user.id, orderId, pickupDate, cart, cartNotes,
                () => { 
                    dispatch( clearCart() );
                    dispatch( setPickupDate( null ) ) 
                }, 
                dispatch 
            );
        }
    }, [ cart, cartNotes, pickupDate, user, customer, dispatch ] );

    return ( 
        <React.Fragment>
            <div className={ classes.Main } >
                <div className={ classes.Links } >
                    { orderToPay ? <div className={ classes.ContinueShopping } >&nbsp;</div> :
                        <Link to={ customer ? '/products' : '/' } className={ classes.ContinueShopping } 
                        >Continue Shopping</Link>
                    }
                    { cartCount > 0 && ( ! customer || ! customer.storeOrder ) &&
                        <React.Fragment>
                            <Link to='/checkout' onClick={ checkDate } className={ classes.Checkout } >
                                Checkout
                            </Link>
                        </React.Fragment>
                    }
                </div>

                <div className={ classes.OrderPickup } >
                    { pickupDate && ! orderToPay &&
                        <React.Fragment>
                            <div className={ classes.DateLabel } >Pickup date:</div>
                            <div className={ classes.DateValue } >{ formatDate( pickupDate ) }</div>
                            <div className={ classes.DateLabel } >Pickup time:</div>
                            <div className={ classes.DateValue } >{ formatTime( pickupDate ) }</div>
                        </React.Fragment> 
                    }
                    { customer && customer.storeOrder ?
                        <div className={ classes.StoreOrders } >
                            <Button onClick={ confirmKitchenOrder } className={ classes.ChangePickup } >
                                Create Kitchen Order
                            </Button>
                            <Button onClick={ confirmPackOrder } className={ classes.ChangePickup } >
                                Create Packing Order
                            </Button>
                        </div>
                    : cartCount > 0 && ! orderToPay ?
                        <Button onClick={ changePickupDate } className={ classes.ChangePickup } >
                            { pickupDate ? 'Change' : 'Choose' } Pickup Time
                        </Button>
                    :
                        <div className={ classes.ChangePickup } >&nbsp;</div>
                    }
                </div>

                <div className={ classes.Items } >
                    <CartContents cart={ cart } 
                        setChangeOrderItem={ ! orderToPay && setChangeOrderItem }
                        overridePrice={ customer && ! orderToPay && setPriceOverrideItem }
                    />
                </div>

                { customer && customer.storeOrder ? null : cartCount > 0 ? 
                    <CartTotals cart={ cart } divider />
                :
                    <p>Your cart is empty.</p>
                }

                { user && user.isAdmin && cartCount > 0 &&
                    <React.Fragment>
                        <div className={ classes.AdminNotesLabel } >Staff notes:</div>
                        <textarea value={ cartNotes || '' } onChange={ updateCartNotes }
                            className={ classes.AdminNotes } disabled={ !! orderToPay }
                        />
                    </React.Fragment>
                }

                <div className={ classes.Footer } >
                    { cartCount > 0 && ! orderToPay && <React.Fragment>
                        <div className={ classes.Links } >
                            { cartCount > 0 &&
                                <React.Fragment>
                                    <Link to='/cart' onClick={ saveCart } className={ classes.Save } >
                                        Save cart to place a different order
                                    </Link>
                                    <Link to='/cart' onClick={ confirmEmptyCart } className={ classes.Clear } >
                                        Clear cart
                                    </Link>
                                </React.Fragment>
                            }
                        </div>
                        <p>To make changes to any of the items in the cart simply click on the item.</p>
                    </React.Fragment> }

                </div>

                { changeOrderItem && 
                    <ProductOrder product={ changePricePoint() }
                        onCancel={ () => setChangeOrderItem( null ) } 
                    />
                }

                { priceOverrideItem &&
                    <PriceOverrides orderItem={ priceOverrideItem }
                        onCancel={ () => setPriceOverrideItem( null ) } 
                    />
                }

            </div>
            { showClearCart && 
                <Modal show onClick={ () => setShowClearCart( false ) }
                    className={ classes.ConfirmEmpty } backdropClassName={ classes.ClearBackdrop }
                > 
                    <p>Remove all items from cart?</p>
                    <div className={ classes.ClearButtons } >
                        <Button onClick={ emptyCart } >Clear Cart</Button>
                        <Button onClick={ () => {} } >Cancel</Button>
                    </div> 
                </Modal>
            }
            { showConfirmStoreOrder && 
                <Modal show onClick={ () => setShowConfirmStoreOrder( false ) }
                    className={ classes.ConfirmEmpty } backdropClassName={ classes.ClearBackdrop }
                > 
                    <p>Create store order { kitchenOrder ? 'for kitchen.' : 'to pack.' }</p>
                    <div className={ classes.ClearButtons } >
                        <Button onClick={ createStoreOrder } >OK</Button>
                        <Button onClick={ () => {} } >Cancel</Button>
                    </div> 
                </Modal>
            }
            { outOfStock &&
                <Modal show onClick={ () => setOutOfStock( false ) }
                    className={ classes.ConfirmEmpty } backdropClassName={ classes.ClearBackdrop }
                > 
                    <p>Following item(s) out of stock, remove from store order:</p>
                    { cart.filter( item => outOfStock[ item.variation.squareVariationId ] )
                        .map( ( item, index ) => 
                            <div key={ index } >
                                { `${ ( ( item.variation.displayName && 
                                    item.variation.displayName.trim().length > 0 
                                      ) ? item.variation.displayName + ' ' : ''
                                      ) + item.variation.squareItemName }`
                                }
                            </div>
                        )
                    }
                    <br />
                    <div className={ classes.ClearButtons } >
                        <Button>OK</Button>
                    </div> 
                </Modal>
            }
        </React.Fragment>
    );
};

export default Cart;