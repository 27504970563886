import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { calendarUtils, selectors } from '@fernleaf/util';
import { 
    setCalendar, getCalendar, 
    setMenusAndModifiers, getMenus,
    setItemOrderControls,
    getCustomer, setCustomer,
    getPickupDate, setPickupDate,
    clearCart, setOrderToPay, getOrderToPay, getReturnToAdmin, getRestart 
} from './store/redux';

import apiGetCustomer from './api/getCustomer';
import apiGetOrder from './api/getOrder';
import apiGetAvailableMenus from './api/getAvailableMenus';
import apiGetItemOrderControls from './api/getItemOrderControls';
import apiGetCalendar from './api/getCalendar';
import apiCodeVersion from './api/codeVersion';

import classes from './App.module.css';

import Layout from './Layout/Layout';
import { LoginRouter, Theme, Modal, Button } from '@fernleaf/util';

import Main from './Main/Main';
import Cart from './Cart/Cart';
import Checkout from './Checkout/Checkout';
import Orders from './Orders/Orders';
import Products from './Products/Products';
import SavedCarts from './SavedCarts/SavedCarts';

const NOT_ALPHA = /^[^a-z]$/i;

const App = ( { location, history } ) => {    
    const dispatch = useDispatch();
    const calendar = useSelector( getCalendar );
    const menus = useSelector( getMenus );
    const user = useSelector( selectors.getUser );
    const customer = useSelector( getCustomer ); // If staff is ordering for a customer then the user
                                                 // is the admin user for the staff person and the
                                                 // customer is an additional user stored as 'customer'.
    const orderToPay = useSelector( getOrderToPay );
    const returnToAdmin = useSelector( getReturnToAdmin );
    const restart = useSelector( getRestart );
    const pickupDate = useSelector( getPickupDate );
    const [ currentCodeVersion, setCurrentCodeVersion ] = useState( null );
    const [ outOfDate, setOutOfDate ] = useState( null );

    const checkCodeVersion = useCallback( () => {
        apiCodeVersion( ( { codeVersion } ) => {
            if ( currentCodeVersion === null ) {
                setCurrentCodeVersion( codeVersion );
            } else if ( codeVersion !== currentCodeVersion ) {
                setOutOfDate( true );
            }
        }, dispatch );
    }, [ currentCodeVersion, dispatch ] );

    const refreshExternals = useCallback( () => {
        checkCodeVersion();
        apiGetItemOrderControls(
            ( controls ) => dispatch( setItemOrderControls( controls ) ),
            dispatch
        )
    }, [ checkCodeVersion, dispatch ] );

    useEffect( () => {
        // When coming from the admin console the search parms should identify the customer
        // for the order, otherwise ignore any search parms.
        // This should only be done on first entry to the shop app.
        if ( location.search.startsWith( '?' ) ) {
            if ( location.search.startsWith( '?customer=' ) ) {
                localStorage.setItem( 'adminParms', location.search );
            } else {
                localStorage.removeItem( 'adminParms' );
            }
            dispatch( clearCart() );
            dispatch( setPickupDate( null ) );
            history.replace( location.pathname );
        }
    // eslint-disable-next-line
    }, [] )

    useEffect( () => {
        if ( returnToAdmin ) {
            setTimeout ( () => window.location = `${ window.adminRoot() }/admin`, 500 );
        }
    }, [ returnToAdmin ] );

    useEffect( () => {
        if ( restart ) {
            setTimeout( () => window.location = `${ window.location.origin }/shop/products`, 500 );
        }
    }, [ restart ] );

    useEffect( () => {
        refreshExternals();
        const interval = setInterval( refreshExternals, 60000 );
        return () => clearInterval( interval );
    }, [ refreshExternals ] );

    useEffect( () => {
        // Load calendar and menus to redux - used elsewhere
        if ( ! calendar ) {
            apiGetCalendar(
                calendar => dispatch( setCalendar( calendar ) ),
                dispatch
            )
        }
        if ( ! menus ) {
            apiGetAvailableMenus( 
                menusAndModifiers => dispatch( setMenusAndModifiers( menusAndModifiers ) ),
                dispatch
            );
        }
    }, [ calendar, menus, dispatch ] );

    useEffect( () => {

        // Store URL parameters in local storage in case admin user reloads the page
        // by accident. Then reload the page with no parms.

        if ( location.search.startsWith( '?' ) ) {
            // The initial useEffect function should clear this out first.
            return;
        }

        if ( ! user ) { // If not logged in then ignore any URL parameters
            return;
        }

        // Manage values related to whether we are ordering on behalf of a customer
        if ( ! user.isAdmin ) {
            localStorage.removeItem( 'adminParms' );
            if ( customer ) {
                dispatch( setCustomer( null ) );
            }
            return;
        }

        if ( ! localStorage.adminParms ) {
            return;
        }
        
        const parms = localStorage.adminParms.substr( 10 ).split( '&' );
        const customerId = parms[ 0 ];
        const inPerson = parms.length > 1 && parms[ 1 ] === 'type=in_person';
        const newPhone = parms.length > 1 && parms[ 1 ].startsWith( 'phone=' ) && 
                         parms[ 1 ].split( '=' )[ 1 ];
        const storeOrder = parms.length > 1 && parms[ 1 ] === 'type=store_order';
        const payOrderId = parms.reduce( ( orderId, parm ) => {
                if ( parm.startsWith( 'pay=' ) ) {
                    return parm.substr( 4 );
                } 
                return orderId;
            },
            null
        );
        
        if ( payOrderId && ( ! orderToPay || orderToPay._id !== payOrderId ) ) { 
            const reloadOrder = orders => {
                orders.order.squareOrder = orders.squareOrder;
                dispatch( setOrderToPay( orders.order ) );
            }
            apiGetOrder( payOrderId, reloadOrder, dispatch )
        }

        if ( customerId && ( ! customer || customer.id !== customerId ) ) {

            apiGetCustomer( 
                customerId, 
                ( customer ) => {
                    customer.inPerson = inPerson;
                    if ( newPhone ) {
                        if ( customer.newPhone.match( NOT_ALPHA ) ) {
                            customer.newPhone = newPhone;
                        } else {
                            customer.newName = newPhone;
                        }
                    }
                    customer.storeOrder = storeOrder;

                    if ( payOrderId ) {
                        customer.payingHeldOrder = true;
                    }
        
                    dispatch( setCustomer( customer ) );

                    if ( ! inPerson && ! payOrderId && ! storeOrder && ! pickupDate ) { 
                        // If ordering for a customer prompt for pickup date up front
                        document.getElementById( 'fernPickupDateTime' ).click();
                    }
                },
                () => {} // Swallow errors - probably navigating back to admin 
            );
        }

    }, [ user, customer, orderToPay, pickupDate, location, history, dispatch ] );
 
    useEffect( () => {
        if ( customer && ! pickupDate && ! customer.payingHeldOrder && calendar ) {
            if ( customer.storeOrder || customer.inPerson ) {
                dispatch( setPickupDate(
                    customer.storeOrder ? 
                        calendarUtils.hourBeforeNextOpening( calendar ) :
                        new Date()
                ) );
            }
        }

    }, [ customer, pickupDate, calendar, dispatch ] );

    if ( returnToAdmin ) {
        localStorage.removeItem( 'adminParms' );
        return (
            <div className={ classes.App } style={{ backgroundImage: 'url(/pictures/other/Background.png)' }} >
                &nbsp;
            </div>
        );
    }

    return ( 
        <div className={ classes.App } style={{ backgroundImage: 'url(/pictures/other/Background.png)' }} >
            <Theme useDarkTheme={ true } />
            <LoginRouter app='shop' >
                <Layout>
                    <Switch>
                        <Route exact path='/products' component={ Products } />
                        <Route exact path='/cart' component={ Cart } />
                        <Route exact path='/checkout' component={ Checkout } />
                        <Route exact path='/orders' component={ Orders } />
                        <Route exact path='/saved' component={ SavedCarts } />
                        <Route path='/' component={ Main } />
                    </Switch>
                </Layout>
            </LoginRouter>

            { outOfDate && 
                <Modal noX onClick={ () => window.location = '/shop' } >
                    The page you are using is a little out of date.
                    <Button>Refresh Page</Button>
                </Modal>
            }
        </div>
    );
};

export default withRouter( App );
