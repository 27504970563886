import { unpack } from '../util'
import { 
    SET_THEME, 
    SET_EMAIL_STATUS, SET_USER, 
    INITIALIZED,
    SET_ERROR
 } from "./actions";

const initialAuthStore = {
    emailStatus:   {},
    user:          null,
    isInitialized: false,
//  useDarkTheme:  false - do not initialize, selector needs to know if it is undefined
    error:         null
};

const utilReducer = (state = initialAuthStore, action) => {
    switch (action.type) {
        case SET_EMAIL_STATUS:
            return { ...state, emailStatus: { ...unpack( action ) } };
        case SET_USER:
            localStorage.setItem( 'username', action.name ); 
            return { ...state, user: { ...unpack( action ) } };
        case INITIALIZED:
            return { ...state, isInitialized: true };
        case SET_THEME:
            localStorage.setItem( 'useDarkTheme', action.useDarkTheme );
            return { ...state, useDarkTheme: action.useDarkTheme };
        case SET_ERROR:
            return { ...state, error: action.error };
        default:
            return state;
    }
};

export default utilReducer;
