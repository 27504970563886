import React, { useState, useEffect, createRef } from 'react';

import MESSAGES from '../messages';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';

import classes from './NewFolder.module.css';

const NewFolder = ( { existingFiles, onCreate, onCancel, folderPrompt } ) => {
    const [ folderName, setFolderName ] = useState( '' );
    const [ message, setMessage ] = useState( null );
    const [ isValid, setValid ] = useState( false );

    const inputRef = createRef();

    useEffect( () =>
        inputRef.current && inputRef.current.focus()
    , [] );

    const setFolder = (e) => {
        const currentValue = e.target.value;
        const folderName = currentValue.trim();

        const goodName = /^[\-_a-z0-9 \&]*$/i;

        let message;
        let valid = true;

        if ( folderName !== '' ) {
            if ( ! folderName.match( goodName ) ) {
                message = MESSAGES.BAD_FOLDER_NAME;
                valid = false;
            }
        
            if ( valid && existingFiles.includes( folderName ) ) {
                message = MESSAGES.sub( MESSAGES.FILE_EXISTS, { name: folderName.trim() } );
                valid = false;
            }
        }

        setMessage( message );
        setValid( valid && folderName !== '' );
        setFolderName( currentValue );
    }

    return (
        <Modal onClick={ onCancel } rounded >
            <div className={ classes.NewFolder } onClick={ (e) => e.stopPropagation() } >
                <div className={ classes.Title } >{ MESSAGES.ADD_FOLDER_TITLE }</div>
                <input type='text' value={ folderName } onChange={ setFolder }
                    ref={ inputRef }
                    onKeyDown={ (e) => {
                        if ( e.key === 'Enter' ) {
                            e.preventDefault();
                            onCreate( folderName );
                        }
                        if ( e.key === 'Escape' ) {
                            e.preventDefault();
                            onCancel();
                        }
                    } }
                    className={ classes.Input } placeholder={ MESSAGES.ADD_FOLDER_HINT } />
                { message && <div className={ classes.Message }>{ message }</div> }
                { isValid && 
                    <Button className={ classes.Add } onClick={ () => onCreate( folderName ) } >
                        { MESSAGES.ADD_FOLDER_BUTTON }
                    </Button>
                }
            </div>
        </Modal>
    )
};

export default React.memo( NewFolder );