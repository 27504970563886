import React from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { selectors } from '@fernleaf/util';
import { 
    getCartCount, getCustomer, getOrderToPay, 
    returnToAdminAction 
} from '../../../store/redux';

import NavigationItem from '../NavigationItem/NavigationItem';

import classes from './NavigationItems.module.css';

export const NavigationItems = ( { toggleSession, history } ) => {
    const dispatch = useDispatch();
    const user = useSelector( selectors.getUser );
    const cartCount = useSelector( getCartCount );
    const customer = useSelector( getCustomer );
    const orderToPay = useSelector( getOrderToPay );

    const returnToAdmin = () => dispatch( returnToAdminAction( true ) );

    return (
        <div>
            <ul className={classes.NavigationItems} >
                <NavigationItem to='/cart' >Go to shopping cart...</NavigationItem>
                { ( cartCount > 0 || customer ) &&
                    <NavigationItem to='/checkout' >Go to checkout...</NavigationItem>
                }
                { ! orderToPay && 
                    <NavigationItem to='/orders' >Look at your orders...</NavigationItem>
                }
                { ! orderToPay && 
                    <NavigationItem to='/saved' >Look at your saved carts...</NavigationItem>
                }
                { ! orderToPay && 
                    <NavigationItem to={ customer ? '/products' : '/' }>Continue shopping...</NavigationItem>
                }
                { ! customer && 
                    <React.Fragment>
                        <hr />
                        <NavigationItem to='/' onClick={ toggleSession } >
                            { Object.isEmpty( user ) ? 'Log in' : 'Log out' }
                        </NavigationItem>
                    </React.Fragment>
                }
                <hr />
                { user && user.isAdmin ?
                    <NavigationItem to='/' onClick={ returnToAdmin } >
                        Return to admin
                    </NavigationItem>
                :
                    <React.Fragment>
                        <NavigationItem external={ `${ window.location.origin }/fernleaf-privacy-policy.html` } newTab >
                            Privacy policy
                        </NavigationItem>
                        <NavigationItem external={ `${ window.location.origin }/fernleaf-terms-of-service.html` } newTab >
                            Terms of service
                        </NavigationItem>
                        <NavigationItem external={ `${ window.location.origin }/` } newTab >
                            Fernleaf Bakery Home Page
                        </NavigationItem>
                    </React.Fragment>
                }
            </ul>
        </div>
    );
}

export default withRouter( NavigationItems );