import React from 'react';

import classes from './MinusWidget.module.css';

const MinusWidget = ( { onClick, className } ) => 
    <div onClick={ onClick } 
        className={ [ classes.Plus, className || '' ].join( ' ' ) }
    >
        <svg viewBox="0 0 100 80" width="40" height="40" >
            <rect x="20" y="10" width="53" height="48" rx="10"
                strokeWidth="2" stroke="black" fill="gray"></rect>
            <rect x="32" y="30" width="30" height="10"
                strokeWidth="1" stroke="white" fill="white"></rect>
        </svg>
    </div>;

export default MinusWidget;