import { get } from './fetch';

const apiGetGiftCard = async ( giftCardId, setGiftCard, dispatch ) => {
    await get( 'apiGetGiftCard', 
        `/api/giftcard/${ giftCardId }`, setGiftCard,
        dispatch
    );
}

export default apiGetGiftCard;
