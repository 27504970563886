// Price calculation

export const calculatePrice = orderItem => {
    
    if ( ( orderItem.priceOverrides && orderItem.priceOverrides.length < orderItem.count ) ||
         ( orderItem.priceOverrides && orderItem.priceOverrides.some( 
             override => override.price === null ) )                                       ||
         ( orderItem.variation.squarePrice === 'Variable' && ! orderItem.priceOverrides ) ) {
        return 'Price required';
    }

    if ( orderItem.priceOverrides ) {
        return orderItem.priceOverrides.reduce( 
            ( total, { price } ) => { total += price; return total }, 0 
        );
    }

    if ( ! orderItem.details || 
        orderItem.details.length === 0 || 
        typeof orderItem.details[ 0 ] === 'string' 
    ) {
        const price = orderItem.count * orderItem.variation.squarePrice;
        return isNaN( price ) ? null : price;
    }

    let total = 0;
    for ( let index = 0; index < orderItem.count; index++ ) {
        total += orderItem.variation.squarePrice;
        if ( index < orderItem.details.length ) {
            const detail = orderItem.details[ index ];
            for ( const modifier of detail ) {
                if ( modifier.selected ) {
                    if ( modifier.type === 'SINGLE' ) {
                        for ( let pIndex = 0; pIndex < modifier.values.length; pIndex++ ) {
                            if ( modifier.selected === modifier.values[ pIndex ] ) {
                                total += modifier.prices[ pIndex ] || 0;
                                break;
                            }
                        }
                    } else {
                        total += modifier.price || 0;
                    }
                }
            }
        }
    }
    return isNaN( total ) ? null : total;
};

export const orderItemsTotal = cart => ( cart
    .map( orderItem => calculatePrice( orderItem ) )
    .reduce( ( sum, price ) => isNaN( price ) || sum === null ? null : sum + price, 0 )
);

export const orderTaxTotal = cart => {
    const amountByTax = {};
    const rateForTax = {};
    for ( const orderItem of cart ) {
        const totalAmount = calculatePrice( orderItem );
        for ( const tax of orderItem.variation.squareTaxes ) {
            if ( ! rateForTax[ tax.squareTaxId ] ) {
                rateForTax[ tax.squareTaxId ] = parseFloat( tax.percentage );
                amountByTax[ tax.squareTaxId ] = totalAmount;
            } else {
                amountByTax[ tax.squareTaxId ] += totalAmount;
            }
        }
    }
    const taxAmount = Math.round( Object.keys( rateForTax ).reduce( 
        ( total, taxId ) => { total += rateForTax[ taxId ] * amountByTax[ taxId ]; return total; }, 0
    ) / 100 );

    return isNaN( taxAmount ) ? null : taxAmount;
};

export const orderPaymentTotal = ( cart, isTaxExempt ) => (
    orderItemsTotal( cart ) + ( isTaxExempt ? 0 : orderTaxTotal( cart ) )
);
