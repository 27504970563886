import { parseISO, isToday } from 'date-fns';
import { actions } from '@fernleaf/util';

const SET_CALENDAR = 'SET_CALENDAR';
const SET_CUSTOMER = 'SET_CUSTOMER';
const SET_ITEM_ORDER_CONTROLS = 'SET_ITEM_ORDER_CONTROLS';
const SET_MENUS_AND_MODIFIERS = 'SET_MENUS_AND_MODIFIERS';
const SET_SEARCH = 'SET_SEARCH';
const SET_PICKUP_DATE = 'SET_PICKUP_DATE';
const CLEAR_CART = 'CLEAR_CART';
const SET_ORDER_TO_PAY = 'SET_ORDER_TO_PAY';
const RELOAD_CART = 'RELOAD_CART';
const ADD_TO_CART = 'ADD_TO_CART';
const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
const SET_PRICE_OVERRIDES = 'SET_PRICE_OVERRIDES';
const CHANGE_CART_DETAILS = 'CHANGE_CART_DETAILS';
const CHANGE_CART_ITEM_READY = 'CHANGE_CART_ITEM_READY';
const CHANGE_CART_ITEMS_READY = 'CHANGE_CART_ITEMS_READY';
const CHANGE_CART_NOTES = 'CHANGE_CART_NOTES';
const SHOW_PRODUCTS = 'SHOW_PRODUCTS';
const SALE_COMPLETED = 'SALE_COMPLETED';
const SHOW_MAIN_PAGE = 'SHOW_MAIN_PAGE';

const EMPTY_OVERRIDE = { description: null, price: null };

const getExistingPickupDate = () => {
    try {
        if ( localStorage.pickupDate ) {
            const pickupDate = new Date( localStorage.pickupDate );
            if ( pickupDate.toString() === 'Invalid Date' ) {
                throw new Error( 'Bad Date' );
            }
            return pickupDate;
        }
    } catch( error ) {
        console.warn( "SHOP:redux.js - bad order date", error, localStorage.pickupDate );
    }
    return null;
}

const getExistingCart = () => {
    try {
        if ( localStorage.cart ) {
            return JSON.parse( localStorage.cart );
        }
    } catch( error ) {
        console.warn( "SHOP - could not parse cart.", error );
    }
}

const getCurrentCustomer = () => {
    try {
        if ( localStorage.customer ) {
            return JSON.parse( localStorage.customer );
        }
    } catch( error ) {
        console.warn( "SHOP - could not parse customer.", error );
    }
}

const initialProductsStore = {
    calendar: null,
    menus: null,
    partialPaymentItem: null,
    itemOrderControls: null,
    pickupDate: getExistingPickupDate(),
    orderItems: getExistingCart() || [],
    notes: localStorage.cartNotes,
    productSelectorId: null,
    customer: null,  // getCurrentCustomer(),
    orderToPay: null,
    search: null
};

const stowCart = cart => localStorage.setItem( 'cart', JSON.stringify( cart ) );

// TODO - update prices and available items when reloading a cart

export const productsReducer = ( state = initialProductsStore, action ) => {
    let orderItems;
    let found = false;

    switch ( action.type ) {
        case SET_CALENDAR:
            return { ...state, calendar: action.calendar };

        case SET_CUSTOMER:
            if ( action.customer ) {
                localStorage.setItem( 'customer', JSON.stringify( action.customer ) )
            } else {
                localStorage.removeItem( 'customer' );
            }
            return { ...state, customer: getCurrentCustomer() };

        case SET_ITEM_ORDER_CONTROLS:
            return { ...state, itemOrderControls: action.itemOrderControls };

        case SET_MENUS_AND_MODIFIERS:
            const exclusiveDates = action.menusAndModifiers.exclusiveDates;
            for ( const dates of Object.values( exclusiveDates ) ) {
                dates.availableFrom = parseISO( dates.availableFrom );
                dates.availableTo = parseISO( dates.availableTo );
            }
            const findPartialPaymentItem = () => {
                for ( const menu of action.menusAndModifiers.activeMenus ) {
                    for ( const section of menu.sections ) {
                        for ( const item of section.items ) {
                            if ( item.squareItemName === 'Partial Payment' ) {
                                return item;
                            }
                        }
                    }
                }
            }
            return { 
                ...state, 
                menus: action.menusAndModifiers.activeMenus,
                partialPaymentItem: findPartialPaymentItem(),
                modifierLists: action.menusAndModifiers.modifierLists,
                allMenusURL: action.menusAndModifiers.allMenusURL,
                exclusiveDates
            };

        case SET_SEARCH:
            return { ...state, search: action.search };

        case SET_PICKUP_DATE:
            if ( action.pickupDate ) {
                localStorage.setItem( 'pickupDate', action.pickupDate.toString() );
            } else {
                localStorage.removeItem( 'pickupDate' );
            }
            return { ...state, pickupDate: action.pickupDate };

        case CLEAR_CART:
            localStorage.removeItem( 'cart' );
            localStorage.removeItem( 'cartNotes' );
            localStorage.removeItem( 'cartOrderId' );
            localStorage.removeItem( 'orderToPay' );
            return { 
                ...state, 
                orderItems: [], notes: null,
                orderToPay: null 
            }

        case SET_ORDER_TO_PAY:
            if ( action.order ) {
                localStorage.setItem( 'orderToPay', JSON.stringify( action.order ) );
            } else {
                localStorage.removeItem( 'orderToPay' );
            }
            return {
                ...productsReducer( state, reloadCart( action.order ) ),
                orderToPay: action.order
            }

        case RELOAD_CART:
            try {
                localStorage.setItem( 'cart', JSON.stringify( action.order.items ) );
                localStorage.setItem( 'pickupDate', action.order.pickupDateAndTime );
                localStorage.setItem( 'cartOrderId', action.order._id );
                if ( action.order.notes ) {
                    localStorage.setItem( 'cartNotes', action.order.notes );
                } else {
                    localStorage.removeItem( 'cartNotes' );
                }
                return { 
                    ...state, 
                    orderItems: JSON.parse( localStorage.cart ), 
                    pickupDate: parseISO( localStorage.pickupDate ),
                    notes: localStorage.cartNotes 
                }
            } catch( error ) {
                console.error( 'REDUX - RELOAD CART', error );
                return productsReducer( state, clearCart() );
            }

        case ADD_TO_CART:
            orderItems = [ ...state.orderItems ];
            for ( const orderItem of orderItems ) {
                if ( orderItem.variation.squareVariationId === action.variation.squareVariationId ) {
                    orderItem.count++;
                    orderItem.details = action.details;
                    if ( orderItem.variation.squarePrice === 'Variable' ) {
                        orderItem.priceOverrides.push( { ...EMPTY_OVERRIDE } );
                    }
                    found = true;
                    break;
                }
            }
            if ( ! found ) {
                orderItems.push( { variation: action.variation, count: 1, details: action.details } );
                if ( action.variation.squarePrice === 'Variable' ) {
                    orderItems[ orderItems.length - 1 ].priceOverrides = [ { ...EMPTY_OVERRIDE } ];
                }
            }
            stowCart( orderItems );
            return { ...state, orderItems };
            
        case REMOVE_FROM_CART:
            orderItems = [ ...state.orderItems ];
            for ( const [ index, orderItem ] of orderItems.entries() ) {
                if ( orderItem.variation.squareVariationId === action.variation.squareVariationId ) {
                    if ( action.all ) {
                        orderItem.count = 0;
                    } else {
                        orderItem.count--;
                    }
                    if ( orderItem.count === 0 ) {
                        orderItems.splice( index, 1 );
                    } else {
                        orderItem.details = action.details;
                        if ( orderItem.priceOverrides ) {
                            orderItem.priceOverrides.pop();
                        }
                    }
                    stowCart( orderItems );
                    return { ...state, orderItems };
                }
            }
            return state;
            
        case SET_PRICE_OVERRIDES:
            orderItems = [ ...state.orderItems ];
            for ( const orderItem of orderItems ) {
                if ( orderItem.variation.squareVariationId === action.variation.squareVariationId ) {
                    orderItem.priceOverrides = action.priceOverrides;
                    stowCart( orderItems );
                    return { ...state, orderItems };
                }
            }
            return state;
                
        case CHANGE_CART_DETAILS:
            orderItems = [ ...state.orderItems ];
            for ( const orderItem of orderItems ) {
                if ( orderItem.variation.squareVariationId === action.variation.squareVariationId ) {
                    orderItem.details = action.details;
                    stowCart( orderItems );
                    return { ...state, orderItems };
                }
            }
            return state;
            
        case CHANGE_CART_ITEM_READY:
            orderItems = [ ...state.orderItems ];
            for ( const orderItem of orderItems ) {
                if ( orderItem === action.orderItem ) {
                    orderItem.isReady = action.isReady;
                    stowCart( orderItems );
                    return { ...state, orderItems };
                }
            }
            return state;
        
        case CHANGE_CART_ITEMS_READY:
            orderItems = [ ...state.orderItems ];
            for ( const orderItem of orderItems ) {
                    orderItem.isReady = action.isReady;
            }
            stowCart( orderItems );
            return { ...state, orderItems };
                    
        case CHANGE_CART_NOTES:
            if ( ! action.notes || action.notes.replace( / \n/g, '' ) === '' ) {
                localStorage.removeItem( 'cartNotes' );
            } else {
                localStorage.setItem( 'cartNotes', action.notes );
            }
            return { ...state, notes: localStorage.cartNotes };
                        
        case SHOW_PRODUCTS:
            return { ...state, productSelectorId: action.productSelectorId };

        case SALE_COMPLETED:
            // When doing walk-in orders we want to stay in the Shop app otherwise go to Admin
            const noCustomerState = productsReducer( state, setCustomer( null ) );
            const cleared = productsReducer( noCustomerState, clearCart() );
            const goToAdmin = action.goToAdmin || ( state.customer && ! state.customer.inPerson );
            return { ...cleared, returnToAdmin: goToAdmin, restart: ! goToAdmin }

        case SHOW_MAIN_PAGE:
            // For staff orders we don't usually show the main page unless specifically requested
            return { ...state, showMainPage: action.showMainPage };

        default:
            return state;
    }
};

// Actions

export const setCalendar = calendar => actions.action( SET_CALENDAR, { calendar } );

export const setCustomer = customer => actions.action( SET_CUSTOMER, { customer } );

export const setItemOrderControls = itemOrderControls => 
    actions.action( SET_ITEM_ORDER_CONTROLS, { itemOrderControls } );

export const setMenusAndModifiers = menusAndModifiers => 
    actions.action( SET_MENUS_AND_MODIFIERS, { menusAndModifiers } );

export const setSearch = search => actions.action( SET_SEARCH, { search } );
export const setPickupDate = pickupDate => actions.action( SET_PICKUP_DATE, { pickupDate } );

export const setOrderToPay = order => actions.action( SET_ORDER_TO_PAY, { order } );
export const reloadCart = order => actions.action( RELOAD_CART, { order } );
export const clearCart = () => actions.action( CLEAR_CART );

export const addToCart = ( variation, details ) => ( 
    actions.action( ADD_TO_CART, { variation, details } )
);
export const removeFromCart = ( variation, details, all ) => (
    actions.action( REMOVE_FROM_CART, { variation, details, all } )
);
export const setPriceOverrides = ( variation, priceOverrides ) => (
    actions.action( SET_PRICE_OVERRIDES, { variation, priceOverrides } )
);
export const changeCartDetails = ( variation, details ) => (
    actions.action( CHANGE_CART_DETAILS, { variation, details } )
);
export const changeCartItemReady = ( orderItem, isReady ) => (
    actions.action( CHANGE_CART_ITEM_READY, { orderItem, isReady } )
);
export const changeCartItemsReady = ( isReady ) => (
    actions.action( CHANGE_CART_ITEMS_READY, { isReady } )
);
export const changeCartNotes = ( notes ) => actions.action( CHANGE_CART_NOTES, { notes } );

export const showProducts = ( productSelectorId ) => (
    actions.action( SHOW_PRODUCTS, { productSelectorId } )
);

export const setShowMainPage = ( showMainPage ) => actions.action( SHOW_MAIN_PAGE, { showMainPage } );

export const returnToAdminAction = ( goToAdmin ) => actions.action( SALE_COMPLETED, { goToAdmin } );

// Selectors

export const getCalendar = state => state.products.calendar;

export const getMenus = state => state.products.menus;
export const getPartialPaymentItem = state => state.products.partialPaymentItem;
export const getModifierLists = state => state.products.modifierLists;
export const getExclusiveDates = state => state.products.exclusiveDates;
export const getAllMenusURL = state => state.products.allMenusURL;

export const getCustomer = state => state.products.customer;

export const getSearch = state => state.products.search;
export const getPickupDate = state => state.products.pickupDate;

export const getOrderToPay = state => state.products.orderToPay;
export const getCart = state => state.products.orderItems;
export const getCartNotes = state => state.products.notes;
export const getCartCount = state => state.products.orderItems
    .reduce( ( count, orderItem ) => count + orderItem.count, 0 );
export const getOrderItemCount = squareVariationId => state => state.products.orderItems
    .filter( orderItem => orderItem.variation.squareVariationId === squareVariationId )
    .reduce( ( count, orderItem ) => orderItem.count, 0 ); // Should only be 1
export const getOrderItemDetails = squareVariationId => state => state.products.orderItems
    .filter( orderItem => orderItem.variation.squareVariationId === squareVariationId )
    .reduce( ( result, orderItem ) => orderItem.details, [] ); // Should only be 1

export const getProductSelectorId = state => state.products.productSelectorId;

export const getItemOrderControls = state => state.products.itemOrderControls;

export const getReturnToAdmin = state => state.products.returnToAdmin;
export const getRestart = state => state.products.restart;
export const getShowMainPage = state => state.products.showMainPage;

// Return order item controls adjusted for cart contents
export const getCurrentOrderControls = state => {
    if ( state.products.itemOrderControls === null ) {
        return null;
    }
    const previousCalculatedControls = state.products.previousCalculatedControls;
    const controls = { ...state.products.itemOrderControls };
    const pickupDate = state.products.pickupDate;
    // Same day stocking levels only apply to products that have lead times of 1 day or more
    for ( const orderItem of state.products.orderItems ) {
        const isTodayException = isToday( pickupDate ) && orderItem.variation.leadTime.units === 'Days';
        let control = controls[ orderItem.variation.squareVariationId ];
        if ( control ) {
            control = { ...control };
            if ( isTodayException && typeof control.availableSameDay !== 'undefined' ) {
                control.availableSameDay -= orderItem.count;
            }
            if ( ! isTodayException && typeof control.availableToOrder !== 'undefined' ) {
                control.availableToOrder -= orderItem.count;
            }
            controls[ orderItem.variation.squareVariationId ] = control;
        }
    }
    if ( Object.areEqual( controls, previousCalculatedControls ) ) {
        return previousCalculatedControls;
    }
    state.products.previousCalculatedControls = controls;
    return controls;
}
