import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Thumbnail, orderItemUtils, priceUtils } from '@fernleaf/util';

import { Checkbox } from 'react-input-checkbox';
import 'react-input-checkbox/lib/react-input-checkbox.min.css';

import { isToday } from 'date-fns';

import { 
    getCustomer, getOrderToPay, getPickupDate, changeCartItemReady, changeCartItemsReady 
} from '../../store/redux';

import classes from './CartContents.module.css';

const CartContents = ( { cart, setChangeOrderItem, overridePrice } ) => {
    const dispatch = useDispatch();
    const customer = useSelector( getCustomer );
    const orderToPay = useSelector( getOrderToPay );
    const pickupDate = useSelector( getPickupDate );

    const orderIsReady = useCallback( () => cart.every( orderItem => orderItem.isReady ), [ cart ] );

    const flipOrderStatus = useCallback( (e) => {
        e.stopPropagation();
        const newStatus = ! orderIsReady();
        dispatch( changeCartItemsReady( newStatus ) );
    }, [ orderIsReady, dispatch ] );
    
    return (
        <React.Fragment>
            { cart.map( ( orderItem ) =>
            <React.Fragment key={ orderItem.variation.squareVariationId } >
                <div onClick={ () => setChangeOrderItem && setChangeOrderItem( orderItem ) }
                    className={ classes.OrderItem } 
                >
                    { customer && ! orderToPay && ( 
                        setChangeOrderItem && ! customer.storeOrder && isToday( pickupDate )
                    ) &&
                        <React.Fragment>
                            <div onClick={ (e) => e.stopPropagation() }>
                                <Checkbox 
                                    value={ orderItem.isReady || false }
                                    
                                    onChange={ (e) => {
                                        e.stopPropagation();
                                        dispatch( 
                                            changeCartItemReady( orderItem, ! orderItem.isReady ) 
                                        );
                                    } }
                                >Ready?</Checkbox>
                            </div>
                        </React.Fragment>
                    }
                    <Thumbnail pictures={ orderItem.variation.pictureURLs } small 
                        onClick={ () => {} } className={ classes.Thumbnail }
                    />
                    <div className={ classes.Details } >
                        <div className={ classes.Item } >
                            <div className={ classes.Names } >
                                { orderItem.variation.squareItemName }
                                { orderItem.variation.squareVariationName.match( / *regular */i )
                                    ? '' : `\xA0(${ orderItem.variation.squareVariationName })` 
                                }
                            </div>
                            <div onClick={ (e) => {
                                    e.stopPropagation();
                                    if ( orderItem.priceOverrides ) { 
                                        overridePrice && overridePrice( orderItem )
                                    }
                                } }
                                className={ 
                                    ( overridePrice && classes.Override ) || classes.ExtendedPrice
                                }
                            >
                                { orderItemUtils.formatPrice( priceUtils.calculatePrice( orderItem ) ) }
                            </div>
                        </div>                        
                        { ! orderItem.priceOverrides && orderItem.count > 1 && 
                            ( ! orderItem.details || typeof orderItem.details[ 0 ] === 'string' ) &&
                            <div className={ classes.UnitLine } >
                                <div className={ classes.Qty } >{ orderItem.count }</div>
                                &nbsp;@&nbsp;
                                <div className={ classes.UnitPrice } >
                                    { orderItemUtils.formatPrice( orderItem.variation.squarePrice ) }
                                </div>
                            </div>
                        }
                        { orderItem.details && orderItem.details
                            .filter( detail => detail && typeof detail === 'string' && detail.trim().length > 0 )
                            .map( ( detail, index ) =>
                                <div key={ index } className={ classes.MessageLine } >
                                    <div className={ classes.MessageLabel } >
                                        Message{ orderItem.details.length > 1 
                                            ? ' for cake ' + ( index + 1 ) : '' }: 
                                    </div>
                                    <div className={ classes.Message } >{ detail }</div>
                                </div>
                            ) 
                        }
                        { orderItem.details && orderItem.details.length >= 1 && 
                            ! ( typeof orderItem.details[ 0 ] === 'string' ) &&
                                orderItemUtils.itemDetail( 
                                    orderItem.variation,
                                    orderItem.details, null,
                                    classes.DetailHeader,
                                    classes.DetailLine 
                                )
                        }
                        { orderItem.priceOverrides && 
                            orderItemUtils.itemDetail( 
                                orderItem.variation,
                                null, orderItem.priceOverrides,
                                classes.DetailHeader,
                                classes.DetailLine 
                            )
                        }
                    </div>
                </div>
            </React.Fragment> ) }
            { customer && ! orderToPay && ( 
                setChangeOrderItem && ! customer.storeOrder && cart.length > 0 && isToday( pickupDate )
            ) &&
                <React.Fragment>
                    <Checkbox 
                        value={ orderIsReady() }
                        onChange={ flipOrderStatus }
                    >All items Ready?</Checkbox>
                </React.Fragment>
            }
        </React.Fragment> 
    );
};

export default CartContents;