import React from 'react';

import Dropdown from '../Dropdown/Dropdown';

import classes from './TimePicker.module.css';

const validTime = ( time, optionField, field ) => {
    time = + time;
    if ( time < 0 || time > 2359 ) {
        throw new Error( `The ${ optionField } value for field ${ field } is not between 0000 and 2359.` );
    }
    const minutes = time % 100;
    if ( ! [ 0, 15, 30, 45 ].includes( minutes ) ) {
        throw new Error( `The ${ optionField } value (${ time }) for field ${ field } is not a valid quarter hour.` );
    }
    return time;
};

const createOptionValues = ( startTime, endTime, interval, field, asap ) => {
    startTime = validTime( startTime || 0, 'startTime', field );
    endTime = validTime( endTime || 2345, 'endTime', field );
    if ( endTime < startTime ) {
        throw new Error( `The startTime (${ startTime }) must be less than the endTime (${ endTime }) for field ${ field }` );
    }
    const values = [ [ '', '' ] ];
    if ( asap ) {
        values.push ( [ 0, 'Soonest' ] );
    }
    let hour = Math.floor( startTime / 100 );
    let minutes = startTime % 100;
    while ( hour * 100 + minutes <= endTime ) {
        const display = 
            ( hour === 0 ? '12' : hour > 12 ? hour - 12 : hour ) + ':' +
            ( minutes < 10 ? '0' : '' ) + minutes + 
            ( hour < 12 ? ' am' : ' pm' );
        const time = hour * 100 + minutes;
        values.push( [ time, display ] );
        minutes += interval;
        if ( minutes >= 60 ) {
            hour++;
            minutes = 0;
        }
    }
    return values;
};

const TimePicker = ( { 
    thing, field, update, 
    startTime, endTime, interval, asap,
    open, className 
} ) => {

    if ( ! interval ) {
        interval = 15;
    }

    const values = createOptionValues( startTime, endTime, interval, field, asap );

    const validTimeProxy = new Proxy( thing, {
        get: function( target, timeField, receiver ) {
            if ( timeField === field ) {
                const currentValue = target[ timeField ];
                for( const value of values ) {
                    if ( currentValue === value[ 0 ] ) {
                        return currentValue;
                    }
                }
                return '';
            }
            return Reflect.get( target, timeField, receiver );
        }
    } );

    return (
        <Dropdown thing={ validTimeProxy } field={ field } values={ values } 
            number open={ open } update={ update }
            className={ [ classes.TimePicker, className ].join( ' ' ) }
        />
    );
};

export default TimePicker;
