import React, { Fragment } from 'react';
import classes from './Spinner.module.css';

const spinner = (props) => ( ! props.show ? null :
    <Fragment>
        <div className={classes.container}>
            <div className={classes.loader}>Loading...</div>
        </div>
        { props.children }
    </Fragment>
)

export default spinner;