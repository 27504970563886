import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, RadioButtons, fieldProxy, orderItemUtils } from '@fernleaf/util';

import {
    addToCart,
    removeFromCart,
    changeCartDetails,
    getOrderItemCount, 
    getOrderItemDetails,
    getModifierLists 
} from '../../../store/redux';

import { ADD, REMOVE } from '../orderActions'
import classes from './ModifierDetails.module.css';

const nameAndPrice = ( modifier ) => {
    let name = modifier.name.split( ':' )[ 0 ];
    if ( name.startsWith( '*' ) || name.startsWith( '+' ) ) {
        name = name.substr( 1 );
    }
    const price = modifier.price ? `\xA0-\xA0${ ( modifier.price / 100 ).toFixed( 2 ) }` : '';
    return `${ name }${ price }`
};

const sortModifiers = ( a, b ) => (
    a.type === b.type ||
    ( a.type.startsWith( 'SINGLE' ) && b.type.startsWith( 'SINGLE' ) ) ?
        ( a.name < b.name  ? -1 : a.name === b.name ? 0 : +1 ) :
    a.type.startsWith( 'SINGLE' ) ? -1 :
    b.type.startsWith( 'SINGLE' ) ? +1 :
    a.type === 'COMPLEX' ? -1 : 
    b.type === 'COMPLEX' ? +1 : 
    a.type === 'SIMPLE' ? +1 : 
    b.type === 'SIMPLE' ? -1 : 0
);

const getModifierSet = ( itemModifierLists, allModifierLists ) => {
    const modifierSet = [];
    for ( const modifierListId of itemModifierLists ) {
        const modifierList = allModifierLists[ modifierListId ];
        if ( ! modifierList ) {
            continue; // In case there is a data error - don't fall over.
        }
        if ( modifierList.type === 'SINGLE' ) {
            const type = modifierList.name.indexOf( ':' ) > -1 ? 'SINGLEPLEX' : 'SINGLE';
            const names = modifierList.name.split( ':' );
            const subModifiers = type === 'SINGLEPLEX' ? names[ 1 ].split( '|' ) : null;
            let defaultSubModifier = null;
            if ( subModifiers ) {
                for ( let index = 0; index < subModifiers.length; index++ ) {
                    const subModifier = subModifiers[ index ];
                    if ( subModifier.startsWith( '+' ) ) {
                        subModifiers[ index ] = subModifier.substr( 1 );
                        defaultSubModifier = subModifiers[ index ];
                    }
                }
            }
            let defaultModifier = null;
            let defaultType = null;
            for ( let index = 0; index < modifierList.modifiers.length; index++ ) {
                const modifier = modifierList.modifiers[ index ];
                if ( 
                    modifier.name.length > 1 && 
                    [ '+', '*' ].includes( modifier.name.substr( 0, 1 ) ) 
                ) {
                    defaultType = modifier.name.substr( 0, 1 );
                    defaultModifier = modifier.id;
                }
            }
            modifierSet.push( { 
                type,
                id: modifierListId,
                name: names[ 0 ],
                values: modifierList.modifiers.map( modifier => modifier.id ),
                labels: modifierList.modifiers.map( modifier => nameAndPrice( modifier ) ),
                prices: modifierList.modifiers.map( modifier => modifier.price ),
                selected: defaultType === '*' ? defaultModifier : null,
                defaultModifier,
                subModifiers: subModifiers,
                subModifier: null,
                defaultSubModifier,
            } );
        } else {
            for ( const modifier of modifierList.modifiers ) {
                const type = modifier.name.indexOf( ':' ) > -1 ? 'COMPLEX' : 'SIMPLE';
                modifierSet.push( {
                    type,
                    id: modifier.id,
                    listId: modifierListId,
                    name: nameAndPrice( modifier ),
                    price: modifier.price,
                    values: type === 'COMPLEX' ? modifier.name.split( ':' )[ 1 ].split( '|' ) : null,
                    selected: type === 'SIMPLE' ? false : null
                } );
            }
        }
    }
    return modifierSet.sort( sortModifiers );
}

const displayName = ( orderItem ) => (
    orderItem.squareItemName + 
    ( orderItem.displayName ? ` (${ orderItem.displayName })` : '' )
);

const displayDescription = ( orderItem, modifierSet ) => (
    orderItemUtils.itemDetail( 
        orderItem,
        [ modifierSet ], null,
        classes.DetailHeader,
        classes.DetailLine
    )
);

const ModifierDetails = ( { orderItem, orderAction, onClose } ) => {
    const dispatch = useDispatch();
    const modifierLists = useSelector( getModifierLists );
    let count = useSelector( getOrderItemCount( orderItem.squareVariationId ) );
    const details = useSelector( getOrderItemDetails( orderItem.squareVariationId ) );
    const [ modifiers, setModifiers ] = useState( 
        orderAction === ADD ? [ ...details ].concat( [ 
            getModifierSet( 
                orderItem.squareModLists,
                modifierLists
            ) ] ) : [ ...details ]
    );
    const [ open, setOpen ] = useState ( orderAction === ADD ? count : -1 );
    if ( orderAction === ADD ) {
        count++;
    }

    const addToOrder = () => {
        dispatch( addToCart( orderItem, modifiers ) );
        onClose();
    }

    const changeOrder = () => {
        dispatch( changeCartDetails( orderItem, modifiers ) );
        onClose();
    }
    
    const removeItemAt = index => {
        modifiers.splice( index, 1 );
        dispatch( removeFromCart( orderItem, modifiers ) );
        onClose();
    }

    const modifiedItems = [];

    const changeDetails = ( e, index ) => {
        e.stopPropagation();
        if ( orderAction === REMOVE ) {
            removeItemAt( index );
        } else {
            setOpen( index );
        }
    }

    for ( let index = 0; index < modifiers.length; index++ ) {
        const modifierSet = modifiers[ index ];
        if ( index !== open ) {
            modifiedItems.push(
                <div key={ index } className={ classes.Closed } >
                    <div className={ classes.Description } >
                        { displayName( orderItem ) }.
                        { displayDescription( orderItem, modifierSet ) }
                    </div>
                    { orderAction !== ADD &&
                        <Button onClick={ e => changeDetails( e, index ) } 
                            className={ classes.ChangeButton } 
                        >
                            { orderAction === REMOVE ? 'Remove' : 'Change' }
                        </Button>
                    }
                </div>
            );
            continue;
        }
        const widgets = [ 
            <div className={ classes.ItemName } key="name" >
                { displayName( orderItem ) }
            </div> 
        ];

        for ( const modifier of modifierSet ) {
            if ( modifier.values ) {
                // List of values requires a radio button
                const proxy = fieldProxy( {
                    getter: () => modifier.selected,
                    setter: value => {
                        modifier.selected = value;
                        if ( modifier.type === 'SINGLEPLEX' ) {
                            if ( value === null ) {
                                modifier.subModifier = null;
                            } else {
                                if ( modifier.subModifier === null ) {
                                    modifier.subModifier = modifier.defaultSubModifier;
                                }
                            }
                        }
                        setModifiers( [ ...modifiers ] );
                    },
                    name: modifier.id
                } )
                let nested = null;
                if ( modifier.subModifiers ) {
                    const fieldName = modifier.id + 'Plex';
                    const subModifierProxy = fieldProxy( {
                        getter: () => modifier.subModifier,
                        setter: value => {
                            modifier.subModifier = value;
                            if ( modifier.type === 'SINGLEPLEX' ) {
                                if ( value === null ) {
                                    modifier.selected = null;
                                } else {
                                    if ( modifier.selected === null ) {
                                        modifier.selected = modifier.defaultModifier;
                                    }
                                }
                            }
                            setModifiers( [ ...modifiers ] );
                        },
                        name: fieldName
                    } );
                    nested = (
                        <RadioButtons thing={ subModifierProxy } field={ fieldName }
                            values={ modifier.subModifiers } noNone
                        />
                    );
                }
                const wantANoneChoice = !! nested || modifier.type === 'COMPLEX';
                widgets.push (
                    <RadioButtons thing={ proxy } field={ modifier.id }
                        values={ modifier.values } labels={ modifier.labels } 
                        title={ modifier.name } key={ modifier.id }
                        titleClassName={ classes.Title }
                        buttonsClassName={ modifier.type.startsWith( 'SINGLE' ) && classes.Stacked }
                        buttonClassName={ classes.RadioButton }
                        noNone={ ! wantANoneChoice }
                    >{ nested }</RadioButtons>
                )
            } else {
                // No list of values - makes a checkbox
                widgets.push( 
                    <div key={ modifier.id } className={ classes.Simple } >
                        <div className={ classes.Title } >{ modifier.name }</div>
                        <input type='checkbox' value={ modifier.selected }
                            checked={ modifier.selected }
                            onChange={ () => {
                                modifier.selected = ! modifier.selected;
                                setModifiers( [ ...modifiers ] ) 
                            } }
                        />
                    </div>
                );
            }
        }
        modifiedItems.push(
            <div key={ modifiedItems.length } className={ classes.Open } >
                { widgets }
            </div>
        );
    }

    return (
        <Modal onClick={ onClose } className={ classes.Modal } 
            contentClassName={ classes.Content }
        >
            <div onClick={ (e) => e.stopPropagation() } className={ classes.Items } >
                <div className={ classes.Buttons } >
                    { orderAction !== REMOVE &&
                        <Button onClick={ orderAction === ADD ? addToOrder : changeOrder } >
                            { orderAction === ADD ? 'Add' : 'Save Changes' }
                        </Button>
                    }
                    <Button onClick={ onClose } className={ classes.Cancel } >Cancel</Button>
                </div>
                <div onClick={ (e) => e.stopPropagation() } className={ classes.Modifiers } >
                    { modifiedItems }
                </div>
            </div>
        </Modal>
    )
}

export default ModifierDetails;