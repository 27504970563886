import React from 'react';
import TransitionModal from '../Modal/TransitionModal';
import Button from '../Button/Button';
import EmailForm from './EmailForm';
import MESSAGES from '../messages';

import { useSelector } from 'react-redux';
import { getUser, getEmailStatus } from '../store/selectors';

import classes from './LoginDialog.module.css';

const LoginDialog = ( { admin, show, returnTo, onClick } ) => {
    const user = useSelector( getUser ) || {};
    const emailStatus = useSelector( getEmailStatus );

    const message = emailStatus.message ? emailStatus.message // unexpected condition

                  : admin && ! user.id ? MESSAGES.ADMIN_MUST_LOGIN
                  : admin && ! user.isAdmin && user.isFacebookUser ? MESSAGES.MUST_BE_ADMIN

                  : emailStatus.badPassword ? MESSAGES.BAD_PASSWORD
                  : emailStatus.notFound ? MESSAGES.EMAIL_NOT_FOUND
                  : emailStatus.emailSent ? MESSAGES.EMAIL_SENT
                  : emailStatus.resetId ? MESSAGES.ENTER_NEW_PASSWORD
                  : emailStatus.isExpired ? MESSAGES.RESET_EXPIRED

                  : null;

    return (
        <TransitionModal in={ show } classes={ classes } baseClass='Login' rounded
            onClick={ onClick } noX={ admin }>
            
            <div className={ classes.EmailForm } onClick={ (e) => e.stopPropagation() } >
                <Button facebook disabled={ emailStatus.email }
                    className={ classes.LoginButton } 
                    onClick={ () => 
                        window.location = `/auth/facebook/${ admin ? 'admin' : returnTo }` 
                    } 
                >
                    Login with facebook
                </Button>

                { admin && user.id && user.isFacebookUser && ! user.isAdmin ?
                    <Button className={ classes.LoginButton } 
                        onClick={ () => window.location = '/auth/admin/request' } >
                        Request Admin Access
                    </Button>
                : null }

                { ! admin ? 
                    <React.Fragment>
                        <hr className={ classes.EmailSeparator } />
                        <EmailForm emailStatus={ emailStatus } returnTo={ returnTo } />
                    </React.Fragment>
                : null }

                { message ? 
                    <React.Fragment>
                        <hr className={ classes.EmailSeparator } />
                        <div className={ classes.Message } >{ message }</div>
                    </React.Fragment>
                : 
                    ! admin &&
                    <p>First time users: If logging in with an email, enter the desired password for your new account.</p> 
                }
            </div>
        </TransitionModal>
    );
}

export default LoginDialog;