import { getWithRetry } from './fetch';

const apiGetAvailableMenus = async ( setMenusAndModifiers, dispatch ) => {
    await getWithRetry( 'apiGetAvailableMenus', 
        '/api/menus/available/', setMenusAndModifiers,
        dispatch, 30, 2000
    );
}

export default apiGetAvailableMenus;
