import React from 'react';

const PickupProcedure = () => (
    <ul>
        <li>Please pick up in the store unless you need curbside pickup.</li>
        <br />
        <strong>IF YOU NEED CURBSIDE PICKUP:</strong>
        <br />
        <li>Park as near the ramp on Free street as is safe and possible.</li>
        <li>Call the bakery at 286-3376 to let them know you have arrived.</li>
        <li>Open the door or trunk where you want us to place your order.</li>
        <li>Get back into your vehicle. The staff will put your order into your vehicle.</li>
        <li>Enjoy your order!</li>
    </ul>
);

export default PickupProcedure;