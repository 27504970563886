import React, { useState } from "react";

const FormFactory = () => {

    const fields = {
        __defaultClass__: "",
        __defaultErrorClass__: "error"
    }
    // actual input values have a type other than "submit" and are not currently hidden
    fields.__isInputField__ = name => ( 
        fields[name].type && fields[name].type !== "submit"  && ! fields[name].hidden );
    fields.__valuesOnly__ = () => (
        Object.keys( fields )
            .filter( name => fields.__isInputField__( name ) )
            .reduce( ( values, name ) => { 
                values[name] = fields[name].type === "checkbox" 
                                ? fields[name].ref.checked 
                                : fields[name].value;
                return values; }, {} )
    );

    const field = (name) => {
        if (!fields[name]) {
            fields[name] = {};
        }
        return fields[name];
    }
    const cacheValue = ( name, value ) => field( name ).value = value;
    const cacheRef = ( name, ref ) => field( name ).ref = ref;
    const cacheField = ( name, isFieldValid, type, hidden, value ) => {
        fields[name] = { ...field( name ), ...{ isFieldValid, type, hidden, value } };
    };

    ///////////////////////////////////////////////////////////////////////////////////////////
    //                                                                                       //
    //  Form - wrapper for HTML form - provides validation and other services.               //
    //                                                                                       //
    ///////////////////////////////////////////////////////////////////////////////////////////

    const Form = (props) => {
        if ( props.defaultInputClass ) {
            fields.__defaultClass__= props.defaultInputClass;
        }
        if ( props.defaultErrorClass ) {
            fields.__defaultErrorClass__= props.defaultErrorClass;
        }

        const isFormValid = () => {
            let formIsValid = true;
            for ( const name in fields ) {
                // fields object contains some non-input field values - exclude from validation
                if ( ! fields.__isInputField__( name ) ) {
                    continue;
                }

                const value = fields[name].value || null;
                formIsValid = fields[name].isFieldValid( formIsValid, value ) && formIsValid;
            }
            return formIsValid;
        }

        const onSubmit = (e) => {
            // event.preventDefault();
            if ( isFormValid() ) {
                if ( props.onSubmit ) {
                    props.onSubmit( e, fields.__valuesOnly__() );
                }
            } else {
                e.preventDefault();
            }
        }
        const passthru = Object.excludingKeys( props,
            ["children", "onSubmit", "defaultInputClass", "defaultErrorClass"]
        );
        return (
            <form { ...passthru }
                onSubmit={ onSubmit }>
                { props.children }
            </form>
        );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////
    //                                                                                       //
    //  Input - wrapper for input fields - co-ordinate values with enclosing Form.           //
    //                                                                                       //
    ///////////////////////////////////////////////////////////////////////////////////////////
    
    const InputInternal = (props) => {
        const [ inputValue, setInputValue ] = useState( props.value || "" );
        const [ inputValid, setInputValid ] = useState( true );

        const error = (msg) => { throw new Error(msg); };

        if (!props.name && props.type !== "submit" ) {
            error("Forms.Input requires a \"name\" property");
        }

        // Filter out props that are not passed directly to native HTML tag.
        const passthru = Object.excludingKeys( props, 
            [ "type", "value", "validate", "ref", "onChange", "onBlur", "errorClass", "required",
              "getRef", "minLength" ]);
        passthru.type = props.type || "text"; // Default to type=text if not given

        const fieldRef = (ref) => {
            // Get a DOM reference for local use and pass up to user"s Input component if requested.
            cacheRef( props.name, ref );
            if ( props.getRef ) {
                props.getRef( ref );
            }
        };

        const onChange = event => {
            // When field value changes update local state and trigger re-render.
            setInputValue( event.target.value );
            if ( props.onChange ) {
                props.onChange( event );
            }
        }

        const isValid = ( focus, currentValue ) => {
            // Builtin validation. Override default validation by passing funtion thru validate prop.
            const isValueOK = ( testValue ) => {
                const value = testValue ? testValue.trim() : "";

                if ( !props.required && ( !value || !props.validate ) ) {
                    return true;
                }
                if ( props.required && !value ) {
                    return false;
                }
                if ( props.minLength && value.length < props.minLength ) {
                    return false;
                }
                if ( props.type === "email" &&
                    !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test( value ) ) {
                    return false;
                }
                if ( typeof props.validate === "function") {
                    return props.validate(value, fields.__valuesOnly__() );
                }
                if ( props.validate === "zipcode" &&
                    !/^[0-9]{5}$/.test( value ) ) {
                    return false;
                }
                if ( props.validate === "zipcode9" &&
                    !/^[0-9]{5}(?:-[0-9]{4})?$/.test( value ) ) {
                    return false;
                }
                return true;
            }
            
            if ( props.type === "checkbox" ) {
                return true;
            }

            const isOK = isValueOK ( currentValue );
            if ( isOK !== inputValid ) {
                setInputValid( isOK )
            }

            if ( !isOK ) {
                if ( focus ) {
                    fields[props.name].ref.focus();
                }
            }

            return isOK;
        }

        const onBlur = event => {
            // Trim whitespace and apply validation when field loses focus.
            // Pass event and validation result to enclosing component if requested.
            const value = inputValue ? inputValue.trim() : "";
            cacheValue( props.name, value );
            isValid( false, value );

            if ( props.onBlur ) {
                props.onBlur( event );
            }
        }

        // Register input field validation with enclosing form - allows form validation on submit.
        cacheField( props.name, isValid, passthru.type, props.hidden, inputValue.trim() );

        // Apply error class in case of errors.
        const errorClass = inputValid ? "" : props.errorClass || fields.__defaultErrorClass__;
        const inputClasses = [ props.className || fields.__defaultClass__, errorClass ].join(" ");
        const styles = { ...props.style, ...( props.hidden ? { display: "none" } : null ) };

        return (
            props.type === "select" ?
                <select className={ inputClasses } style={ styles } ref={ fieldRef } 
                    value={ inputValue }
                    onChange={ onChange }
                    onBlur={ onBlur }
                    { ...passthru } >
                    { props.children }
                </select>

            : props.type === "submit" ?
                <input className={ props.className } style={ styles } ref={ fieldRef } 
                    value={ props.value }
                    { ...passthru }
                />

            : props.type !== "textarea" ?
                <input className={ inputClasses } style={ styles } ref={ fieldRef }
                    value={ inputValue }
                    onChange={ onChange }
                    onBlur={ onBlur }
                    { ...passthru }
                />
            :
                <p>{ error(`Forms.Input: Unsupported input type: "${ props.type }"`) }</p>
        );
    }

    const Input = InputInternal;
    const Select = ( props ) => InputInternal( { ...props, type: "select" } );

    return { Form, Input, Select };
}

export default FormFactory;