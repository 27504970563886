import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal, orderItemUtils } from '@fernleaf/util';

import apiReportSystemError from '../../api/reportSystemError';
import apiGetGiftCard from '../../api/getGiftCard';
import apiPayOrder from '../../api/payOrder';

import { clearCart, setPickupDate } from '../../store/redux';

import classes from './GiftCardDialog.module.css';

const focusInput = ( inputField ) => inputField && inputField.focus();

const GC_FORMAT_INCOMPLETE = /^(D[\s-]?)?[0-9]*$/;

const GC_NUMBER_REQUIRED = 'Gift card number is required.';
const LEGACY_BALANCE_MESSAGE = 'Legacy card. Enter verified balance.';

const isVerified = ( giftCard ) => (
    giftCard.checkDigit || 
    giftCard.redemptions.some( redemption => redemption.orderId !== 'CONVERTED' )
);

const GiftCardDialog = ( { 
    order, setOrder, paymentTotal, NavigationButtons, onCancel 
} ) => {
    const dispatch = useDispatch();
    const [ blockInput, setBlockInput ] = useState( false );
    const [ loadTimer, setLoadTimer ] = useState( null );
    const [ balanceTimer, setBalanceTimer ] = useState( null );
    const [ giftCard, setGiftCard ] = useState( null );
    const [ giftCardNumber, setGiftCardNumber ] = useState( '' );
    const [ giftCardPayment, setGiftCardPayment ] = useState( '' );
    const [ verifiedBalance, setVerifiedBalance ] = useState( null );
    const [ message, setMessage ] = useState( GC_NUMBER_REQUIRED );
    
    const loadGiftCard = useCallback( ( giftCardNumber ) => {
        if ( ! giftCardNumber ) {
            return;
        }
        apiGetGiftCard( giftCardNumber, 
            ( reply ) => {
                if ( reply.giftCardError ) {
                    setMessage( reply.giftCardError );
                } else {
                    const giftCard = reply;
                    setGiftCard( giftCard );
                    setVerifiedBalance( null );
                    setMessage( isVerified( giftCard ) ? null : LEGACY_BALANCE_MESSAGE );
                }
            },
            dispatch
        );
    }, [ setGiftCard, setVerifiedBalance, setMessage, dispatch ] );

    const processGiftCardNumber = useCallback( ( e ) => {
        clearTimeout( loadTimer );
        e.stopPropagation();
        let newValue = e.target.value;
        if ( newValue ) {
            newValue = newValue.toUpperCase();
        }
        
        if ( GC_FORMAT_INCOMPLETE.test( newValue ) ) {
            setGiftCard( null );
            setGiftCardNumber( newValue );
            setLoadTimer( setTimeout( () => loadGiftCard( newValue ), 1000 ) );
        } else {
            newValue = giftCardNumber;
        }

        e.target.value = newValue;
        setMessage( newValue ? null : GC_NUMBER_REQUIRED );
    }, [ loadTimer, giftCardNumber, loadGiftCard, setGiftCard, setGiftCardNumber, setLoadTimer ] );

    const validateInput = useCallback( ( newValue ) => {
        if ( ! newValue ) {
            setMessage( LEGACY_BALANCE_MESSAGE );
        } else if ( newValue > giftCard.remainingBalance ) {
            setMessage( 'The entered balance is greater than the balance on file. Contact manager.' );
        } else {
            setMessage( null );
        }
    }, [ giftCard, setMessage ] );

    const processBalanceInput = useCallback( ( e ) => {
        clearTimeout( balanceTimer );
        setMessage( ' ' ); // Fake an error so button won't activate till validation is called
        const newValue = orderItemUtils.processDollarEntry( e, verifiedBalance, setVerifiedBalance );
        setBalanceTimer( setTimeout( () => validateInput( newValue ), 1000 ) );
    }, [ balanceTimer, verifiedBalance, validateInput, setVerifiedBalance, setBalanceTimer ] );

    const completeGiftCardPayment = useCallback( (e) => {
        setBlockInput( true );
        apiPayOrder( order._id, paymentTotal, null, 'OTHER_GIFT_CARD', giftCardNumber, verifiedBalance,
            ( reply ) => {
                let message = 'Gift card payment failed - CHECK FOR ERROR EMAIL.';
                if ( reply && reply.errors && reply.errors[ 0 ].code === 'TIMEOUT' ) {
                    message = 'Gift card payment timed out - CHECK IN SQUARE TO SEE IF PAYMENT COMPLETED.';
                } 
                if ( ! reply || ( ! reply.redemption && ! reply.giftCardError ) ) {
                    const unexpected = JSON.stringify( reply || 'PAYMENT WAS NULL' );
                    apiReportSystemError( 
                        'SHOP011 - Unexpected issue in Square gift card payment', unexpected, dispatch 
                    );
                    alert( message );
                } else if ( reply.giftCardError ) {
                    setMessage( reply.giftCardError );
                } else {
                    dispatch( clearCart() );
                    dispatch( setPickupDate( null ) );
                    setOrder( reply.order );
                    setGiftCardPayment( reply.redemption );
                }
                setBlockInput( false );
            },
            dispatch
        );
    }, [ 
        order, paymentTotal, giftCardNumber, verifiedBalance, 
        setOrder, setGiftCardPayment, setBlockInput, dispatch 
    ] );

    return (
        <React.Fragment>
            { giftCardPayment ?
                <div onClick={ e => e.stopPropagation() } >
                    <div className={ classes.Legend } >
                        <div>Gift card payment has been accepted.</div>
                        <div>Remaining balance: ${ 
                            ( giftCardPayment.remainingBalance / 100 ).toFixed( 2 ) 
                        }</div>
                    </div>
                    <NavigationButtons order={ order } />
                </div>
            :
                <div onClick={ (e) => e.stopPropagation() } >
                    <div className={ classes.Legend }>Enter gift card number:</div>
                    <div className={ classes.Message } >{ message || ' ' }</div>
                    <table>
                        <tbody>
                            <tr>
                                <td>Gift card number:</td>
                                <td>
                                    <input type='text'
                                        defaultValue={ giftCardNumber } 
                                        placeholder='GC number' 
                                        className={ classes.CardNumberInput }
                                        onInput={ processGiftCardNumber } ref={ focusInput }
                                    />
                                </td>
                            </tr>
                            { giftCard && ! isVerified( giftCard ) &&
                                <tr>
                                    <td>Verify card balance:</td>
                                    <td>
                                        <input type='text'
                                            placeholder='Remaining balance' 
                                            className={ classes.BalanceInput }
                                            onInput={ processBalanceInput } ref={ focusInput }
                                        />
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <Button onClick={ completeGiftCardPayment }
                        className={ classes.Disabled }
                        disabled={ ! giftCard || message }
                    >
                        Pay with gift card
                    </Button>
                    <Button onClick={ onCancel } >Cancel</Button>
                </div>
            }

            { blockInput && <Modal noX showSpinner /> }

        </React.Fragment>
    )
}

export default GiftCardDialog;