import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { isToday, parseISO } from 'date-fns';

import { Button, selectors, orderItemUtils } from '@fernleaf/util';
import apiOrderTogglePickedUp from '../../api/orderTogglePickedUp';
import apiGetOrder from '../../api/getOrder';

import ReceiptModal from '../../ReceiptModal/ReceiptModal';

import classes from './OkNavigationButtons.module.css';

import { 
    getCustomer,
    returnToAdminAction
} from '../../store/redux';

const isTodayDate = ( date ) => {
    if ( typeof date === 'string' ) {
        date = parseISO( date );
    }
    return isToday( date );
}

const OkNavigationButtons = ( { order, children, history } ) => {
    const dispatch = useDispatch();
    const user = useSelector( selectors.getUser );
    const customer = useSelector( getCustomer );
    const [ navigationStarted, setNavigationStarted ] = useState( false );
    const [ showPopup, setShowPopup ] = useState( false );
    const [ gcOrder, setGcOrder ] = useState( null );
    const [ emailSent, setEmailSent ] = useState( null );

    const doNavigationStarted = ( e ) => {
        e.stopPropagation();
        setNavigationStarted( true );
    }

    const returnToAdmin = useCallback( () => {
        dispatch( returnToAdminAction() );
    }, [ dispatch ] );

    const returnToAdminWithPickup = useCallback( () => {
        if ( ! order.pickedUp ) {
            apiOrderTogglePickedUp( order._id, returnToAdmin, dispatch );
        } else {
            returnToAdmin();
        }
    }, [ order, returnToAdmin, dispatch ] );

    const goToOrders = useCallback( () => {
        history.push( '/orders' );
    }, [ history ] );

    const showReceiptPopup = useCallback( ( e ) => {
        e.stopPropagation();
        setShowPopup( true );
    }, [ setShowPopup ] );

    const receiptSent = useCallback( ( sentOK ) => {
        if ( sentOK.ok ) {
            setEmailSent( true );
        } else {
            setEmailSent( false );
        }
        setShowPopup( false );
    }, [ setEmailSent ] );

    const receiptCancelled = useCallback( ( e ) => {
        e.stopPropagation();
        setEmailSent( null );
        if ( ! customer ) {
            goToOrders( e );
        }
        setShowPopup( false );
    }, [ customer, setEmailSent, goToOrders, setShowPopup ] );

    const showGiftCertificateNumbers = useCallback( () => {
        const gcNos = [];
        for ( const item of ( gcOrder || order ).items ) {
            if ( item.variation.squareItemName === 'Gift Certificate' ) {
                gcNos.push( ...item.priceOverrides );
            }
        }
        if ( gcNos.length > 0 ) {
            if ( ! gcOrder ) { // Need to refresh order to get certificate numbers
                apiGetOrder( order._id, reply => setGcOrder( reply.order ), dispatch );
                return;
            }
            return (
                <React.Fragment>
                    <div className={ classes.Alert }>
                        Write number(s) on the gift certificate(s):
                    </div>
                    { gcNos.map( gcNo => (
                        <div className={ classes.Info }>{ 
                            `${ gcNo.description } - $${ 
                                orderItemUtils.formatDollarAmount( gcNo.price ) }`
                        }</div>
                    ) ) }
                </React.Fragment>
            )
        }
    }, [ order, gcOrder, dispatch ] );

    const initialEmail = customer && customer.email === 'guest@fernleaf.biz' ? ''
                            : customer ? customer.email : user ? user.email : '';

    return (
        <React.Fragment>
            <div className={ classes.ButtonPane } >
                { ! navigationStarted &&
                    <div onClick={ doNavigationStarted } >
                        { children ?
                            children

                        : customer ?
                            <React.Fragment>
                                { order && isTodayDate( order.pickupDateAndTime ) &&
                                    <Button onClick={ returnToAdminWithPickup } >Picked Up</Button>
                                }
                                <Button onClick={ returnToAdmin }>Pick Up Later</Button>
                            </React.Fragment>
                        :
                            <Button onClick={ goToOrders } >{ emailSent ? "Done" : "No receipt" }</Button>
                        }
                        <Button onClick={ showReceiptPopup }>Send email receipt</Button>
                        { showGiftCertificateNumbers() }
                        { emailSent   === true  ? 
                            <div className={ classes.Info}>OK. Email receipt was sent.</div> 
                          : emailSent === false ? 
                            <div className={ classes.Alert}>ERROR. Email receipt was NOT sent.</div> 
                          : null 
                        }
                    </div>
                }
            </div>

            { showPopup &&
                <ReceiptModal
                    orderId={ order._id }
                    initialEmail={ initialEmail }
                    onReceiptSent={ receiptSent }
                    onClose={ receiptCancelled }
                />
            }
        </React.Fragment>
    );
}

export default withRouter( OkNavigationButtons );