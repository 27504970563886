import { post } from './fetch';

const apiSaveCart = async ( userId, orderId, pickupDate, cart, notes, cartSaved, dispatch ) => {
    await post( 'apiSaveCart', 
        '/api/order/save-cart', 
        { userId, orderId, pickupDate, cart, notes },
        cartSaved, dispatch
    );
}

export default apiSaveCart;
