import { 
    addDays, addHours, addMinutes,
    endOfDay, startOfDay, subMinutes, differenceInCalendarDays, parseISO, format 
} from 'date-fns';

const DAY_OF_WEEK = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6
};
export const WEEK_DAY = Object.fromEntries( 
    Object.entries( DAY_OF_WEEK ).map( ( [ key, value ] ) => [ value, key ] )
);

export const fromAndThruDates = ( exception ) => ( [
    subMinutes( startOfDay( parseISO( exception.fromDate ) ), 1 ),
    endOfDay( parseISO( exception.thruDate || exception.fromDate ) )
] );

export const excludeDates = ( date, calendar ) => {
    if ( ! calendar ) {
        return true;
    }
    for ( const exception of calendar.exceptions ) {
        const [ fromDate, thruDate ] = fromAndThruDates( exception );
        if ( ! ( date < fromDate ) && ! ( date > thruDate ) ) {
            return ! exception.openHours.isOpen;
        }
    }
    if ( ! calendar[ WEEK_DAY[ date.getDay() ] ].isOpen ) {
        return true;
    }
};

export const nextOpenDate = ( date, calendar ) => {
    if ( excludeDates( date, calendar ) ) {
        return nextOpenDate( addDays( date, 1 ), calendar );
    }
    return date;
};

export const orderDayName = ( date ) => {
    switch ( differenceInCalendarDays( date, new Date() ) ) {
        case 0:  return 'today';
        case 1:  return 'tomorrow';
        default: return format( date, 'EEEE' );
    }
};

export const getHoursForDate = ( date, calendar ) => {
    for ( const exception of calendar.exceptions ) {
        const [ fromDate, thruDate ] = fromAndThruDates( exception );
        // fromAndThruDates adjusts dates so date falls "between" itself
        if ( date > fromDate && date < thruDate ) {
            if ( exception.openHours.isOpen ) {
                return [ exception.openHours.openFrom, exception.openHours.openTo ];
            }
            return [ null, null ];
        }
    }
    const openHours = calendar[ WEEK_DAY[ date.getDay() ] ];
    if ( openHours.isOpen ) {
        return [ openHours.openFrom, openHours.openTo ];
    }
    return [ null, null ];
}

export const canOrderForToday = ( calendar ) => {
    const now = new Date();
    const [ openFrom, openTo ] = getHoursForDate( now, calendar );
    const nowPlus30 = addMinutes( now, 30 );
    const nowTime = nowPlus30.getHours() * 100 + now.getMinutes();
    return openFrom && nowTime <= openTo;
}

export const hourBeforeNextOpening = ( calendar ) => {
    try {
        let nextDay = new Date();
        for ( let i = 0; i < 31; i++ ) {
            nextDay = addDays( nextDay, 1 );
            // Look for next open day - give up after one month
            const [ openFrom, ] = getHoursForDate( nextDay, calendar );
            if ( openFrom ) {
                const hh = Math.floor( openFrom / 100 );
                const mm = openFrom % 100;
                if ( hh > 0 && mm < 60 ) {
                    return addHours( addMinutes( startOfDay( nextDay ), mm ), hh - 1 );
                }
                return null;
            }
        }
    } catch( error ) {
        console.error( 'Error getting next open hours', error );
    }
}
