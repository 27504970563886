import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, selectors } from '@fernleaf/util';

import apiGetOrders from '../api/getOrders';
import { getCustomer, reloadCart } from '../store/redux';

import OrderDetail from '../Orders/OrderDetail/OrderDetail';
import classes from './SavedCarts.module.css';

const SavedCarts = ( { history } ) => {
    const dispatch = useDispatch();
    const user = useSelector( selectors.getUser );
    const customer = useSelector( getCustomer );
    const [ orders, setOrders ] = useState( null );
    const [ showOrder, setShowOrder ] = useState( null );

    useEffect( () => {
        if ( ! orders && user ) {
            apiGetOrders( 
                customer ? customer.id : user.id, 
                ( orders ) => setOrders( 
                    orders && orders.filter( order => order.name === 'SAVED CART' )
                ), dispatch );
        }
    }, [ customer, user, orders, dispatch ] );

    const useOrderAsCart = (e) => {
        e.stopPropagation();
        dispatch( reloadCart( showOrder ) );
        history.push( '/cart' );
    }

    const dateFrom = ( date, short ) => (
        new Date( date ).toString( 'en-US' ).substr( 4, short ? 6 : 11 )
    );

    return ( ! Object.isEmpty( user ) && 
        <React.Fragment>
            <div className={ classes.Main } >
                <h2>Saved Carts</h2>
                { ! orders || orders.length === 0 ?
                    <div className={ classes.NoCarts }>You have no saved carts.</div>
                :
                    <div className={ classes.Orders } >
                        <div className={ [ classes.Order, classes.Header ].join( ' ' ) } >
                            <div className={ classes.Date } >Pickup Date</div>
                            <div className={ classes.Date } >Saved Date</div>
                            <div className={ classes.ItemsHeader } >Items</div>
                        </div>
                        { orders && orders.map( order => (
                            <div key={ order._id } onClick={ () => setShowOrder( order ) }
                                className={ classes.Order } 
                            >
                                <div className={ classes.Date } >
                                    { dateFrom( order.pickupDateAndTime ) }
                                </div>
                                <div className={ classes.Date } >
                                    { dateFrom( order.orderDate ) }
                                </div>
                                <div className={ classes.Items } >
                                    { order.items
                                        .map( item => item.variation.squareItemName ).join( ', ' ) 
                                    }
                                </div>
                            </div>
                        ) ) }
                    </div>
                }
                <div className={ classes.Footer } >
                    For cart details click on the pickup date.
                </div>
            </div>

            { showOrder && 
                <OrderDetail order={ showOrder } onClose={ () => setShowOrder( null ) } >
                    <Button onClick={ useOrderAsCart }>Reload</Button>
                    NOTE: This will replace any items currently in your cart.
                    If you want to save those items go to the cart and save it 
                    before you reload this cart.
                </OrderDetail>
            }
        </React.Fragment>
    )
}

export default withRouter( SavedCarts );