import { getWithRetry } from './fetch';

const apiGetCalendar = async ( setCalendar, dispatch ) => {
    await getWithRetry( 'apiGetCalendar', 
        '/api/calendar', setCalendar,
        dispatch, 30, 5000
    );
}

export default apiGetCalendar;
