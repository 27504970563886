import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal, orderItemUtils } from '@fernleaf/util';

import apiCreatePartialPaymentOrder from '../../api/createPartialPaymentOrder';
import apiGetStoredBalance from '../../api/getStoredBalance';
import apiReportSystemError from '../../api/reportSystemError';

import PaymentButtons from '../PaymentButtons/PaymentButtons';
import OkNavigationButtons from '../OkNavigationButtons/OkNavigationButtons';

import classes from './SplitPaymentDialog.module.css';

const SplitPaymentDialog = ( { 
    customerOrder, paymentTotal, squareIds, setSquareIds, onCancel 
} ) => {
    const paymentOne = Math.round( paymentTotal / 2 );
    const paymentTwo = paymentTotal - paymentOne;

    const dispatch = useDispatch();
    const [ blockInput, setBlockInput ] = useState( false );
    const [ paymentOneAmount, setPaymentOneAmount ] = useState( paymentOne );
    const [ paymentTwoAmount, setPaymentTwoAmount ] = useState( paymentTwo );
    const [ partialPaymentOrder, setPartialPaymentOrder ] = useState( null );
    const [ paymentsProcessed, setPaymentsProcessed ] = useState( 0 );
    const [ storedBalance, setStoredBalance ] = useState( null );
    const [ showPaymentButtons, setShowPaymentButtons ] = useState( false );
    const [ message, setMessage ] = useState( null );

    const refOne = useRef();
    const refTwo = useRef();

    useEffect( () => {
        if ( customerOrder && ! storedBalance ) {
            apiGetStoredBalance( customerOrder._id, setStoredBalance, dispatch );
        }
    }, [ customerOrder, storedBalance, setStoredBalance, dispatch ] );

    const processAmountOneEntry = useCallback( ( e ) => {
        const newValue = orderItemUtils.processDollarEntry( e, paymentOneAmount, setPaymentOneAmount );
        if ( ! newValue || newValue < 100 ) {
            setMessage( 'Payment amount one must be at least $1.00' );
        } else if ( newValue > paymentTotal - 100 ) {
            setMessage( 'Payment amount one must be less than total amount less $1.00' );
        } else {
            const otherAmount = paymentTotal - newValue;
            refTwo.current.value = orderItemUtils.formatDollarAmount( otherAmount );
            setPaymentTwoAmount( otherAmount );
            setMessage( null );
        }
    }, [ paymentOneAmount, paymentTotal, setPaymentOneAmount, setMessage ] );

    const processAmountTwoEntry = useCallback( ( e ) => {
        const newValue = orderItemUtils.processDollarEntry( e, paymentTwoAmount, setPaymentTwoAmount );
        if ( ! newValue || newValue < 100 ) {
            setMessage( 'Payment amount two must be at least $1.00' );
        } else if ( newValue > paymentTotal - 100 ) {
            setMessage( 'Payment amount two must be less than total amount less $1.00' );
        } else {
            const otherAmount = paymentTotal - newValue;
            refOne.current.value = orderItemUtils.formatDollarAmount( otherAmount );
            setPaymentOneAmount( otherAmount );
            setMessage( null );
        }
    }, [ paymentTwoAmount, paymentTotal, setPaymentTwoAmount, setMessage ] );

    const acceptPayment = useCallback( ( e ) => {
        e.stopPropagation();
        setShowPaymentButtons( true );
    }, [] );

    const createPartialPaymentOrder = useCallback( ( e ) => {
        e.stopPropagation();

        const processResult = ( result ) => {
            if ( ! result.order ) {
                console.log( 'Unexpected reply from create partial order:', result );
                apiReportSystemError( 'Error creating partial order', result );
                alert( 'The order could not be created. Please try again.' );
            } else {
                setPartialPaymentOrder( result.order );
            }
            setBlockInput( false );
        };

        if ( ! partialPaymentOrder ) {
            setBlockInput( true );
            apiCreatePartialPaymentOrder( 
                paymentsProcessed === 0 ? paymentOneAmount : paymentTwoAmount,
                storedBalance._id,
                processResult,
                dispatch
            );
        }
    }, [ 
        partialPaymentOrder, paymentOneAmount, paymentTwoAmount, paymentsProcessed, storedBalance,
        setPartialPaymentOrder, setBlockInput, dispatch 
    ] );

    const nextPayment = useCallback( ( e ) => {
        e.stopPropagation();
        const newStoredBalance = ( storedBalance ) => {
            setStoredBalance( storedBalance );
            setPaymentsProcessed( storedBalance.payments.length );
            setPartialPaymentOrder( null );
            // setShowPaymentButtons( false );
        }
        apiGetStoredBalance( customerOrder._id, newStoredBalance, dispatch );
    }, [ customerOrder, setStoredBalance, setPaymentsProcessed, setPartialPaymentOrder, dispatch ] );

    const NextNavigationButton = useCallback( ( { order } ) => {
        const buttonText = paymentsProcessed === 0 ? 'Accept next payment' : 'Continue';
        return (
            <OkNavigationButtons order={ order } >
                <Button onClick={ nextPayment } >{ buttonText }</Button>
            </OkNavigationButtons>
        )
    }, [ paymentsProcessed, nextPayment ] );

    const currentPaymentAmount = () => (
        paymentsProcessed === 0 ? paymentOneAmount : paymentTwoAmount 
    );
    
    if ( ! storedBalance ) {
        return <Modal noX showSpinner />;
    }

    return (
        <Modal className={ classes.SplitPaymentModal } NoX >

            { storedBalance.payments.length === 2 ?
                <div onClick={ e => e.stopPropagation() } >
                    <div>All payments have been accepted.</div>
                    <OkNavigationButtons order={ customerOrder } />
                </div>
            :
                <div onClick={ (e) => e.stopPropagation() } >
                    <div className={ classes.Legend }>Enter split amounts:</div>
                    <div className={ classes.Message } >{ message || ' ' }</div>
                    <table>
                        <tbody>
                            <tr>
                                <td>Payment one amount:</td>
                                <td>
                                    <input type='text'
                                        defaultValue={ 
                                            orderItemUtils.formatDollarAmount( paymentOne ) 
                                        } 
                                        placeholder='Amount one' 
                                        className={ classes.AmountInput }
                                        onInput={ processAmountOneEntry } ref={ refOne }
                                        disabled={ paymentsProcessed }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Payment two amount:</td>
                                <td>
                                    <input type='text'
                                        defaultValue={ 
                                            orderItemUtils.formatDollarAmount( paymentTwo ) 
                                        } 
                                        placeholder='Amount two' 
                                        className={ classes.AmountInput }
                                        onInput={ processAmountTwoEntry } ref={ refTwo }
                                        disabled={ paymentsProcessed }
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    { showPaymentButtons ?
                        <React.Fragment>
                            <div className={ classes.PaymentPrompt } >
                                Select payment type for payment of ${ 
                                    orderItemUtils.formatDollarAmount( currentPaymentAmount() )
                                }
                            </div>
                            <PaymentButtons
                                order={ partialPaymentOrder } setOrder={ setPartialPaymentOrder }
                                createOrUpdateOrder={ createPartialPaymentOrder } 
                                paymentTotal={ currentPaymentAmount() } 
                                squareIds={ squareIds }
                                setSquareIds={ setSquareIds }
                                setBlockInput={ setBlockInput }
                                NavigationButtons={ NextNavigationButton }
                                // Force refresh per payment or change in payment amount
                                key={ `${ paymentsProcessed }:${ currentPaymentAmount() }` }
                            />
                        </React.Fragment>
                    :
                        <Button onClick={ acceptPayment }
                            className={ classes.Disabled }
                            disabled={ message }
                        >
                            Accept first payment
                        </Button>
                    }
                    <Button onClick={ onCancel } >Cancel</Button>
                </div>
            }

            { blockInput && <Modal noX showSpinner /> }

        </Modal>
    )
}

export default SplitPaymentDialog;