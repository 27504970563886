import React, { createRef, useState, useEffect } from 'react';
import canClick from '../utils/canClick';
import canUpdate from '../utils/canUpdate';

import classes from './Dropdown.module.css';

const Dropdown = ( { thing, field, update, values, number, optional, open, className } ) => {

    // values: For untranslated display values pass a simple array of choices
    //         For translated display values pass an array [ [ val1, display1 ], ... ]

    // open:   Always show native dropdown.

    update = ( update && ( canUpdate( thing ) || thing.isSelection ) ) || open;

    const [ showInput, setShowInput ] = useState( false );
    const value = thing[ field ];
    const displayValue = ! values ? value : Array.isArray( values[ 0 ] )
            ? values.reduce( ( display, entry ) => 
                ( entry[ 0 ] === value ? entry[ 1 ] : display ),
                value )       
            : value;

    const classNames = [
        update && classes.CanUpdate,
        className,
        update && thing.isUpdated && thing.isUpdated( field ) && classes.Pending
    ];
        
    const dropdownRef = update ? createRef() : null;
    
    useEffect( () => {
        showInput && dropdownRef.current && dropdownRef.current.focus()
    }, [ showInput, dropdownRef ] );

    return (
        <div className={ `${ classes.Updatable } ${ classNames.join( ' ' ) }` } >
        { ! open &&
            <div className={ `${ classes.Display }` } 
                onClick={ canClick( update, () => setShowInput( true ) ) } 
            >
                { displayValue }
            </div>
        }

        { update ?
            <select ref={ dropdownRef } required={ ! optional }
                className={ [ 
                    classes.Input, classes.DropDown, className,
                    showInput || open ? classes.Active : '' 
                ].join( ' ' ) }
                value={ value } onClick={ (e) => e.stopPropagation() }
                onChange={ (e) => {
                    let undefined;
                    thing[ field ] = 
                        ! number ? e.target.value
                        : e.target.value.trim() === "" ? undefined
                        : + e.target.value;
                    e.target.blur();
                } }
                onBlur={ (e) => setShowInput( false ) }
            >
                { values.map( ( value ) => {
                    if ( ! Array.isArray( value ) ) {
                        value = [ value, value ];
                    }
                    return <option key={ value[ 0 ] } value={ value[ 0 ] }>{ value[ 1 ] }</option>
                } ) }
            </select>
        : null }
        </div>
    );
};

export default Dropdown;
