import { createStore, combineReducers, compose } from 'redux';

import { utilReducer } from '@fernleaf/util';
import { productsReducer } from './redux';

const redux_composer = process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null;
const composeEnhancers = redux_composer || compose;

const store = createStore(
    combineReducers( {
        util:     utilReducer,
        products: productsReducer
    } ),
    composeEnhancers()
);

export default store;
