import React, { useEffect, useState, useCallback } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, selectors, orderItemUtils } from '@fernleaf/util';
import PaymentButtons from './PaymentButtons/PaymentButtons';
import SplitPaymentDialog from './SplitPaymentDialog/SplitPaymentDialog';

import apiGetSquareIds from '../api/getSquareIds';
import apiCreateOrUpdateOrder from '../api/createOrUpdateOrder';
import apiPayOrder from '../api/payOrder';
import apiReportSystemError from '../api/reportSystemError';

import { closingTime } from '../PickupDate/PickupDate';
import { 
    getCustomer, getCart, getCartNotes, getPickupDate, getCalendar, getOrderToPay, getPartialPaymentItem,
    returnToAdminAction
} from '../store/redux';

import { priceUtils } from '@fernleaf/util';
import CartTotals from '../Cart/CartTotals/CartTotals';
import classes from './Checkout.module.css';
import { format } from 'date-fns';
import isToday from 'date-fns/isToday';

const EMAIL = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
const PHONE = /^(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/;

const isPaid = ( order ) => order && order.squarePaymentId && order.squarePaymentId !== 'HOLD';

const Checkout = ( { history } ) => {
    const dispatch = useDispatch();
    const user = useSelector( selectors.getUser );
    const customer = useSelector( getCustomer );
    const cart = useSelector( getCart );
    const cartNotes = useSelector( getCartNotes );
    const pickupDate = useSelector( getPickupDate );
    const calendar = useSelector( getCalendar );
    const orderToPay = useSelector( getOrderToPay );
    const partialPaymentItem = useSelector( getPartialPaymentItem );
    const [ userLoaded, setUserLoaded ] = useState( false );
    const [ customerLoaded, setCustomerLoaded ] = useState( false );
    const [ orderLoaded, setOrderLoaded ] = useState( false );
    const [ showTaxCert, setShowTaxCert ] = useState( false );
    const [ tempTaxCert, setTempTaxCert ] = useState();
    const [ taxCert, setTaxCert ] = useState();
    const [ taxCertInputRef ] = useState( React.createRef() );
    const [ blockInput, setBlockInput ] = useState( false );
    const [ squareIds, setSquareIds ] = useState();
    const [ name, setName ] = useState( '' );
    const [ email, setEmail ] = useState( '' );
    const [ phone, setPhone ] = useState( '' );
    const [ pickupName, setPickupName ] = useState( '' );
    const [ pickupPhone, setPickupPhone ] = useState( '' );
    const [ order, setOrder ] = useState();
    const [ showHoldConfirm, setShowHoldConfirm ] = useState( false );
    const [ showSplitPaymentDialog, setShowSplitPaymentDialog ] = useState( false );
    const [ showTip, setShowTip ] = useState( false );
    const [ tipAmount, setTipAmount ] = useState();
    const [ tempTipAmount, setTempTipAmount ] = useState( 0 );
    const [ tipInputRef ] = useState( React.createRef() );
    const [ messages, setMessages ] = useState( [] );
    const [ orderingAsGuest, setOrderingAsGuest ] = useState( false );
    const [ inPerson, setInPerson ] = useState( false );
    const [ outOfStock, setOutOfStock ] = useState( null );
    const [ noAmount, setNoAmount ] = useState( false );

    const orderTotal = priceUtils.orderPaymentTotal( cart, taxCert );

    useEffect ( () => {
        if ( ! squareIds ) {
            apiGetSquareIds( setSquareIds, dispatch );
        }
    }, [ squareIds, setSquareIds, dispatch ] );

    useEffect ( () => {
        let person = null;

        if ( user && ! userLoaded && ! customer ) {
            person = user;
            setUserLoaded( true );
        }
        
        if ( customer && ! customerLoaded ) {
            setOrderingAsGuest( customer.email === 'guest@fernleaf.biz' )
            setInPerson( customer.inPerson );
            person = customer;
            setCustomerLoaded( true );
        }

        if ( person ) {
            setName( person.name || '' );
            setEmail( person.email || '' );
            setPhone( person.phone || '' );
        }
    }, [ customer, customerLoaded, user, userLoaded ] );

    useEffect ( () => {
        if ( orderToPay && ! order ) {
            setOrder( orderToPay );
        }
    }, [ orderToPay, order ] );

    useEffect ( () => {
        if ( order && ! orderLoaded ) {
            setPickupName( order.pickupName || '' );
            setPickupPhone( order.pickupPhone || '' );
            setTaxCert( order.taxCert );
            setOrderLoaded( true );
        }
    }, [ order, orderLoaded ] );

    useEffect ( () => {
        // if ( order && isPaid( order ) ) {
        //     setMessages( [ 'THIS ORDER IS ALREADY PAID - CHECK IN SQUARE' ] );
        //     return;
        // }

        const messages = [];

        if ( name.trim().length === 0 ) {
            messages.push( 'Contact name is required.' );
        }

        if ( email.trim().length === 0 ) {
            messages.push( 'Contact email is required.' );
        } else if ( ! email.match( EMAIL ) ) {
            messages.push( 'Invalid email address.' );
        }
        
        if ( phone.trim().length === 0 ) {
            messages.push( 'Contact phone is required.' );
        } else if ( ! phone.match( PHONE ) ) {
            messages.push( 'Invalid contact phone number.' );
        }

        if ( orderingAsGuest && pickupName.trim().length === 0 ) {
            if ( ! inPerson ) {
                messages.push( 'Pickup name is required for guest orders.' );
            } else if ( pickupDate && ! isToday( pickupDate ) ) {
                messages.push( 'Pickup name is required for orders picked up later.' )
            }
        }

        if ( orderingAsGuest && pickupPhone.trim().length === 0 ) {
            if ( ! inPerson ) {
                messages.push( 'Pickup phone is required for guest orders.' );
            } else if ( pickupDate && ! isToday( pickupDate ) ) {
                messages.push( 'Pickup phone is required for orders picked up later.' )
            }
        }

        if ( pickupPhone.trim().length > 0 && ! pickupPhone.match( PHONE ) ) {
            messages.push( 'Invalid pickup phone number.' );
        }

        setMessages( messages );
    }, [ name, email, phone, pickupName, pickupPhone, customer, orderingAsGuest, inPerson, order,
         pickupDate, setMessages ] 
    );

    useEffect ( () => {
        if ( showTaxCert && taxCertInputRef && taxCertInputRef.current ) {
            taxCertInputRef.current.focus();
        }
    }, [ showTaxCert, taxCertInputRef ] );

    useEffect ( () => {
        if ( showTip ) {
            tipInputRef.current.focus();
        }
    }, [ showTip, tipInputRef ] );

    const returnToCart = useCallback( (e) => {
        e.stopPropagation();
        history.push( '/cart' );
    }, [ history ] );

    const showTaxCertInput = useCallback( (e) => {
        e.stopPropagation();
        setShowTaxCert( true );
    }, [ setShowTaxCert ] );

    const hideTaxCertInput = useCallback( (e) => {
        e.stopPropagation();
        setShowTaxCert( false );
    }, [ setShowTaxCert ] );

    const processTaxCertEntry = useCallback( (e) => {
        setTempTaxCert( ( e.target.value || '' ).trim() || null );
    }, [ setTempTaxCert ] );

    const acceptTaxCert = useCallback( (e) => {
        e.stopPropagation();
        setTaxCert( tempTaxCert );
        setShowTaxCert( false );
    }, [ setTaxCert, setShowTaxCert, tempTaxCert ] );

    const removeTaxCert = useCallback( (e) => {
        e.stopPropagation();
        setTaxCert( null );
        setShowTaxCert( false );
    }, [ setTaxCert, setShowTaxCert ] );

    const showTipInput = useCallback( (e) => {
        e.stopPropagation();
        setTempTipAmount( tipAmount || 0 );
        setShowTip( true );
    }, [ setTempTipAmount, setShowTip, tipAmount ] );

    const hideTipInput = useCallback( (e) => {
        e.stopPropagation();
        setShowTip( false );
    }, [ setShowTip ] );

    const processTipEntry = useCallback( (e) => {
        orderItemUtils.processDollarEntry( e, tempTipAmount, setTempTipAmount );
    }, [ tempTipAmount, setTempTipAmount ] );

    const acceptTipChanges = useCallback( (e) => {
        e.stopPropagation();
        setTipAmount( tempTipAmount || null );
        setShowTip( false );
    }, [ setTipAmount, setShowTip, tempTipAmount ] );

    const removeTip = useCallback( (e) => {
        e.stopPropagation();
        setTipAmount( null );
        setShowTip( false );
    }, [ setTipAmount, setShowTip ] );

    const createOrUpdateOrder = useCallback( ( e, nextAction ) => {
        e.stopPropagation();

        const processResult = ( result ) => {
            if ( ! result.order ) {
                console.log( 'Unexpected reply from create order:', result );
                apiReportSystemError( 'Error creating order', result );
                alert( 'The order could not be created. Please try again.' );
            } else {
                const order = result.order;
                setOrder( order );
                setOutOfStock( result.outOfStock );
                if ( order.overrideStockLevel && ! orderToPay ) {
                    alert( 
                        'This order did an override on the stock levels.\n' +
                        '\n' +
                        'YOU MUST VERIFY STOCK LEVELS FOR ALL ITEMS ON THIS ORDER.'
                    );
                }
                nextAction && nextAction( order );
            }
            setBlockInput( false );
        };

        if ( ! order || ! isPaid( order ) ) {
            setBlockInput( true );
            apiCreateOrUpdateOrder(
                { user: customer || user, name, email, phone,
                    orderedBy: customer && user.name,
                    taxCert  
                },
                { pickupName, pickupPhone },
                pickupDate, cart, cartNotes, orderTotal,
                order && order._id,
                processResult,
                dispatch
            );
        }
    }, [ order, customer, user, name, email, phone, taxCert, orderToPay,
         pickupName, pickupPhone, pickupDate, cart, cartNotes, orderTotal,
         setOrder, setOutOfStock, setBlockInput, dispatch ]
    );

    const holdOrder = ( order ) => {
        apiPayOrder( order._id, orderTotal, null, 'HOLD', null, null,
            ( result ) => {
                dispatch( returnToAdminAction() );
            },
            dispatch
        );
    }

    const placeHoldOrder = (e) => {
        createOrUpdateOrder( e, holdOrder );
        e.stopPropagation();
    }

    const showHoldConfirmDialog = (e) => {
        e.stopPropagation();
        setShowHoldConfirm( true );
    }

    const cancelHoldConfirm = (e) => {
        e.stopPropagation();
        setShowHoldConfirm( false );
    }
    
    const your = customer ? 'Customer\'s' : 'Enter your';

    const outOfStockItems = useCallback( () => cart.filter( 
        orderItem => outOfStock[ orderItem.variation.squareVariationId ] 
    ), [ cart, outOfStock ] );

    const startSplit = useCallback( ( e ) => {
        createOrUpdateOrder( e, () => {
            setShowSplitPaymentDialog( true );
        } );
    }, [ createOrUpdateOrder, setShowSplitPaymentDialog ] );

    const hideSplitPaymentDialog = useCallback( ( e ) => {
        e.stopPropagation();
        setShowSplitPaymentDialog( false );
    }, [ setShowSplitPaymentDialog ] );

    return (
        <div className={ classes.Main } >
            <div className={ classes.Links } >
                { orderToPay ? <div className={ classes.ContinueShopping }>&nbsp;</div> :
                    <Link to={ customer ? '/products' : '/' } className={ classes.ContinueShopping } 
                    >Continue Shopping</Link>
                }
                <Link to='/cart' className={ classes.Cart } >Cart</Link>
            </div>

            <div className={ classes.Content } >
                { calendar && pickupDate &&
                    <div className={ classes.ClosingTime } >
                        <strong>NOTE: { customer ? 'TELL THE CUSTOMER: ' : '' }</strong>
                        On your pickup date, { format( pickupDate, 'EEEE, MMM d' ) },
                        <strong> the store closes at { closingTime( pickupDate, calendar ) }</strong>.
                        You will not be able to pick up your order if you arrive
                        later than that.
                    </div>
                }
                { ! orderingAsGuest &&
                    <div className={ classes.ContactInfo } >
                        <div>{ your } contact details:</div>
                        <input type='text' value={ name } 
                            onChange={ (e) => setName( e.target.value ) } 
                            placeholder={ 'First-name Last-name' } />
                        <input type='email' value={ email } 
                            onChange={ (e) => setEmail( e.target.value ) } 
                            placeholder={ `${ your } email` } />
                        <input type='phone' value={ phone } 
                            onChange={ (e) => setPhone( e.target.value ) } 
                            placeholder={ `${ your } phone` } />
                    </div>
                }

                <div className={ classes.ContactInfo } >
                    <div>
                        Enter contact details for the person picking up 
                        the order { customer ? '' : <strong>if not you</strong> }:
                    </div>
                    <input type='text' value={ pickupName } 
                        onChange={ (e) => setPickupName( e.target.value ) } 
                        placeholder='Pickup first-name last-name' />
                    <input type='phone' value={ pickupPhone } 
                        onChange={ (e) => setPickupPhone( e.target.value ) } 
                        placeholder='Pickup phone no' />
                </div>

                { customer &&
                    <div className={ classes.TaxCert } >
                        <div className={ classes.Prompt } onClick={ showTaxCertInput } >
                            { taxCert ? 'Change Tax Cert' : 'Tax Exempt?' }
                        </div> 
                        { taxCert &&
                            <div className={ classes.TaxCertValue }>Tax certificate: { taxCert }</div> 
                        }
                    </div>
                }
                { showTaxCert &&
                    <Modal onClick={ hideTaxCertInput } >
                        <div>Enter tax cert:</div>
                        <br />
                        <input id='taxCertInput' type='text' 
                            onClick={ (e) => e.stopPropagation() }
                            defaultValue={ taxCert } 
                            placeholder='Tax Certificate' className={ classes.TaxCertInput }
                            onInput={ processTaxCertEntry } ref={ taxCertInputRef }
                        />
                        <Button onClick={ acceptTaxCert } >Add Tax Cert</Button>
                        <Button onClick={ removeTaxCert } >Remove Tax Cert</Button>
                    </Modal>
                }

                <CartTotals 
                    cart={ cart } tipAmount={ tipAmount } taxCert={ taxCert }
                    setNoAmount={ setNoAmount }
                />

                <div className={ classes.Prompt } onClick={ showTipInput } >
                    { tipAmount ? 'Change Tip Amount' : 'Add Tip' }
                </div>

                { showTip &&
                    <Modal onClick={ hideTipInput } >
                        <div>Enter tip amount:</div>
                        <br />
                        <input id='tipInput' type='text' 
                            defaultValue={ orderItemUtils.formatDollarAmount( tipAmount ) } 
                            placeholder='Tip amount' className={ classes.TipInput }
                            onInput={ processTipEntry } ref={ tipInputRef }
                            onClick={ (e) => e.stopPropagation() }
                        />
                        <Button onClick={ acceptTipChanges } >Add Tip</Button>
                        <Button onClick={ removeTip } >Remove Tip</Button>
                    </Modal>
                }

                { outOfStock ?
                    <Modal onClick={ returnToCart } >
                        <div>
                            Your order could not be completed. 
                            Some items went out of stock while you were shopping:
                        </div>
                        <br />
                        { outOfStockItems().map( orderItem =>
                            <div key={ orderItem.variation.squareVariationId } >
                                { orderItem.variation.displayName }
                                { orderItem.variation.squareItemName }
                            </div>
                        ) }
                        <Button onClick={ returnToCart } >OK</Button>
                    </Modal>
                : squareIds && messages.length === 0 && ! noAmount ?
                    <PaymentButtons
                        order={ order } setOrder={ setOrder }
                        createOrUpdateOrder={ createOrUpdateOrder } 
                        paymentTotal={ orderTotal } 
                        tipAmount={ tipAmount }
                        squareIds={ squareIds }
                        setSquareIds={ setSquareIds }
                        setBlockInput={ setBlockInput }
                    >
                        { customer && ( ! order || order.squarePaymentId !== 'HOLD' ) &&
                            <Button onClick={ showHoldConfirmDialog } 
                                className={ classes.Disabled }
                                disabled={ tipAmount ||
                                    ( orderingAsGuest && ( ! pickupName || ! pickupPhone ) )
                                }
                            >
                                { orderingAsGuest && ( ! pickupName || ! pickupPhone ) ?
                                    'Need pickup info to hold' :
                                  tipAmount ? 'Remove tip to hold order' :
                                    'Hold for payment'
                                }
                            </Button>
                        }

                        { customer && partialPaymentItem && ! isPaid( order ) &&
                            <Button onClick={ startSplit } 
                                className={ classes.SplitPaymentButton }
                                disabled={ order && order.squarePaymentId && orderTotal < 200 }
                            >
                                Split Payment
                            </Button>
                        }
                    </PaymentButtons>
                : messages.length > 0 ?
                    <ul className={ classes.Messages } >
                        { messages.map( message =>
                            <li key={ message } >{ message }</li>
                        ) }
                    </ul>
                :
                    null
                }
            </div>

            { blockInput && <Modal noX showSpinner /> }

            { showHoldConfirm &&
                <Modal NoX>
                    <div onClick={ e => e.stopPropagation() } >
                        <div>
                            <div>Confirm that the order is to be held for later payment.</div>
                            <Button onClick={ placeHoldOrder } >Hold for payment</Button>
                            <Button onClick={ cancelHoldConfirm } >Cancel</Button>
                        </div>
                    </div>
                </Modal>
            }

            { showSplitPaymentDialog &&
                <SplitPaymentDialog
                    customerOrder={ order }
                    paymentTotal={ orderTotal + ( tipAmount || 0 ) }
                    squareIds={ squareIds }
                    setSquareIds={ setSquareIds }
                    onCancel={ hideSplitPaymentDialog }
                />
            }

            <div className={ classes.FooterSpacer } />
            
            <div className={ classes.Footer } >
                <div className={ classes.FooterText } >Payments handled by Square</div>
                <div className={ classes.SquareLogo } > 
                    <img src='https://squareup.com/favicon.ico' alt='Square' />
                </div>
            </div>
        </div>
    );
}

export default withRouter( Checkout );