import React, { useState } from 'react';

import DiagonalFill from '../DiagonalFill/DiagonalFill';
import Modal from '../Modal/Modal';

import classes from './Thumbnail.module.css';

const DEFAULT_PICTURE = '/pictures/other/cake-placeholder.png'

const Thumbnail = ( { pictures, small, noDefault, className, onClick } ) => {
    const [ showPreview, setShowPreview ] = useState( false );

    if ( noDefault && ( ! pictures || ( Array.isArray( pictures ) && pictures.length === 0 ) ) ) {
        return null;
    }

    let pictureURL = pictures || DEFAULT_PICTURE;

    if ( Array.isArray( pictures ) ) {
        if ( pictures.length === 0 ) {
            pictureURL = DEFAULT_PICTURE;
        } else {
            const selected = Math.floor( pictures.length * Math.random() );
            pictureURL = pictures[ selected ];
        }
    }

    const thumbnailClasses = [ 
        classes.Thumbnail, 
        small && classes.Small,
        className
    ].join( ' ' );

    if ( ! pictureURL || pictureURL === 'NONE' ) { // TODO - make generic CAKE image for missing thumbnails
        return <div className={ thumbnailClasses }>{ pictureURL !== 'NONE' && '?' }</div>
    }
    const name = pictureURL.substr( pictureURL.lastIndexOf( '/' ) );

    return (
        <React.Fragment>
            <img src={ pictureURL } alt={ name } 
                className={ thumbnailClasses } 
                onClick={ onClick || ( () => setShowPreview( true ) ) }
            />

            { showPreview &&
                <Modal onClick={ () => setShowPreview( false ) } className={ classes.PreviewModal }>
                    <div className={ classes.Preview } >
                        <div className={ classes.PreviewBuffer } ></div>
                        <img src={ pictureURL } 
                            alt={ name } className={ classes.PreviewImage }
                        />
                        <div className={ classes.PreviewBuffer } ></div>
                    </div>
                    <div className={ classes.PreviewBackdrop } >
                        <DiagonalFill />
                    </div>
                </Modal>
            }
        </React.Fragment>
    );
}

export default Thumbnail;