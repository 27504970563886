import React from 'react';

import classes from './Button.module.css';
import facebookLogo from '../assets/images/facebook.png';

const Button = ( props ) => (
    <button
        className={ [
            classes.Button,
            props.facebook ? classes.Facebook : '',
            props.className || '' ].join(' ')
        }
        { ...Object.excludingKeys( props, [ "children", "facebook", "className" ] ) }
    >
        <React.Fragment>
            { props.facebook ? <img src={ facebookLogo } className={ classes.FacebookLogo } /> : null }
            { props.children }
        </React.Fragment>
    </button>
);

export default React.memo( Button );