import { get } from './fetch';

const apiGetSquareIds = async ( setSquareIds, dispatch ) => {
    await get( 'apiGetSquareIds', 
        '/api/square/ids', setSquareIds,
        dispatch
    );
}

export default apiGetSquareIds;
