import React, { Fragment } from 'react';

import NavigationItems from '../NavigationItems/NavigationItems';

import classes from './SystemMenu.module.css';

const SystemMenu = ( { show, toggleSession }) => (
    <Fragment>
        <div className={[ classes.SystemMenu,
                          show ? classes.Open : classes.Closed ].join(' ')} >
            <nav>
                <NavigationItems toggleSession={ toggleSession }/>
            </nav>
        </div>
    </Fragment>
);

export default React.memo( SystemMenu );