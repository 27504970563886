// Create a proxy to allow non-database fields to use the database input widgets.
// call with { getter, setter }

const fieldProxy = ( { getter, setter, name } ) => (
    new Proxy( {}, {
        get: function( target, field, receiver ) {
            if ( field === 'isProxy' ) {
                return true;
            }
            if ( field === 'isShowingOriginalData' || field === 'isUpdated' ) {
                return () => false;
            }
            if ( field === 'isHeader' ) {
                return false;
            }
            if ( field !== name ) {
                throw new Error( `System error - get: field name must be "${ name }" - not "${ field }"` );
            }
            return getter();
        },

        set: function( target, field, value, receiver ) {
            if ( field !== name ) {
                throw new Error( `System error - set: field name must be "${ name }" - not "${ field }"` );
            }
            setter( value );
            return true;
        }
    } )
);

export default fieldProxy;