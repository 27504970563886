import React from 'react';
import refreshIcon from '../assets/images/refresh.png';
import classes from './RefreshIcon.module.css';

const RefreshIcon = ( { onClick, className } ) => (
    <img onClick={ onClick }
        src={ refreshIcon } 
        className= { [ classes.Picture, className ].join( ' ' ) } 
    />
)

export default RefreshIcon