import React from 'react';

const DiagonalFill = () => (
    <svg width="2000" height="2000">
        <defs>
            <pattern id="diagonalHatch" width="5" height="5" 
                patternTransform="rotate(45 0 0)" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="10" style={{ stroke: "grey", strokeWidth: 1 }} />
            </pattern>            
        </defs>

        <rect fill="url(#diagonalHatch)" width="2000" height="2000" />
    </svg>
);

export default DiagonalFill;