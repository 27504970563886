import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal } from '@fernleaf/util';

import CashDialog from '../CashDialog/CashDialog';
import CheckDialog from '../CheckDialog/CheckDialog';
import GiftCardDialog from '../GiftCardDialog/GiftCardDialog';
import SquareWebPaymentForm from '../SquareWebPaymentForm/SquareWebPaymentForm';
//import SquarePaymentForm from '../SquarePaymentForm/SquarePaymentForm';
import ConnectTerminalDialog from '../ConnectTerminalDialog/ConnectTerminalDialog';
import OkNavigationButtons from '../OkNavigationButtons/OkNavigationButtons';

import apiPayOrder from '../../api/payOrder';
import apiPollCheckout from '../../api/pollCheckout';
import apiCancelCheckout from '../../api/cancelCheckout';

import { getCustomer, clearCart, setPickupDate } from '../../store/redux';

import classes from './PaymentButtons.module.css';

const KEYED_CARD_FORM = 'KEYED_CARD_FORM';
const CASH_FORM = 'CASH_FORM';
const CHECK_FORM = 'CHECK_FORM';
const GC_FORM = 'GC_FORM';
const SWIPE_FORM = 'SWIPE_FORM';

const modalClass = ( form ) => {
    switch ( form ) {
        case KEYED_CARD_FORM:
            return classes.PaymentModal;
        default:
            return null; 
    }
}

const PaymentButtons = ( { 
    order, setOrder, paymentTotal, tipAmount, squareIds, setSquareIds,
    createOrUpdateOrder,
    children, NavigationButtons 
} ) => {
    const dispatch = useDispatch();
    const customer = useSelector( getCustomer );
    const [ paymentForm, setPaymentForm ] = useState( null );
    const [ swipeStarted, setSwipeStarted ] = useState( false );
    const [ startSwipeCancel, setStartSwipeCancel ] = useState( false );
    const [ swipeOK, setSwipeOK ] = useState( false );

    if ( ! NavigationButtons ) {
        NavigationButtons = OkNavigationButtons;
    }

    const reinitialize = useCallback( () => {
        setOrder( null );
        setPaymentForm( null );
        setSwipeStarted( false );
        setStartSwipeCancel( false );
        setSwipeOK( false );
    }, [ setOrder, setPaymentForm, setSwipeStarted, setStartSwipeCancel, setSwipeOK ] );

    const checkSwipeOrder = useCallback( ( orderId ) => {

        const checkoutResult = ( response ) => {

            const { checkout, order } = response;
            if ( checkout.status === 'CANCELED' ) {
                alert( 'NOTE: The swipe was cancelled - transaction not completed' );
                reinitialize();
                return;
            }
            if ( checkout.status === 'COMPLETED' && order && order.squarePaymentId ) {
                setSwipeOK( true );
                dispatch( clearCart() );
                dispatch( setPickupDate( null ) );
                setOrder( order );
                return; // If no error the "picked up now  or  pick up later  popup should show"
            }
            // Still waiting for swipe to complete
            setTimeout( () => checkSwipeOrder( orderId ), 1000 );
        };

        apiPollCheckout( orderId, checkoutResult, dispatch );

    }, [ setOrder, setSwipeOK, reinitialize, dispatch ] );
    
    useEffect( () => {
        if ( paymentForm === SWIPE_FORM && order && ! swipeStarted ) {
            setSwipeStarted( true );
            apiPayOrder( order._id, paymentTotal, null, 'TERMINAL', null, null,
            ( result ) => {
                setTimeout( () => checkSwipeOrder( order._id ), 1000 );
            },
            dispatch
        );
        }
    }, [ paymentForm, order, paymentTotal, swipeStarted, setSwipeStarted, checkSwipeOrder, dispatch ] );
    
    useEffect( () => {
        if ( startSwipeCancel ) {
            if ( order && order.squareCheckoutId ) {
                apiCancelCheckout( 
                    order._id,
                    ( { checkout, order } ) => {
                        setStartSwipeCancel( false );
                        setOrder( order );
                        if ( checkout.status === 'CANCELED' ) {
                            reinitialize();
                        } else {
                            alert( 'The transaction was not cancelled. Check Square.' );
                        }
                    },
                    dispatch 
                );
            } else {
                reinitialize();
            }
        }
    }, [ 
        order, reinitialize,
        startSwipeCancel, setStartSwipeCancel, setSwipeStarted, setPaymentForm, setOrder, dispatch 
    ] );

    const startSwipe = useCallback( () => {
        setPaymentForm( SWIPE_FORM );
    }, [ setPaymentForm ] );

    const cancelSwipe = useCallback( ( e ) => {
        e.stopPropagation();
        setStartSwipeCancel( true );
    }, [ setStartSwipeCancel ] );

    const payWithKeyedCard = useCallback( () => {
        setPaymentForm( KEYED_CARD_FORM )
    }, [ setPaymentForm ] );

    const payWithCash = useCallback( () => {
        setPaymentForm( CASH_FORM );
    }, [ setPaymentForm ] );

    const payWithCheck = useCallback( () => {
        setPaymentForm( CHECK_FORM );
    }, [ setPaymentForm ] );

    const payWithGC = useCallback( () => {
        setPaymentForm( GC_FORM );
    }, [ setPaymentForm ] );

    const cancelPayment = useCallback( ( e ) => {
        try { e.stopPropagation(); } catch( error ) {}
        reinitialize();
    }, [ reinitialize ] );
    
    return (
    <React.Fragment> {
        <div className={ classes.PaymentButtons } onClick={ createOrUpdateOrder }>
            <Button onClick={ payWithKeyedCard } >Charge Card</Button>
            { customer &&
                <React.Fragment>
                    <Button onClick={ payWithCash } disabled={ tipAmount } className={ classes.Disabled } >
                        { tipAmount ? 'No Cash with tip' : 'Cash' }
                    </Button>
                    <Button onClick={ payWithGC } disabled={ tipAmount } className={ classes.Disabled } >
                        { tipAmount ? 'No GC with tip' : 'Gift Card' }
                    </Button>
                    <Button onClick={ payWithCheck } >Check</Button>
                    <ConnectTerminalDialog 
                        order={ order }
                        squareIds={ squareIds }
                        setSquareIds={ setSquareIds }
                        swipeCard={ startSwipe }
                        tipAmount={ tipAmount }
                        children={ children }
                    />
                </React.Fragment>
            }
        </div> }
        { paymentForm &&
            <Modal onClick={ cancelPayment } noX
                className={ modalClass( paymentForm ) }
            >
                { paymentForm === KEYED_CARD_FORM ?
                  <SquareWebPaymentForm 
                        squareIds={ squareIds } 
                        order={ order }
                        setOrder={ setOrder }
                        paymentTotal={ paymentTotal }
                        tipAmount={ tipAmount }
                        onCancel={ cancelPayment }
                    />
/*
                    <SquarePaymentForm appId={ squareIds.appId } 
                        order={ order } 
                        setOrder={ setOrder }
                        squareOrderTotal={ paymentTotal }
                        tipAmount={ tipAmount }
                        receiptEmail={ '' }
                        NavigationButtons={ NavigationButtons }
                        onCancel={ cancelPayment }
                    />
*/
                : paymentForm === CASH_FORM ?
                    <CashDialog
                        order={ order }
                        setOrder={ setOrder }
                        paymentTotal={ paymentTotal }
                        NavigationButtons={ NavigationButtons }
                        onCancel={ cancelPayment }
                    />

                : paymentForm === CHECK_FORM ?
                    <CheckDialog
                        order={ order }
                        setOrder={ setOrder }
                        paymentTotal={ paymentTotal }
                        tipAmount={ tipAmount }
                        NavigationButtons={ NavigationButtons }
                        onCancel={ cancelPayment }
                    />

                : paymentForm === GC_FORM ?
                    <GiftCardDialog
                        order={ order }
                        setOrder={ setOrder }
                        paymentTotal={ paymentTotal }
                        NavigationButtons={ NavigationButtons }
                        onCancel={ cancelPayment }
                    />

                : paymentForm === SWIPE_FORM ?
                    <div onClick={ e => e.stopPropagation() } >
                        { swipeOK ?
                            <div>
                                Card was charged.
                                <NavigationButtons order={ order } />
                            </div>
                        :
                            <div>
                                Waiting for customer to swipe card . . .
                                { order && <Button onClick={ cancelSwipe } >Cancel Swipe</Button> }
                            </div>
                        }
                    </div>

                : <Button onClick={ cancelPayment } >
                    { paymentForm + ' Not Implemented' }
                </Button>
                }
            </Modal>
        }
    </React.Fragment>
    );
}

export default PaymentButtons;