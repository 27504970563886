import React from 'react';

import { orderItemUtils, priceUtils } from '@fernleaf/util';
import classes from './CartTotals.module.css';

const Total = ( { legend, amount, divider } ) => {
    return (
        <React.Fragment>
            { divider && 
                <Total amount='_______' />
            }
            <div className={ classes.TotalLine } >
                <div className={ classes.TotalLegend } >{ legend || '' }</div>
                <div className={ classes.TotalAmount } >{ amount || '-' }</div>
            </div>
        </React.Fragment>
    )
};

const CartTotals = ( { cart, tipAmount, taxCert, divider, setNoAmount } ) => {
    const itemsTotal = priceUtils.orderItemsTotal( cart );
    const taxTotal = taxCert ? 0 : priceUtils.orderTaxTotal( cart );
    const tip = tipAmount || 0;

    if ( setNoAmount && itemsTotal === null ) {
        setNoAmount( true );
    }

    return (
        <React.Fragment>
            <Total legend='Item Total' 
                amount={ orderItemUtils.formatPrice( itemsTotal ) } divider={ divider } 
            />
            <Total legend='Tax' amount={ orderItemUtils.formatPrice( taxTotal ) } />
            { tipAmount &&
                <Total legend='Tip' amount={ orderItemUtils.formatPrice( tip ) } />
            }
            <Total legend='Order Total' 
                amount={
                    itemsTotal === null ? null : 
                    orderItemUtils.formatPrice( itemsTotal + taxTotal + tip ) 
                } divider 
            />
        </React.Fragment>
    );
}

export default CartTotals;