Object.filter = ( obj, filter, includeOnly ) => {
    const predicate = (
        typeof filter === "function" ? filter : // receives obj, key
        includeOnly ? (obj, key) => filter.includes( key ) :
        ( obj, key ) => ! filter.includes( key )
    );

    return Object.keys(obj)
        .filter( key => predicate( obj, key ) )
        .reduce( ( result, key ) => { result[key] = obj[key]; return result }, {} );
}

Object.excludingKeys = ( object, excludedKeys ) => Object.filter( object, excludedKeys, false );
Object.includingKeys = ( object, includedKeys ) => Object.filter( object, includedKeys, true );

Object.isEmpty = ( value ) => {
    if ( value instanceof Date ) {
        return false;
    }
    return value === null
        || typeof value === 'undefined'
        || ( Array.isArray( value ) && value.length === 0 )
        || ( typeof value === 'object' && Object.keys( value ).length === 0 )
        || ( typeof value === 'string' && value.trim() === '' );
}

Object.areEqual = ( a, b ) => {
    if ( a instanceof Date || b instanceof Date ) {
        return a instanceof Date && b instanceof Date
            && a.getTime() === b.getTime(); 
    }
    return a === b
        || ( Object.isEmpty( a ) && Object.isEmpty( b ) )
        || ( typeof a === 'string' && typeof b === 'string' 
            && a.trim() === b.trim() )
        || ( ( typeof a === 'number' || typeof b === 'number' )
            && +b === +a )
        || ( ( typeof a === 'object' || typeof b === 'object' ) &&
            ! Array.isArray( a ) && ! Array.isArray( b ) &&
            Object.areEqualObjects( a, b ) );
}

Object.areEqualObjects = ( a, b ) => {
    if ( a === b ) {
        return true;
    }
    if ( a instanceof Date || b instanceof Date ) {
        return Object.areEqual( a, b ); // Will short-circuit before infinite recursion.
    }

    a = a || {};
    b = b || {};

    const aNames = Object.keys( a || {} ).reduce( ( names, name ) => names.add( name ), new Set() );
    const bNames = Object.keys( b || {} );

    for ( name of bNames ) {
        // NOTE: { a: null } is considered = to {}
        if ( ! Object.areEqual( a[ name ], b[ name ] ) ) {
            return false;
        }
        aNames.delete( name );
    }

    for ( name of aNames ) {
        // Object a may have null values for properties absent from b - still equal then
        if ( ! Object.areEqual( a[ name ], b[ name ] ) ) {
            return false;
        }
    }
    return true;
}

const deepCopy = ( o ) => {
    if ( Array.isArray( o ) ) {
        return o.map( entry => deepCopy( entry ) );
    }
    if ( typeof o === 'object' && o !== null ) {
        if ( o instanceof Date ) {
            return new Date( o.getTime() );
        }
        return Object.entries( o ).reduce( ( copy, entry ) => {
            const [ key, value ] = entry;
            copy[ key ] = deepCopy( value );
            return copy;
        }, {} );
    }
    return o;
}

Object.deepCopy = deepCopy;

Object.slice = ( obj, keys ) => (
    Array.isArray( keys ) ? 
        keys.reduce( ( target, key ) => {
            if ( obj[ key ] ) {
                target[ key ] = obj[ key ];
            }
            return target;
        }, {} ) 
    : {}
);
