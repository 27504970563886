import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { calendarUtils } from '@fernleaf/util';
import { 
    getCustomer, getMenus, showProducts, getCalendar, getAllMenusURL, getSearch,
    getItemOrderControls,
    getShowMainPage,
    setShowMainPage
} from '../store/redux';
import { anyAvailable } from '../stockControl/stockControl';

import classes from './Main.module.css';

const isPictureSection = ( section ) => [ 'Picture', 'Link' ].includes( section.type );

const Main = ( { location, history } ) => {
    const dispatch = useDispatch();
    const calendar = useSelector( getCalendar );
    const menus = useSelector( getMenus );
    const allMenusURL = useSelector( getAllMenusURL );
    const search = useSelector( getSearch );
    const itemOrderControls = useSelector( getItemOrderControls );
    const customer = useSelector( getCustomer ); // If staff is ordering for a customer then the user
                                                 // is the admin user for the staff person and the
                                                 // customer is an additional user stored as 'customer'.
    const showMainPage = useSelector( getShowMainPage );
    const [ selectedMenu, setSelectedMenu ] = useState( null );

    useEffect( () => {
        if ( location.pathname !== '/shop' && location.pathname !== '/' ) {
            history.push( '/' );
        }
    }, [ location, history ] );

    useEffect( () => {
        if ( search || ( customer && ! showMainPage ) ) {
            history.push( '/products' );
        }
    }, [ showMainPage, search, customer, history, dispatch ] );

    const showSection = useCallback(
        ( section ) => ( e ) => {
            e.stopPropagation();
            dispatch( setShowMainPage( false ) ); // Make sure main page request is cancelled
            dispatch( showProducts( section._id ) );
            history.push( '/products' );
        }
    , [ history, dispatch ] );
    
    const menuLink = useCallback( 
        ( section ) => ( e ) => {
            e.stopPropagation();
            setSelectedMenu( section.menuId )
        } 
    , [ setSelectedMenu ] );

    let sections = [];
    for ( const menu of menus || [] ) {
        if ( menu.linkURL ) {
            sections.push( { 
                type: 'Link',
                url: menu.linkURL,
                menuId: menu.id,
                _id: sections.length
            } );
        }
    }
    for ( const menu of menus || [] ) {
        sections = sections.concat( menu.sections.filter(
            section => {
                if ( ! customer && menu.type === 'Admin' ) {
                    return false;
                }
                if ( selectedMenu && section.menuId !== selectedMenu ) {
                    return false;
                }
                if ( customer && customer.storeOrder ) {
                    if ( menu.minLeadTime.units === 'Minutes' || section.note === 'available today' ) {
                        return false;
                    }
                }
                if ( section.note !== 'available today' ) {
                    return true;
                }
                return calendar && calendarUtils.canOrderForToday( calendar );
            }
        ) );
    }
    if ( selectedMenu ) {
        sections.push( {
            type: 'Link',
            url: allMenusURL,
            _id: sections.length
        })
    }

    let itemSection = 1;

    return ( sections.length === 0 ? null :
        <section className={ classes.Main }>
            { sections.map( section => section.url && ( 
                isPictureSection( section ) || 
                ( customer && section.items.length > 0 ) ||
                ( ! customer && itemOrderControls && section.items.filter( 
                    variation => anyAvailable( variation, itemOrderControls ) ).length > 0 ) 
            ) &&
                <div key={ section._id } 
                    onClick={ isPictureSection( section ) 
                        ? menuLink( section )
                        : showSection( section ) 
                    } 
                    className={ `${ classes.Section } ${ isPictureSection( section ) ? '' :
                        itemSection++ % 2 === 0 ? classes.Even : classes.Odd }` }
                >
                    <div className={ isPictureSection( section ) ? 
                            classes.PictureSectionDiv : classes.SectionPictureDiv
                    } >
                        <img src={ section.url } alt={ section.url }
                            className={ isPictureSection( section ) ? 
                                classes.PictureSection : classes.SectionPicture
                            }
                        />
                        { section.type === 'Items' &&
                            <div className={ classes.SectionHeader }>{ section.header }</div>
                        }
                    </div>
                </div>
            ) }
        </section>
    );
};

export default withRouter( Main );