import React, { useState } from 'react';
import canClick from '../utils/canClick';
import canUpdate from '../utils/canUpdate';

import classes from './DatePicker.module.css';

export const normalDate = ( date, showDayName ) => {
    if ( ! date ) {
        return null;
    }
    const start = showDayName ? 0 : 4;
    const stop = showDayName ? 10 : 15;
    const formatted = new Date( Date.parse( date ) ).toString();
    return formatted === 'Invalid Date' ? date : formatted.slice( start, stop );
};

let fieldIds = 1;

const DatePicker = ( { 
    thing, className, field, update, placeholder, showDayName,
    minDate, maxDate, excludeDates, firstDayOfWeek
} ) => {

    update = update && canUpdate( thing );

    const [ fieldId ] = useState( `datepicker-${ field }-${ fieldIds++ }` );
    const value = thing.isHeader 
        ? thing[ field ] 
        : normalDate( thing[ field ], showDayName );
    const classNames = [
        update && classes.CanUpdate,
        className,
        update && thing.isUpdated( field ) && classes.Pending
    ].join( ' ' );

    const getPicker = () => {
        const options = {
            clickOpens: false,
            allowInput: false,
            disableMobile: true,
            locale: { firstDayOfWeek: firstDayOfWeek || 0 },
            minDate: minDate || new Date(),
            defaultDate: thing[ field ] ? new Date( thing[ field ] ) : null,
            formatDate: ( d ) => new Date( Date.parse( d ) ).toDateString( 'en-US' ),
            parseDate: ( d ) => new Date( Date.parse( d ) ),
            onChange: ( d ) => {
                thing[ field ] = new Date( Date.parse( d ) ).toISOString();
                document.getElementsByClassName( 'flatpickr-calendar' )[ 0 ].remove();
            }
        };
        if ( maxDate ) {
            options.maxDate = maxDate;
        }
        if ( excludeDates ) {
            options.disable = Array.isArray( excludeDates ) ? excludeDates : [ excludeDates ];
        }

        const picker = window.flatpickr( `#${ fieldId }`, options );
        return picker;
    }

    return (
        <div className={ [ classes.Updatable, classNames ].join( ' ' ) } >
            <div className={ [ 
                    classes.Display, 
                    ! value && placeholder && classes.Placeholder 
                ].join( ' ' ) } 
                onClick={ canClick( update, () => getPicker().open() ) }
            >
                { value || placeholder || '' }
            </div>
            { update ? 
                <React.Fragment>
                    <input id={ fieldId }
                        className={ classes.Input }
                        type='text' value={ value || '' }
                        placeholder={ placeholder }
                        onChange={ (e) => thing[ field ] = e.target.value }
                    /> 
                    { value && value.trim() !== '' ?
                        <div className={ classes.X } 
                            onClick={ canClick( update, () => thing[ field ] = null ) }
                        >
                            &times;
                        </div> 
                    : null }
                </React.Fragment>
            : null }
        </div>
    );
}

export default DatePicker;