import { getWithRetry } from './fetch';

const apiGetItemOrderControls = async ( setItemOrderControls, dispatch ) => {
    await getWithRetry( 'apiGetItemOrderControls', 
        '/api/stockcontrols/items/', setItemOrderControls,
        dispatch, 30, 2000
    );
}

export default apiGetItemOrderControls;
