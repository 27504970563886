// Get selected (or all) properties from the action
export const unpack = ( action, names ) => {
    if ( ! names ) {
        names = Object.keys( action )
            .filter( key => key !== `type`);
    }
    if ( ! Array.isArray( names ) ) {
        names = [ names ];
    }
    return names.reduce( ( result, key ) => 
        { result[ key ] = action[ key ]; return result }, {} );
}

export const parseQuery = ( location ) => {
    const query = location.search;
    const queryParms = {};
    if ( location.search && location.search.charAt( 0 ) === "?" ) {
        const parms = location.search.substr( 1 ).split( "&" );
        for ( const parm of parms ) {
            const [ name, value ] = parm.split( "=", 2 );
            queryParms[ name ] = decodeURIComponent( value );
        }
    }
    return queryParms;
}

export const isEmpty = ( value ) => 
    value === null                  ||
    typeof value === 'undefined'    ||
    ( typeof value === 'string' && value.trim() === '' );
