import React from 'react';

import classes from './DownIcon.module.css';

const DownIcon = ( { onClick, className } ) => (
    <div onClick={ onClick } 
         className={ [ classes.Down, className ].join( ' ' ) }
    >
        <svg width="24px" height="24px" viewBox="0 0 24 24">
            <g data-name="Layer 2">
                <g data-name="chevron-down">
                    <rect width="24" height="24" opacity="0" strokeWidth="2" stroke="black" fill="white" />
                    <path d="M12 15.5a1 1 0 0 1-.71-.29l-4-4a1 1 0 1 1 1.42-1.42L12 13.1l3.3-3.18a1 1 0 1 1 1.38 1.44l-4 3.86a1 1 0 0 1-.68.28z"/>
                </g>
            </g>
        </svg>
    </div>
);

export default DownIcon;