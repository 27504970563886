import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Thumbnail } from '@fernleaf/util';

import { 
    addToCart, removeFromCart
} from '../../store/redux';

import { ADD, REMOVE, CHANGE } from './orderActions';

import OrderItem from './OrderItem/OrderItem';
import CakeDetails from './CakeDetails/CakeDetails';
import ModifierDetails from './ModifierDetails/ModifierDetails';
import classes from './ProductOrder.module.css';

const ProductOrder = ( { product, onCancel } ) => {
    const dispatch = useDispatch();
    const [ orderAction, setOrderAction ] = useState( null );
    const [ selectedVariation, setSelectedVariation ] = useState();
    
    const addOrderItem = variation => {
        if ( ( ! variation.isHoliday && variation.squareCategoryName === 'Cake' ) ||
            ( variation.squareModLists && variation.squareModLists.length > 0 )
        ) {
            setSelectedVariation( variation );
            setOrderAction( ADD );
        } else {
            dispatch( addToCart( variation ) );
        }
    }

    const removeOrderItem = ( variation, details ) => {
        if ( details && details.length > 1 ) {
            setSelectedVariation( variation );
            setOrderAction( REMOVE );
        } else {
            dispatch( removeFromCart( variation ) );
        }
    }

    const changeOrderItem = variation => {
        setSelectedVariation( variation );
        setOrderAction( CHANGE );
    }

    return (
        <Modal rounded noX onClick={ onCancel } className={ classes.Modal } >
            <div onClick={ (e) => e.stopPropagation() } >
                <div className={ classes.Picture } >
                    <Thumbnail pictures={ product.pictures } onClick={ () => {} } 
                        className={ classes.Thumbnail }
                    />
                    <div onClick={ onCancel } className={ classes.X } >continue...</div>
                </div>
                <div className={ classes.ProductName }>{ product.squareItemName }</div>
                { product.squareDescription && product.squareDescription.trim().length > 0 &&
                    <div className={ classes.Description } >{ product.squareDescription }</div>
                }
                { product.pricePoints.map( pricePoint => 
                    pricePoint.variations.map( variation => 
                        <OrderItem key={ variation.squareVariationId } variation={ variation } 
                            addOrderItem={ addOrderItem } removeOrderItem={ removeOrderItem }
                            changeOrderItem={ changeOrderItem }
                        />
                    )
                ) }
            </div>

            { orderAction && 
                ( selectedVariation.squareCategoryName === 'Cake' ?
                    <CakeDetails orderItem={ selectedVariation } orderAction={ orderAction }
                        onClose={ () => setOrderAction( null ) }
                    />
                :
                    <ModifierDetails orderItem={ selectedVariation } orderAction={ orderAction }
                            onClose={ () => setOrderAction( null ) }
                    />
                )
            }
        </Modal>
    );
}

export default ProductOrder;