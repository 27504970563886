import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Backdrop from '../Backdrop/Backdrop';
import SystemError from '../SystemError/SystemError';

import { initEmailReset, emailBadPassword, emailNotFound, emailOK, showEmailMessage,
    emailResetExpired, resetEmailSent, login, logout, initialize } from '../store/actions';
import { getInitialized, getEmailStatus, getError } from '../store/selectors';
import { parseQuery } from '../util';
import { startMonitor } from '../activityMonitor';

import MESSAGES from '../messages';

let doDispatch = () => {};
const logoutOnInactivity = async () => {
    console.info('logoutOnInactivity was triggered');
    doDispatch( logout() );
    await fetch( '/auth/logout' );
}

const LoginRouter = ( { app, children, location } ) => {
    const dispatch = useDispatch();
    const isInitialized = useSelector( getInitialized );
    const noEmailMsg = Object.isEmpty( useSelector( getEmailStatus ) );
    const error = useSelector( getError );

    useEffect( () => {
        const _checkLoginStatus = async () => {
            try {
                const response = await fetch( '/auth/currentuser' );
                const user = await response.json();
                if ( user && user.id ) {
                    doDispatch = dispatch;
                    //startMonitor( logoutOnInactivity, 3600 * 4 );
                }
                dispatch( login( user ) );
            } catch( error ) {
                console.warn( 'System error Init._checkUser: ', error );
            }
        };

        if ( ! isInitialized ) {
            dispatch( initialize() );
            _checkLoginStatus();
        }
    }, [ dispatch ] );

    return ( 
        <BrowserRouter basename={ `/${ app }` } >
            <Switch>
                <Route path='/loginerror' render={ ( { location, history } ) => {

                    const parms = parseQuery( location );

                    switch( parms.type ) {
                        case 'password-invalid':
                            dispatch( emailBadPassword( parms.password ) );
                            break;

                        case 'reset-sent':
                            dispatch( resetEmailSent() );
                            break;

                        case 'reset-expired':
                            dispatch( emailResetExpired() );
                            break;

                        case 'not-found':
                            dispatch( emailNotFound() );
                            break;

                        case 'auth-requested':
                            dispatch( showEmailMessage( MESSAGES.AUTH_REQUESTED ) );
                            break;

                        default:
                            dispatch( showEmailMessage( MESSAGES.LOGIN_FAILED ) );
                            break;
                    }
                    history.replace( localStorage.loginReturnPath || '/' );
                }} />

                <Route path='/emailreset' render={ ( { location, history } ) => {
                    dispatch( initEmailReset( parseQuery( location ) ) );
                    history.replace( localStorage.loginReturnPath || '/' );
                }} />

                { isInitialized && children }

                { isInitialized ? <Redirect to='/' /> : <Route component={ Backdrop } /> }
            </Switch>
            { error ? <SystemError error={  error } /> : null }
        </BrowserRouter>
    );
};

export default LoginRouter;
