import { post } from './fetch';

const apiSendReceipt = async ( orderId, email, onReceiptSent, dispatch ) => {
    await post( 'apiSendReceipt', 
        '/api/order/send-receipt', 
        { orderId, email },
        onReceiptSent, dispatch
    );
}

export default apiSendReceipt;
