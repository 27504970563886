// This file defines the default values for all user output messages.
// They can be overridden in the admin app (overrides are in the messages collection in the database)

const substituteValues = ( ID, values ) => {
    let message = MESSAGES[ ID ] || ID;

    if ( typeof values === 'object' ) {
        for ( const key of Object.keys( values ) ) {
            const pattern = new RegExp( '\\${\\s*' + key + '\\s*}', 'g' );
            message = message.replace( pattern, values[ key ] );
        }
    }

    return message;
}

const MESSAGES = {
    ADMIN_MUST_LOGIN:   'You must log in to use the Admin app.',
    MUST_BE_ADMIN:      'You must be an admin user to use the Admin app.' +
                        '\n\nTo request admin access press the REQUEST ADMIN ACCESS button',
    AUTH_REQUESTED:     'Your request has been sent to the admin person.' +
                        '\n\nOnce it has been granted come back and log in again.',

    BAD_PASSWORD:       'Invalid email/password combination.',
    EMAIL_NOT_FOUND:    'Unknown email.',
    RESET_EXPIRED:      'Reset request has expired. Enter RESET again to reset.',
    EMAIL_SENT:         'You\'ll soon get an email (check your junk folder as well). Click the link and then enter a new password when requested.',
    ENTER_NEW_PASSWORD: 'Enter you new password.',
    ENTER_PASSWORD:     'Password (enter RESET to reset)',
    LOGIN_FAILED:       'Something seems to have gone wrong. Please try again or contact the bakery.',

    CHOOSE_FILE_PROMPT: 'Choose picture from your device',
    CHOOSE_IMAGE_FILE:  'NOTE: Please select a file with a ".png", ".svg", ".jpg", or ".jpeg" extension.',
    NO_FILE_SELECTED:   'No file currently selected.',
    FILE_SIZE_BAD:      'WARNING. This picture is ${ sizer } than ideal. Try to use files between 40K and 300K in size.',
    NEW_FILE_NAME_HINT: 'Enter new name to rename',
    BAD_FILE_NAME:      'Please use only alphamerics, "-", "_", and spaces for your picture name.',
    RENAME_FILE:        'Please rename your picture using only alphamerics, "-", "_", and spaces.',
    FILE_EXISTS:        'The name "${ name }" already exists in the archive - please use a different name.',
    ADD_FILE_BUTTON:    'Add Picture',

    ADD_FOLDER_TITLE:   'Add a new folder:',
    ADD_FOLDER_HINT:    'Enter new folder name',
    ADD_FOLDER_BUTTON:  'Add New Folder',
    BAD_FOLDER_NAME:    'Please use only alphamerics, "-", "_", "&", and spaces for your folder name.',

    sub: substituteValues,
    test_message:       'The ${ quick } brown ${fox} jumped etc...'
}

export default MESSAGES;