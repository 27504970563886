import React, { useState, useEffect } from 'react';
import MESSAGES from '../messages';

import classes from './LoginDialog.module.css';
import buttonClasses from '../Button/Button.module.css';
import FormFactory from '../FormFactory/FormFactory';

const { Form, Input } = FormFactory();

const EmailForm = ( { emailStatus, inputClasses, returnTo } ) => {
    const [ firstPass, setFirstPass ] = useState( true );
    const [ email, setEmail ] = useState( emailStatus.email || localStorage.email || '' );
    const [ password, setPassword ] = useState( emailStatus.badPassword || '' );

    useEffect( () => setFirstPass( false ), [ setFirstPass ] );

    localStorage.setItem( 'email', email ); // In case new value from reset email.
    
    const emailChanged = (e) => {
        localStorage.setItem( 'email', e.target.value );
        setEmail( localStorage.email );
    }
    
    const setFocus = ( ref ) => ref && ref.focus();

    const inputFieldClasses = classes.LoginInputField + ' ' + inputClasses;
    const pwPlaceholder = emailStatus.email 
        ? MESSAGES.ENTER_NEW_PASSWORD 
        : MESSAGES.ENTER_PASSWORD;

    return (
        <React.Fragment>
            <Form action={ `/auth/email/${ returnTo }` } method='post'
                className={ classes.EmailForm }
                defaultInputClass={ classes.LoginInputField }
                defaultErrorClass={ classes.InputError } >

                <Input type='submit' 
                    className={ buttonClasses.Button + ' ' + classes.LoginButton }
                    value='Login with Email' />

                <Input name='email' type='email' required value={ email }
                    readOnly={ emailStatus.email }
                    onChange={ emailChanged } placeholder='Your email'
                    getRef={ email === '' ? setFocus : null }
                    />

                <Input name='password' type='password' required value={ password }
                    onChange={ (e) => setPassword( e.target.value ) } 
                    placeholder={ pwPlaceholder } 
                    getRef={ firstPass && email !== '' ? setFocus : null }
                    />
                <Input name='resetid' type='text' value={ emailStatus.resetId } hidden />
            </Form>
        </React.Fragment>
    );
}

export default EmailForm;