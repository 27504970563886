import React, { useState } from 'react';

import MESSAGES from '../messages';
import Button from '../Button/Button';

import classes from './FileUploader.module.css';

const FileUploader = ( { setFormValid, onSubmit, existingFiles, filePrompt, divClass, promptClass } ) => {
    const [ id ] = useState( `file-uploader-${ Date.now() }` );
    const [ fileName, setFileName ] = useState( MESSAGES.NO_FILE_SELECTED );
    const [ fileSize, setFileSize ] = useState( null );
    const [ message, setMessage ] = useState( null );
    const [ preview, setPreview ] = useState( null );
    const [ newName, setNewName ] = useState( '' );
    const [ suffix, setSuffix ] = useState( '' );
    const [ isValid, setValid ] = useState( false );

    const formatSize = ( size ) => (
          size > 1024 * 1024 ? ( size / ( 1024 * 1024 ) ).toFixed( 1 ) + 'M' 
        : size > 1024        ? ( size / 1024 ).toFixed( 0 ) + 'K'
        : size + ' bytes'
    );

    const checkFile = ( fileName, fileSize, suffix, newName ) => {
        const goodName = /^[\-_a-z0-9 ]*$/i;

        let message;
        let valid = true;
        let uploadFile = fileName;

        if ( suffix.length === 0 ) {
            message = MESSAGES.CHOOSE_IMAGE_FILE;
            valid = false;
        }

        if ( valid ) {
            if ( newName.trim() !== '' ) {
                if ( ! newName.trim().match( goodName ) ) {
                    message = MESSAGES.BAD_FILE_NAME;
                    valid = false;
                }
                uploadFile = newName.trim() + suffix;
            } else {
                if ( ! fileName.substr( 0, fileName.length - suffix.length ).match( goodName ) ) {
                    message = MESSAGES.RENAME_FILE;
                    valid = false;
                }
            }
        }
        if ( valid && existingFiles.includes( uploadFile ) ) {
            message = MESSAGES.sub( MESSAGES.FILE_EXISTS, { name: uploadFile } );
            valid = false;
        }

        if ( valid && ( fileSize < 40000 || fileSize > 300000 ) ) {
            message = MESSAGES.sub( MESSAGES.FILE_SIZE_BAD, 
                { sizer: fileSize < 50000 ? 'smaller' : 'bigger' } );
        }

        setMessage( message );
        setValid( valid );
        setFormValid( valid );
    }

    const fileSelected = (e) => {
        const file = e.target.files.length === 1 ? e.target.files[ 0 ] : null;
        if ( file ) {
            setFileName( file.name )
            setFileSize( file.size );
            setPreview( URL.createObjectURL( file ) );
            const match = file.name.match(/\.(png|svg|jp[e]?g)$/i);
            const suffix = match && match[ 0 ] || '';
            setSuffix( suffix );
            checkFile( file.name, file.size, suffix, '' );
        } else {
            setFileName( MESSAGES.NO_FILE_SELECTED )
            setFileSize( null );
            setPreview( null );
            setSuffix( null )
        }
        setNewName( '' );
    }

    const newNameChanged = (e) => {
        setNewName( e.target.value );
        checkFile( fileName, fileSize, suffix, e.target.value );
    }

    return (
        <div className={ [ classes.Uploader, divClass ].join( ' ' ) } >
            <input type='file' id={ id } name='uploadFile' accept='.jpg, .jpeg, .png, .svg' 
                className={ classes.Hide } onChange={ fileSelected }
            />
            <label htmlFor={ id } className={ [ classes.Prompt, promptClass ].join( ' ' ) } >
                { filePrompt || MESSAGES.CHOOSE_FILE_PROMPT }
            </label>
            <div className={ classes.NameBlock } >
                <div className={ classes.FileName } >{ fileName }</div>
                <div className={ classes.FileSize } >
                    { fileSize && `Size: ${ formatSize( fileSize ) }` }</div>
            </div>
            { preview && <img src={ preview } alt={ fileName } className={ classes.Preview } /> }
            { message && <div className={ classes.Message } >{ message }</div> }
            { fileSize && 
                <div className={ classes.UploadAction }>
                    <div className={ classes.FakeInput }>
                        <input type='text' name='newName' value={ newName }
                            className={ [ classes.NewName, 
                                          newName.trim() === '' ? classes.Empty : '' ].join( ' ' ) }
                            placeholder={ MESSAGES.NEW_FILE_NAME_HINT } onChange={ newNameChanged } />
                        <div className={ classes.Suffix } >{ newName.trim() !== '' ? suffix : '' }</div>
                    </div>
                    { isValid && <Button className={ classes.Add } onClick={ onSubmit } >
                        { MESSAGES.ADD_FILE_BUTTON }
                    </Button> }
                </div> 
            }
        </div>
    )
};

export default React.memo( FileUploader );