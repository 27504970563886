import { get } from './fetch';

const apiGetStoredBalance = async ( orderId, setStoredBalance, dispatch ) => {
    await get( 'apiGetStoredBalance', 
        `/api/order/stored-balance/${ orderId }`, setStoredBalance,
        dispatch
    );
}

export default apiGetStoredBalance;
