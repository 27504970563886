import { actions } from '@fernleaf/util';

const _fetch = async ( caller, path, payload, apply, dispatch, trace ) => {
    if ( trace && payload ) console.log( 'fetch: SAVE', caller, path, payload );
    const options = payload ? {
        method: 'post',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify( payload )
    } : null;

    let response;
    try {
        response = await fetch( path, options );
        if ( response.status !== 200 ) {
            console.log( 'api/fetch: PAYLOAD SIZE', JSON.stringify( payload ).length );
            console.log( 'api/fetch: PAYLOAD', payload );
            console.log( 'api/fetch:', response );
            if ( response.status === 401 ) {
                window.location = '/';
                return;
            }
            throw new Error( `api/fetch: Unexpected HTTP status: ${ response.status }` );
        }
        let result = await response.json();
        if ( result.error === 'No user' ) {
            result = {};
        }
        if ( result.error ) {
            if ( result.error === 'Square data has changed since this update began' ) {
                dispatch( actions.setError( 
                    'Someone else has updated some of this data in Square' +
                    ' while you were working. Note your changes and then' +
                    ' cancel the changes to see current Square values.' ) );
                return;
            }
            throw Error( result.error && `api/fetch: SERVER ERROR: ${ result.error }` );
        }
        if ( trace ) {
            console.log( `api/fetch: ${ caller } ${ path } RETURNED:`, result )
        }
        return ( apply && apply( Object.isEmpty( result ) ? null : result ) );
    } catch( error ) {
        console.log( `[${ caller }]`, error.message, error );
        console.log( 'RESPONSE', response );
        dispatch( actions.setError( `[${ caller }]: ${ error.message }` ) );
    }
}

// NOTE: These functions return promises. It is not expected that the caller will usually need
//       to wait for the functions to complete as they all have call-backs.

export const post = async ( caller, path, payload, apply, dispatch, trace ) => {
    return _fetch( caller, path, payload, apply, dispatch, trace )
}

export const get = async ( caller, path, apply, dispatch, trace ) => {
    return _fetch( caller, path, null, apply, dispatch, trace );
}

// The retry functions DO NOT return promises any result must be retrieved through a callback.

export const getWithRetry = async ( caller, path, apply, dispatch, retries, retryInterval, trace ) => {

    const retry = ( errorAction ) => {
        if ( retries > 0 ) {
            setTimeout( 
                () => getWithRetry( caller, path, apply, retry, --retries, retryInterval, trace ),
                retryInterval
            );
        } else {
            dispatch( errorAction );
        }
    }

    _fetch( caller, path, null, apply, retry, trace );
}
