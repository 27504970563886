import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, MenuIcon, UserIcon, calendarUtils } from "@fernleaf/util";
import { format, isToday, startOfDay } from 'date-fns';

import { 
    getCalendar,
    getCart, getCartCount, clearCart, removeFromCart,
    getPickupDate, setPickupDate,
    getCustomer, getItemOrderControls, getOrderToPay,
    getSearch, setSearch
} from '../../../store/redux';
import PickupDate, { 
    allItemsAvailableToday,
    getDateRange
} from '../../../PickupDate/PickupDate';

import { itemsAvailableToday, itemsAvailable } from '../../../stockControl/stockControl';

import classes from './Toolbar.module.css';

const VALID_SEARCH = /^[a-z0-9 ]*$/i;

const formatDate = date => format( date, 'MMM d');
const formatTime = date => (
    date.getHours() === 0 && date.getMinutes() === 0 ? 'Soonest' :
    format( date, 'h:mm aaaa' ).replace( /\./g, '' )
);
const formatPickup = date => {
    try {
        return format( 
            date, `${ isToday( date ) ? '' : 'MMM d, ' }h:mm aaaa`).replace( /\./g, '' 
        );
    } catch( error ) {
        return `(INVALID DATE - ${ date })`;
    }
}

const Toolbar = ( { user, toggleSession, toggleMenu, showSearch } ) => {
    const dispatch = useDispatch();
    const calendar = useSelector( getCalendar );
    const customer = useSelector( getCustomer );
    const cart = useSelector( getCart );
    const cartCount = useSelector( getCartCount );
    const search = useSelector( getSearch );
    const pickupDate = useSelector( getPickupDate );
    const orderingForCustomer = useSelector( getCustomer );
    const orderToPay = useSelector( getOrderToPay );
    const itemOrderControls = useSelector( getItemOrderControls );
    const [ firstAvailableDate, setFirstAvailableDate ] = useState( 0 );
    const [ showPickupDate, setShowPickupDate ] = useState( false );
    const [ adminWarned, setAdminWarned ] = useState( false );

    const today = startOfDay( new Date() );

    useEffect( () => {
        if ( calendar && cart && itemOrderControls && firstAvailableDate === 0 ) {
            if ( cart.length === 0 ) {
                // if ( ! customer ) {
                //     dispatch( setPickupDate( null ) );
                // }
                setFirstAvailableDate( null );
                return;
            } 
            
            if ( calendarUtils.canOrderForToday( calendar ) && 
                allItemsAvailableToday( null, cart, itemOrderControls )
            ) {
                setFirstAvailableDate( today );
                return;
            }
            
            const [ minDate, ] = getDateRange( null, cart, calendar, !! customer );
            setFirstAvailableDate( minDate );
        }
    }, [ customer, cart, firstAvailableDate, today, calendar, itemOrderControls, dispatch ] );

    // useEffect( () => {
    //     if ( customer && customer.storeOrder && ! pickupDate && calendar ) {
    //         alert('no store date')
    //         dispatch( setPickupDate( calendarUtils.hourBeforeNextOpening( calendar ) ) );
    //     }
    // }, [ customer, pickupDate, setPickupDate, calendar ] );

    if ( ! ( calendar && cart && itemOrderControls ) ) {
        return null;
    }

    const pickupToday = pickupDate && isToday( pickupDate );
    const isNotAvailable = ( orderItem ) => (
        ( pickupToday && orderItem.variation.leadTime.units === 'Days' ) ?
            orderItem.count > itemsAvailableToday(
                orderItem.variation.squareVariationId,
                itemOrderControls
            )
        :
            orderItem.count > itemsAvailable(
                orderItem.variation.squareVariationId, cart,
                itemOrderControls
            )
    );
    
    const unavailableItems = cart.filter( orderItem => isNotAvailable( orderItem ) );
    const expiredItems = pickupToday ? unavailableItems : 
        cart.filter( orderItem => {
            const [ minDate, ] = getDateRange( orderItem.variation, [], calendar, !! customer );
            return pickupDate && pickupDate < minDate;
        } );

    const processSearchEntry = (e) => {
        e.stopPropagation();
        while( e.target.value && e.target.value.substring( 0, 1 ) === ' ' ) {
            e.target.value = e.target.value.substring( 1 );
        }
        if ( VALID_SEARCH.test( e.target.value ) ) {
            dispatch( setSearch( e.target.value ) );
        } else {
            e.target.value = search;
        }
    };

    const clearSearch = (e) => {
        e.stopPropagation();
        e.target.previousSibling.value = '';
        dispatch( setSearch( null ) );
    }
    
    const changePickupDate = (e) => {
        e.stopPropagation();
        if ( ! orderToPay ) {
            setShowPickupDate( true );
        }
    };

    const hideDate = (e) => {
        e.stopPropagation();
        setShowPickupDate( false );
    };

    const removeOutOfStockItems = (e) => {
        e.stopPropagation();
        for ( const orderItem of unavailableItems ) {
            dispatch( removeFromCart( orderItem.variation, [], true ) );
        }
    };

    const clearOrderCart = (e) => {
        e.stopPropagation();
        dispatch( clearCart() );
        dispatch( setPickupDate( null ) );
    };

    const justify = name => {
        while ( name.length < 7 ) {
            name += '\u00A0';
        }
        return name;
    };

    return (
        <header className={ classes.Toolbar }>
            <div className={ classes.MainBar } >
                <MenuIcon onClick={ toggleMenu } className={ classes.MenuIcon } />
                <Link to='/' >
                    <div className={ orderingForCustomer ? classes.CustomerTitle : classes.Title } >
                        { orderingForCustomer ?
                            orderToPay ?
                                `Paying for order: ${ orderingForCustomer.name }` :
                            orderingForCustomer.storeOrder ?
                                'Store Order' :
                            orderingForCustomer.inPerson ? 
                                'Walk In Customer' : 
                                `Ordering for: ${ orderingForCustomer.name }` 
                        : 'fernleaf shop' }
                    </div>
                </Link>
                { showSearch && 
                    <div className={ classes.SearchBlock } >
                        <input id='searchInput' type='text'
                            defaultValue={ search } 
                            placeholder='Search...' className={ classes.SearchInput }
                            onInput={ processSearchEntry }
                        />
                        <div className={ search ? classes.ClearSearch : classes.NoSearch } 
                            onClick={ clearSearch }
                            disabled={ ! search }
                        >✕</div>
                    </div>
                }
                <div id='fernPickupDateTime' onClick={ changePickupDate } 
                    className={ [
                        classes.PickupDateBlock,
                        ! pickupDate && classes.NoBorder,
                        expiredItems && expiredItems.length > 0 && pickupDate
                            ? classes.BadPickupDate : null 
                    ].join( ' ' ) } >
                    { pickupDate &&
                        <React.Fragment>
                            <div className={ classes.DateHeader } >Pick-up time:</div>
                            <div className={ classes.PickupDate } >
                                { formatDate( pickupDate ) }
                            </div>
                            <div className={ classes.OrderTime } >
                                { formatTime( pickupDate ) }
                            </div>
                        </React.Fragment>
                    }
                </div>
                <Link to='/cart'>
                    <div className={ [ classes.Widget, classes.Cart ].join( ' ' ) } >
                        <img src='/pictures/other/shopping-cart.svg' alt='cart' className={ classes.CartIcon } />
                        { cartCount > 0 &&
                            <div className={ classes.CartCount } >{ cartCount }</div>
                        }
                    </div>
                </Link>
                <div id='fernLoginToggle' onClick={ toggleSession } className={ classes.Widget } >
                    <UserIcon active={ ! Object.isEmpty( user ) } picture={ user && user.picture } />
                </div>
                <div className={ classes.UserName } >
                    { user && ! user.picture ? 
                        user.name ? justify( user.name.split( ' ' )[ 0 ] ) 
                            : user.email ? user.email.split( '@' )[ 0 ]
                                : ''
                    : '' }
                </div>
            </div>

            { unavailableItems.length > 0 && ( ! orderingForCustomer || ! adminWarned ) &&
                    ! orderToPay &&
                <div onClick={ e => e.stopPropagation() } className={ classes.OutOfStockError } >
                    <div className={ classes.OutOfStockMessage } >
                        { orderingForCustomer && 'WARNING: ' }Items in your cart ({
                            unavailableItems.map( item => 
                                ( ( item.variation.displayName && 
                                    item.variation.displayName.trim().length > 0 
                                  ) ? item.variation.displayName + ' ' : ''
                                ) + item.variation.squareItemName
                            ).join( ', ' )
                        }) are now out of stock in the system. 
                        { ! orderingForCustomer && 'The item(s) must be removed from your cart.' }
                    </div>
                    <div className={ classes.DateButtons } >
                        { orderingForCustomer ?
                            <Button onClick={ () => setAdminWarned( true ) } >OK</Button>
                        :
                            <React.Fragment>
                                <Button onClick={ removeOutOfStockItems } >
                                    Remove Unavailable Items
                                </Button>
                                <Button onClick={ clearOrderCart } >Clear cart</Button>
                            </React.Fragment>
                        }
                    </div>
                </div>
            }
            { ! orderingForCustomer && pickupDate && unavailableItems.length === 0 &&
                expiredItems.length > 0 &&

                <div onClick={ e => e.stopPropagation() } className={ classes.DateError } >
                    <div className={ classes.DateMessage } >
                        Order deadline for some items in your cart ({
                            expiredItems.map( item => 
                                ( ( item.variation.displayName && 
                                    item.variation.displayName.trim().length > 0 
                                  ) ? item.variation.displayName + ' ' : ''
                                ) + item.variation.squareItemName
                            ).join( ', ' )
                        }) is past your pick-up time.
                        Either clear your cart or choose a new pick-up date/time
                        on or after { formatPickup( firstAvailableDate ) }.
                    </div>
                    <div className={ classes.DateButtons } >
                        <Button onClick={ changePickupDate } >Pick new time</Button>
                        <Button onClick={ clearOrderCart } >Clear cart</Button>
                    </div>
                </div>
            }

            { ( showPickupDate || ( ! pickupDate && cart.length > 0 ) ) &&
                <PickupDate onDateEntered={ hideDate } onCancel={ hideDate } />
            }
        </header>
    );
};

export default React.memo( Toolbar );
