import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal } from '@fernleaf/util';

import { 
    getOrderItemCount, getOrderItemDetails, getPickupDate, getCustomer, getCalendar,
    getItemOrderControls, getCart, getExclusiveDates
} from '../../../store/redux';

import PickupDate, { getDateRange, isBlackedOut } from '../../../PickupDate/PickupDate';
import classes from './OrderItem.module.css';
import { format, isToday, subDays, startOfDay, parseISO } from 'date-fns';
import { itemsAvailable, itemsAvailableToday } from '../../../stockControl/stockControl';

const formatPrice = price => (
    typeof price !== 'number' ? price : ( price / 100 ).toFixed( 2 )
);

const OrderItem = ( { 
    variation, addOrderItem, removeOrderItem, changeOrderItem
} ) => {
    const calendar = useSelector( getCalendar );
    const exclusiveDates = useSelector( getExclusiveDates );
    const orderingForCustomer = useSelector( getCustomer );
    const pickupDate = useSelector( getPickupDate );
    const cart = useSelector( getCart );
    const orderItemCount = useSelector( getOrderItemCount( variation.squareVariationId ) );
    const orderItemDetails = useSelector( getOrderItemDetails( variation.squareVariationId ) );
    const itemOrderControls = useSelector( getItemOrderControls );
    const [ showPickupDate, setShowPickupDate ] = useState( false );
    const [ availableDate, setAvailableDate ] = useState( null );
    const [ pleaseCall, setPleaseCall ] = useState( false );

    if ( ! ( calendar && cart && itemOrderControls ) ) {
        return null;
    }

    const availableToOrder = (
        itemsAvailable( variation.squareVariationId, cart, itemOrderControls ) > orderItemCount
    )
    const dailyItem = variation.leadTime.units === 'Minutes' && availableToOrder;
    const availableToday = (
        dailyItem ||
        itemsAvailableToday( variation.squareVariationId, itemOrderControls ) > orderItemCount
    );
    const outOfStock = ! availableToday && ! availableToOrder;
    const pickupToday = pickupDate && isToday( pickupDate );
    const warnOutOfStock = ( 
        ( ! pickupDate && outOfStock ) ||
        ( pickupToday && ! availableToday ) ||
        ( pickupDate && ! pickupToday && ! availableToOrder )
    );

    const nextAvailableDate = () => {
        const [ minDate, ] = getDateRange( variation, cart, calendar, !! orderingForCustomer );
        return minDate;
    }
    const showNextAvailable = () => {
        const nextDate = nextAvailableDate();
        if ( isToday( subDays( nextDate, 1 ) ) ) {
            return 'tomorrow';
        } 
        return (
            'on ' +
            format( nextDate, `EEEE${ subDays( nextDate, 6 ) > new Date() ? ', MMM d' : '' }` )
        );
    }

    const addIfDate = () => {
        if ( pickupDate ) {
            let firstAvailableDate;
            if ( pickupToday && availableToday ) {
                firstAvailableDate = pickupDate;
            } else {
                const [ minDate, ] = getDateRange( variation, [], calendar, !! orderingForCustomer );
                firstAvailableDate = minDate || 'NONE';
            }
            if ( ! orderingForCustomer && 
                ( firstAvailableDate === 'NONE' || firstAvailableDate > pickupDate ) 
            ) {
                setAvailableDate( firstAvailableDate );
            } else {
                addOrderItem( variation );
            }
        } else {
            setShowPickupDate( true );
        }
    }
    const addAfterDate = ( e, pickupDate ) => {
        try { e.stopPropagation() } catch( x ) {}
        setShowPickupDate( false );
        if ( ! orderingForCustomer && isToday( pickupDate ) && ! dailyItem ) {
            setPleaseCall( true );
        } else {
            addOrderItem( variation );
        }
    }
    const cancelDateEntry = (e) => {
        e.stopPropagation();
        setShowPickupDate( false );
    }

    const removeIfAny = () => {
        if ( orderItemCount > 0 ) {
            removeOrderItem( variation, orderItemDetails );
        }
    }

    const availableMsg = orderItemCount ? 'More' : 'Available'
    const outOfStockMessage = (
        orderingForCustomer ? null :
        variation.orderBy && ( parseISO( variation.orderBy ) < startOfDay( new Date() ) ) ?
            'Menu closed for orders' :
        outOfStock ? 
            ( orderItemCount ? 'No more available' : 'Out of stock' ) :
        pickupToday ?
            ( dailyItem      ? null          :
              availableToday ? 'Please call' : 
                               `${ availableMsg } ${ showNextAvailable() }` ) :
        pickupDate && (
            ( variation.availableFrom && pickupDate < parseISO( variation.availableFrom ) ) ||
            ( variation.availableTo && startOfDay( pickupDate ) > parseISO( variation.availableTo ) ) ||
            isBlackedOut( variation, pickupDate, exclusiveDates )
        ) ?
            'Not available that day' :
        pickupDate && ! pickupToday ?
            ( ! availableToOrder ? 'Only available today' : null ) :
        null
    );
    
    return (
        <div key={ variation._id } className={ [ 
            classes.Variation,
            warnOutOfStock && orderingForCustomer && classes.OutOfStockWarning 
        ].join( ' ' ) } >
            { ( variation.displayName || outOfStockMessage ) &&
                <div className={ classes.VariationLine } >
                    <div className={ classes.VariationName } >{ variation.displayName }</div>
                    <div className={ classes.OutOfStock } >{ outOfStockMessage }</div>
                </div>
            }
            <div className={ classes.VariationLine } >
                <div className={ classes.Price } >{ formatPrice( variation.squarePrice ) } </div>
                { outOfStockMessage ?
                    <div className={ classes.Plus } >&nbsp;</div>
                :
                    <Button onClick={ addIfDate } className={ classes.Plus } >+</Button>
                }
                <Button onClick={ removeIfAny } className={ classes.Minus } >-</Button>
                { orderItemDetails && orderItemDetails.length > 0 ?
                    <Button onClick={ () => changeOrderItem( variation ) } 
                        className={ classes.Change } 
                    >
                        Details
                    </Button>
                :
                    <div className={ classes.Change } >&nbsp;</div>
                }
                <div className={ [
                    classes.Qty,
                    warnOutOfStock && classes.OutOfStock 
                ] } > In Cart: { orderItemCount }</div>
            </div>
            { showPickupDate &&
                <PickupDate variation={ variation } onDateEntered={ addAfterDate } 
                    onCancel={ cancelDateEntry }
                />
            }
            { availableDate &&
                <Modal show onClick={ e => { e.stopPropagation(); setAvailableDate( null ); } } 
                    className={ classes.NotAvailable }
                >
                    The { variation.displayName } { variation.squareItemName }
                    { availableDate === 'NONE' 
                        ? ' cannot be ordered today. Please try again tomorrow.'
                        : ` can only be ordered for pickup on or after ${ 
                                format( availableDate, 'EEEE, MMM d' ) }.`
                    }
                    <br />
                    <Button>OK</Button>
                </Modal>
            }
            { pleaseCall &&
                <Modal show onClick={ e => { e.stopPropagation(); setPleaseCall( false ); } } 
                    className={ classes.NotAvailable }
                >
                    The { variation.displayName } { variation.squareItemName }&nbsp;
                    has limited availability today - please call the bakery 
                    at (207) 286-3376 to order this item.
                    <Button>OK</Button>
                </Modal>
            }
        </div>
    );
};

export default OrderItem;