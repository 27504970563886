import React from 'react';

// A React app which uses this library must do following in index.js:
//
//   import React from 'react';
//   import { Library } from '@fernleaf/util/dist';
//   Library( React );
//
// This is required so that the library uses the same React object as
// the calling code.
// 
// The app and the library must be compiled with the same React minor version.

import Theme from './Themes/Theme';

import Backdrop from './Backdrop/Backdrop';
import Button from './Button/Button';
import DatePicker from './UserInput/DatePicker/DatePicker';
import DownIcon from './DownIcon/DownIcon';
import FileUploader from './FileUploader/FileUploader';
import FormFactory from './FormFactory/FormFactory';
import LoginDialog from './LoginDialog/LoginDialog';
import LoginRouter from './LoginRouter/LoginRouter';
import MenuIcon from './MenuIcon/MenuIcon';
import MinusWidget from './UserInput/MinusWidget/MinusWidget';
import Modal from './Modal/Modal';
import NewFolder from './NewFolder/NewFolder';
import PlusWidget from './UserInput/PlusWidget/PlusWidget';
import RadioButtons from './UserInput/RadioButtons/RadioButtons';
import RefreshIcon from './RefreshIcon/RefreshIcon';
import Spinner from './Spinner/Spinner';
import Thumbnail from './Thumbnail/Thumbnail';
import TimePicker from './UserInput/TimePicker/TimePicker';
import UpIcon from './UpIcon/UpIcon';
import UserIcon from './UserIcon/UserIcon';

import fieldProxy from './fieldProxy/fieldProxy'
import * as actions from './store/actions';
import utilReducer from './store/reducer';
import * as selectors from './store/selectors';

import * as monitor from './activityMonitor';
import * as util from './util';
import * as calendarUtils from './calendarUtils';
import * as orderItemUtils from './orderItemUtils';
import * as priceUtils from './priceUtils';
import MESSAGES from './messages';
import './Object';
import './assets/flatpickr.css'

const Library = ( MainReact ) => {
    Object.assign( React, MainReact );
}

const SUB = MESSAGES.SUB;

export {
    Library,

    Theme,

    Backdrop,
    Button,
    DatePicker,
    DownIcon,
    FileUploader,
    FormFactory,
    LoginDialog,
    LoginRouter,
    MenuIcon,
    MinusWidget,
    Modal,
    NewFolder,
    PlusWidget,
    RadioButtons,
    RefreshIcon,
    Spinner,
    Thumbnail,
    TimePicker,
    UpIcon,
    UserIcon,

    fieldProxy,

    actions,
    utilReducer,
    selectors,

    monitor,
    util,
    calendarUtils,
    orderItemUtils,
    priceUtils,
    
    MESSAGES,
    SUB
}
