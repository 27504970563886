import React, { Fragment } from "react";

import Backdrop from "../Backdrop/Backdrop";
import Spinner from "../Spinner/Spinner";
import classes from "./Modal.module.css";

const Modal = ( { 
    className, backdropClassName, contentClassName,
    rounded, hide, noX,
    onClick, showSpinner, 
    children 
} ) => {
    const allClasses = ( addClasses ) => 
        [ className || "",
          ( rounded && classes.Rounded ) || "",
          ...addClasses ].join( " " );
          
    return (
        hide ? null : (
            <Fragment>
                <div className={ allClasses( [ classes.Modal ] ) } onClick={ onClick } >
                    <div className={ allClasses( [ classes.Content, contentClassName ] ) } >
                        { children }
                    </div>
                    <Spinner show={ showSpinner } />
                </div>
                <Backdrop className={ backdropClassName } onClick={ onClick } noX={ noX } />
            </Fragment>
        )
    )
};

export default Modal;