import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addMinutes, subMinutes, parseISO, isToday } from 'date-fns';

import apiGetOrdersToPrep from '../api/getOrdersToPrep';
import apiOrderWasSeen from '../api/orderWasSeen';

import { Button } from '@fernleaf/util';

import classes from './Alerts.module.css';

const alertCache = {
    checkPending: false,
    lastPing: null,
    orders: {}
};

const sortMostUrgent = ( a, b ) => (
    a.pickupDateAndTime < b.pickupDateAndTime ? -1 :
    a.pickupDateAndTime > b.pickupDateAndTime ? +1 :
                                                 0
);

const dummySetFn = () => {};

const dummyDispatch = ( action ) => {
    console.log( "checkForAlerts/markOrderAsSeen - fetch failed:", action );
    // Usually the user navigated away during the fetch operation
}

const markOrderAsSeen = ( e ) => {
    e.stopPropagation();
    apiOrderWasSeen( e.target.closest( 'div' ).dataset.id, dummySetFn, dummyDispatch );
}

const checkOrders = ( orders, setAlert ) => {
    const messages = [];

    const ordersToPack = ( orders || [] )
        .filter( order => order.packStatus === 'To Pack' )
        .length;
    if ( ordersToPack ) {
        messages.push(
            <div key={ 'To Pack' } className={ classes.AlertMessage } >
                <div className={ classes.Overdue } >TO PACK:</div>
                There { ordersToPack === 1 
                    ? ' is 1 order '
                    : ` are ${ ordersToPack } orders ` 
                } 
                waiting to be packed.
            </div>
        )
    }
    const now = new Date();
    const fiveMinutesAgo = subMinutes( now, 5 );
    const plusFiveMinutes = addMinutes( now, 5 );
    const plusFifteenMinutes = addMinutes( now, 15 );
    const waiting = ( orders || [] )
        .filter( order => {
            if ( order.squarePaymentId === 'STORE' ) {
                return false;
            }
            if ( ! order.isPacked ) {
                const pickupTime = parseISO( order.pickupDateAndTime );
                if ( 
                    pickupTime < plusFifteenMinutes || 
                    ( ! order.wasSeen && isToday( pickupTime ) && isToday( parseISO( order.orderDate ) ) ) 
                ) {
                    return true;
                }
            }
            return false;
        } )
        .sort( sortMostUrgent );
    const wasWaiting = { ...alertCache.orders };
    alertCache.orders = {};
    for ( const order of waiting ) {
        const pickupTime = parseISO( order.pickupDateAndTime );
        if ( ! wasWaiting[ order._id ] ) {
            alertCache.lastPing = null;
        }
        alertCache.orders[ order._id ] = true;
        const [ messageClass, messagePrefix ] = (
            pickupTime < now                ? [ classes.Overdue,        'Now' ]             :
            pickupTime < plusFiveMinutes    ? [ classes.FiveMinutes,    'Next 5 minutes' ]  :
            pickupTime < plusFifteenMinutes ? [ classes.FifteenMinutes, 'Next 15 minutes' ] :
                                              [ classes.NewOrder,       'New order' ]
        );
        const [ count, ready, packed ] = order.items
            .map( item => ( 
                { count: item.count, 
                  readyCount: item.readyCount || 0, 
                  packedCount: item.packedCount || 0 } ) )
            .reduce( 
                ( [ count, ready, packed ], counts ) => 
                    [ count + counts.count, ready + counts.readyCount, packed + counts.packedCount ], 
                [ 0 ,0, 0 ] 
            );
        messages.push( 
            <div key={ order._id } className={ classes.AlertMessage } >
                <div className={ messageClass } >{ messagePrefix }:</div>
                Customer: <div className={ classes.PickupName } >{ order.pickupName || order.name }</div>
                Total Items: <div className={ classes.ItemCount } >{ count }</div>
                To Prep: <div className={ classes.ItemCount } >{ count - ready }</div>
                To Pack: <div className={ classes.ItemCount } >{ count - packed }</div>
                { messagePrefix === 'New order' &&
                    <div data-id={ order._id }>
                        <Button onClick={ markOrderAsSeen } className={ classes.NewButton }>OK</Button>
                    </div>
                }
            </div>
        );
    }
    if ( messages.length === 0 ) {
        setAlert( null );
        alertCache.lastPing = null;
    } else {
        if ( ! alertCache.lastPing || alertCache.lastPing < fiveMinutesAgo ) {
            try {
                document.getElementById( 'fernleaf-orders-chime' ).play();
                alertCache.lastPing = now;
            } catch ( e ) {}
        }
        setAlert( messages );
    }
}

const checkForAlerts = ( setAlert, dispatch ) => {
    if ( ! alertCache.checkPending ) {
        alertCache.checkPending = true;
        apiGetOrdersToPrep( 
            ( orders ) => {
                try {
                    checkOrders( orders, setAlert ); 
                    alertCache.checkPending = false;
                } catch (e) {
                    console.error( "checkForAlerts - error:", e );
                    // Probably got logged out.
                }
            },
            dummyDispatch
        );
    }
};

const Alerts = () => {
    const dispatch = useDispatch();
    const [ alert, setAlert ] = useState( null );
    const [ alertToFront, setAlertToFront ] = useState( true );

    useEffect( () => {
        setInterval( () => checkForAlerts( setAlert, dispatch ), 5000 );
    }, [ dispatch ] );

    const toggleAlert = () => {
        setAlertToFront( ! alertToFront );
    }

    return ( alert &&
        <div className={ alertToFront ? classes.AlertsFront : classes.Alerts } >
            <div className={ classes.Header } >
                <div>
                    Orders Due Soon / New
                </div>
                <div>
                    <Button onClick={ toggleAlert } className={ classes.ToggleButton }>
                        { alertToFront ? "HIDE" : "SHOW" }
                    </Button>
                </div>
            </div>
            { alert }
        </div>
    );
}

export default Alerts;