export const getUseDarkTheme = ( state ) => (
    typeof state.util.useDarkTheme === "undefined"             // User hasn't specified in this session
        ? ( localStorage.useDarkTheme === "true" ||            // so use selection from previous session
            typeof localStorage.useDarkTheme === 'undefined' ) // or default to dark
        : state.util.useDarkTheme                              // else use selection from this session
);

export const getUser = ( state ) => state.util.user;
export const getEmailStatus = ( state ) => state.util.emailStatus;
export const getInitialized = ( state ) => state.util.isInitialized;

export const getError = ( state ) => state.util.error;
