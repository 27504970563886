import React from 'react';

const MenuIcon = ( { onClick, className } ) => (
    <div className={ className } style={{ cursor: "pointer" }} onClick={ onClick } >
        <svg viewBox="0 0 100 80" width="40" height="40" >
            <rect x="32" y="15" width="40" height="5"
                strokeWidth="1" stroke="white" fill="white" opacity="0.7"></rect>
            <rect x="32" y="30" width="40" height="5"
                strokeWidth="1" stroke="white" fill="white" opacity="0.7"></rect>
            <rect x="32" y="45" width="40" height="5"
                strokeWidth="2" stroke="white" fill="white" opacity="0.7"></rect>
        </svg>
    </div>
);

export default MenuIcon;