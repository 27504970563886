import React from 'react';

// Price calculation / formatting

export const formatPrice = price => {
    if ( typeof price !== 'number' ) {
        return price;
    }
    return ( price / 100 ).toFixed( 2 );
};

export const formatDollarAmount = value => {
    return formatPrice( value || '' );
};

export const processDollarEntry = ( e, tempAmount, setTempAmount ) => {
    e.stopPropagation();
    let newValue = tempAmount;
    if ( /^\d*(\.\d*)?$/.test( e.target.value ) ) {
        newValue = parseInt( e.target.value.replace( '.', '' ) );
    }
    setTempAmount( newValue );
    e.target.value = formatDollarAmount( newValue );
    return newValue;
};

// Display of item details - used in admin (kitchen prep) and shop (order details, cart).

export const formatModifier = ( value ) => (
    ! value || value === true ? '' :
    `\xA0(${ value.trim() })`
);

export const itemDetail = ( 
    variation, details, priceOverrides, classDetailHeader, classDetailLine, classFirst 
) => {
    const lines = [];
    const classDetailFirst = [ classDetailLine, classFirst ].join( ' ' );

    if ( priceOverrides ) {
        
        for ( const override of priceOverrides ) {
            lines.push( 
                <div key={ lines.length } className={ classDetailLine } >
                    1 @ { formatPrice( override.price ) } - { override.description || 'Description requried' }
                </div>
            );
        }
        
        return lines;
    }

    for ( const detail of details ) {
        const headerClass = [ classDetailHeader, lines.length === 0 && classFirst ].join( ' ' );
        lines.push( 
            <div key={ lines.length } className={ headerClass } >
                1 @ base price - { formatPrice( variation.squarePrice ) }
            </div>
        );
        let modifiers = 0;
        for ( const modifier of detail ) {
            if ( modifier.selected || modifier.subModifier ) {
                if ( modifier.type.startsWith( 'SINGLE' ) ) {
                    for ( let index = 0; index < modifier.values.length; index++ ) {
                        if ( modifier.values[ index ] === modifier.selected &&
                            modifier.selected === modifier.defaultModifier &&
                            modifier.labels[ index ] === 'None'
                        ) // If the default modifier is "None" do not display it if "None" selected
                            continue;

                        if ( modifier.values[ index ] === modifier.selected 
                            || ( ! modifier.selected && modifier.subModifier ) 
                        ) {
                            lines.push(
                                <div key={ modifier.name + lines.length } 
                                    className={ 
                                        modifiers++ === 0 ? classDetailFirst : classDetailLine 
                                    }
                                >
                                    { modifier.name }: { 
                                        ( modifier.selected ? modifier.labels[ index ] : '' ) +
                                        formatModifier( modifier.subModifier ) 
                                    }
                                </div>
                            );
                            break;
                        }
                    }
                } else {
                    lines.push(
                        <div key={ modifier.name + lines.length } 
                            className={ 
                                modifiers++ === 0 ? classDetailFirst : classDetailLine 
                            }                        
                        >
                            { modifier.name }{ formatModifier( modifier.selected ) }
                        </div>
                    );
                }
            }
        }
        if ( modifiers === 0 ) {
            lines.push( <div key={ 'asis' + lines.length } className={ classDetailFirst } >As is.</div> );
        }
    }
    return lines;
};
