import { get } from './fetch';

const apiGetOrders = async ( userId, setOrders, dispatch ) => {
    await get( 'apiGetOrders', 
        `/api/orders/${ userId ? `user/${ userId }` : 'all' }`, setOrders,
        dispatch
    );
}

export default apiGetOrders;
