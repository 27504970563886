import { get } from './fetch';

const apiGetOrder = async ( orderId, setOrder, dispatch ) => {
    await get( 'apiGetOrder', 
        `/api/orders/order/${ orderId }`, setOrder,
        dispatch
    );
}

export default apiGetOrder;
