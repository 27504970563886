import { post } from './fetch';

const apiCreateStoreOrder = async ( 
    userId, pickupDate, cart, cartNotes, setErrors, dispatch 
) => {
    await post( 'apiCreateStoreOrder', 
        '/api/order/store', 
        { userId, pickupDate, cart, cartNotes },
        setErrors,
        dispatch
    );
}

export default apiCreateStoreOrder;
