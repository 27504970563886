import { post } from './fetch';

const apiCreateTerminalDeviceCode = async ( setSquareIds, dispatch ) => {
    await post( 'apiCreateTerminalDeviceCode', 
        '/api/square/terminal/create-code', {}, setSquareIds,
        dispatch
    );
}

export default apiCreateTerminalDeviceCode;
