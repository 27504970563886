const INFINITY = 1000;
const NO_PARTS = { usesParts: [] };

export const itemsAvailableToday = ( squareVariationId, itemOrderControls ) => {
    // Return the number available today which usually require more notice.
    // NOTE: These items will have already been made up so they do not depend on
    // components - if there are any defined.
    const controls = ( itemOrderControls || {} )[ squareVariationId ] || {};
    return controls.availableSameDay || 0;
}

export const itemsAvailable = ( squareVariationId, cart, itemOrderControls ) => {
    // Return the number available
    if ( ! itemOrderControls || ! cart ) {
        return 0; // The controls and cart objects have not been loaded yet - say none available
    }
    const controls = itemOrderControls[ squareVariationId ];
    if ( ! controls || 
        ( controls.availableToOrder === undefined && controls.usesParts.length === 0 )
    ) {
        return INFINITY; // Effectively an unlimited number available.
    }

    if ( controls.availableToOrder === 0 ) {
        return 0; // Doesn't matter if parts are available.
    }

    let maxAvailable = controls.availableToOrder || INFINITY;

    if ( controls.usesParts.length === 0 ) {
        return maxAvailable;
    }

    const partsAvailable = {};

    // Gather available number of parts needed for current item
    for ( const { controlId, number } of controls.usesParts ) {
        const partControl = itemOrderControls[ controlId ];
        if ( partControl ) {
            partsAvailable[ controlId ] = { 
                available: partControl.availableToOrder,
                neededPerItem: number
            };
        }
    }

    // Reduce available number by parts needed for other items
    for ( const orderItem of cart ) {
        if ( orderItem.variation.squareVariationId === squareVariationId ) {
            continue; // This is the current item.
        }
        const itemControls = itemOrderControls[ orderItem.variation.squareVariationId ] || NO_PARTS;
        for ( const { controlId, number } of itemControls.usesParts ) {
            const sharedPart = partsAvailable[ controlId ];
            if ( sharedPart ) {
                // Adjust available for parts needed by other items
                sharedPart.available -= orderItem.count * number;
            }
        }
    }

    // The number of items available is the smallest number by item or by part
    for ( const { available, neededPerItem } of Object.values( partsAvailable ) ) {
        const availableItems = parseInt( available / neededPerItem );
        if ( availableItems < maxAvailable ) {
            maxAvailable = availableItems;
        }
    }

    return maxAvailable;
}

export const anyAvailable = ( variation, itemOrderControls ) => (
        itemsAvailableToday( variation.squareVariationId, itemOrderControls ) > 0 || 
        itemsAvailable( variation.squareVariationId, [], itemOrderControls ) > 0
)
