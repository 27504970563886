import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from '@fernleaf/util';

import apiSendReceipt from '../api/sendReceipt';

import classes from './ReceiptModal.module.css';

const EMAIL = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;

const ReceiptModal = ( { orderId, initialEmail, onReceiptSent, onClose } ) => {
    const dispatch = useDispatch();
    const [ email, setEmail ] = useState( initialEmail || '' );


    const updateEmail = useCallback( (e) => {
        setEmail( e.target.value );
    }, [ setEmail ] );

    const validEmailClass = useCallback( () => {
        return email.match( EMAIL ) ? null : classes.DisableSend; 
    }, [ email ] );

    const sendReceipt = useCallback( (e) => {
        e.stopPropagation();
        if ( email.match( EMAIL ) ) {
            apiSendReceipt( orderId, email, onReceiptSent, dispatch );
        }
    }, [ orderId, email, onReceiptSent, dispatch ] );

    return (
        <Modal onClick={ onClose } >
            <div onClick={ (e) => e.stopPropagation() } >
                <div>Email address for receipt:</div>
                <br />
                <input type='text' defaultValue={ email } 
                    onClick={ (e) => { e.stopPropagation(); } }
                    placeholder='Email address'
                    onChange={ updateEmail }
                />
                <Button onClick={ sendReceipt } className={ validEmailClass() }>
                    Send Receipt
                </Button>
                <Button onClick={ onClose } >Cancel</Button>
            </div>
        </Modal>
    )
}

export default ReceiptModal;