import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button, orderItemUtils } from '@fernleaf/util';

import { 
    setPriceOverrides
} from '../store/redux';

import classes from './PriceOverrides.module.css';

const ignoreClick = (e) => e.stopPropagation();

const ItemPriceOverride = ( { overrides, setOverrides, index } ) => {
    const [ description, setDescription ] = useState( overrides[ index ].description );
    const [ price, setPrice ] = useState( overrides[ index ].price );

    const updateOverrides = useCallback( ( { description, price } ) => {
        const newOverrides = [ ...overrides ];

        if ( description !== undefined ) {
            newOverrides[ index ].description = description;
        }

        if ( price !== undefined ) {
            newOverrides[ index ].price = price;
        }

        setOverrides( newOverrides );

    }, [ overrides, setOverrides, index ]);

    const processDescriptionEntry = useCallback( (e) => {
        e.stopPropagation();
        setDescription( e.target.value );
        updateOverrides( { description: e.target.value } );
    }, [ updateOverrides ] );

    const processPriceEntry = useCallback( (e) => {
        e.stopPropagation();
        let newPrice = orderItemUtils.processDollarEntry( e, price, setPrice )
        updateOverrides( { price: newPrice } );
    }, [ price, setPrice, updateOverrides ] );

    return (
        <div className={ classes.PriceOverride } >

            <input type='text' 
                defaultValue={ description } 
                placeholder='Description' className={ classes.Description }
                onInput={ processDescriptionEntry }
            />

            <input type='text' 
                defaultValue={ orderItemUtils.formatDollarAmount( price ) } 
                placeholder='Price' className={ classes.Price }
                onInput={ processPriceEntry }
            />

        </div>
    );
}

const endorsedOverrides = ( orderItem ) => {
    if ( orderItem.variation.squareItemName === 'Gift Certificate' ) {
        orderItem.priceOverrides.forEach( override => override.description = 'Gift certificate' );
    }
    return orderItem.priceOverrides;
}

const PriceOverrides = ( { orderItem, onCancel } ) => {
    const dispatch = useDispatch();
    const [ originalOverrides ] = useState( orderItem.priceOverrides.map( 
        override => ( { ...override } )
    ) );
    const [ overrides, setOverrides ] = useState( [ ...endorsedOverrides( orderItem ) ] );

    const cancelChanges = useCallback( (e) => {
        e.stopPropagation();
        dispatch( setPriceOverrides( orderItem.variation, originalOverrides ) );
        onCancel();
    }, [ originalOverrides, orderItem, onCancel, dispatch ] );

    const saveChanges = useCallback( (e) => {
        e.stopPropagation();
        dispatch( setPriceOverrides( orderItem.variation, overrides ) );
        onCancel();
    }, [ overrides, orderItem, onCancel, dispatch ] );

    const inputValid = useCallback( () => (
        overrides.every( ( override ) => (
            ( override.description || '' ).trim().length > 0 && override.price > 0
        ) )
    ), [ overrides ] );

    const s = orderItem.count > 1 ? 's' : '';

    return (
        <Modal onClick={ cancelChanges } className={ classes.Modal } 
            contentClassName={ classes.Content }
        >
            <div onClick={ ignoreClick } >

                <div>
                    Describe the <strong>{ orderItem.variation.squareItemName }</strong> 
                    &nbsp;item{ s } and give the price{ s }.
                </div>

                {
                    overrides.map( ( override, index ) => (
                        <ItemPriceOverride key={ index }
                            overrides={ overrides } setOverrides={ setOverrides } index={ index }
                        />
                    ) )
                }

                <div className={ classes.PriceOverride } >
                    { inputValid() &&
                        <Button onClick={ saveChanges } >Save</Button>
                    }
                    <Button onClick={ cancelChanges } >Cancel</Button>
                </div>
            </div>
        </Modal>
    )
}

export default PriceOverrides;