import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal, orderItemUtils } from '@fernleaf/util';

import apiReportSystemError from '../../api/reportSystemError';
import apiPayOrder from '../../api/payOrder';

import { clearCart, setPickupDate } from '../../store/redux';

import classes from './CashDialog.module.css';

const formatChange = value => ! value ? '0.00' : orderItemUtils.formatDollarAmount( value );

const nextMultiple = ( amount, billSize ) => (
    parseInt( amount / billSize ) * billSize + billSize
);

const focusInput = ( inputField ) => inputField && inputField.focus();

const CashDialog = ( { 
    order, setOrder, paymentTotal, NavigationButtons, onCancel 
} ) => {
    const dispatch = useDispatch();
    const [ blockInput, setBlockInput ] = useState( false );
    const [ cashAmount, setCashAmount ] = useState( 0 );
    const [ cashPayment, setCashPayment ] = useState( null );

    const processCashEntry = useCallback( (e) => {
        orderItemUtils.processDollarEntry( e, cashAmount, setCashAmount );
    }, [ cashAmount, setCashAmount ] );

    const completeCashPayment = useCallback( (e) => {
        setBlockInput( true );
        apiPayOrder( order._id, paymentTotal, null, 'CASH', cashAmount, null,
            ( reply ) => {
                let message = 'Cash payment failed - CHECK FOR ERROR EMAIL.';
                if ( reply && reply.errors && reply.errors[ 0 ].code === 'TIMEOUT' ) {
                    message = 'Cash payment timed out - CHECK IN SQUARE TO SEE IF PAYMENT COMPLETED.';
                }
                if ( ! reply.payment ) {
                    const unexpected = JSON.stringify( reply || 'PAYMENT WAS NULL' );
                    apiReportSystemError( 
                        'SHOP006 - Unexpected issue in Square cash payment', unexpected, dispatch 
                    );
                    alert( message );
                } else {
                    dispatch( clearCart() );
                    dispatch( setPickupDate( null ) );
                    setOrder( reply.order );
                    setCashPayment( reply.payment );
                }
                setBlockInput( false );
            },
            dispatch
        );
    }, [ order, paymentTotal, cashAmount, setOrder, setCashPayment, setBlockInput, dispatch ] );

    const layout = [ [ '', '', '7',  '8', '9', '' ],
                     [ '', '', '4',  '5', '6', '' ],
                     [ '', '', '1',  '2', '3', '' ],
                     [ '', '', '0', '00', '⌫', '' ],
                     [ '', '', '',   'C' ]
    ];

    layout[ 0 ][ 0 ] = paymentTotal;
    let next = 1;

    const bills = [ 100, 500, 1000, 2000 ];
    for ( const bill of bills ) {
        const nextTender = nextMultiple( layout[ 0 ][ 0 ], bill );
        if ( nextTender > layout[ next - 1 ][ 0 ] ) {
            layout[ next++ ][ 0 ] = nextTender;
            if ( next === layout.length ) {
                break;
            }
        }
    }

    return (
        <React.Fragment>
            { cashPayment ?
                <div onClick={ e => e.stopPropagation() } >
                    <table className={ classes.ChangeAmount } >
                        <tbody>{
                            [ { title: 'Order Total',  
                                amount: cashPayment.total_money.amount },
                                { title: 'Cash tendered', 
                                amount: cashPayment.cash_details.buyer_supplied_money.amount },
                                { title: 'Change to customer',
                                amount: cashPayment.cash_details.change_back_money.amount }
                            ].map( ( amount, index ) =>
                                <tr className={ 
                                        index < 2 ? classes.ChangeAmountRow : '' } 
                                    key={ index }
                                >
                                    <td>{ amount.title }</td>
                                    <td>:</td>
                                    <td className={ classes.ChangeAmountAmt } >
                                        { formatChange( amount.amount ) }
                                    </td>
                                </tr> )
                        }</tbody>
                    </table>

                    <NavigationButtons order={ order } />
                </div>
            :
                <div onClick={ (e) => e.stopPropagation() } >
                    <div className={ classes.Legend }>Cash tendered</div>
                    <br />
                    <div className={ classes.Calculator } >
                        <div className={ classes.KeyPad } > {
                            layout.map( ( line, lx ) => (
                                <React.Fragment key={ lx } > {
                                    line.map( ( column, cx ) => {
                                        const digit = ( value, scale ) => {
                                            if ( value === null ) {
                                                return <div key={ cx } className={ classes.Spacer } />;
                                            }
                                            const fn = ( (e) => {
                                                e.stopPropagation();
                                                let newValue;
                                                if ( cx === 0 ) {
                                                    if ( column === '' ) {
                                                        return;
                                                    }
                                                    newValue = column;
                                                } else if ( column === 'C' ) {
                                                    newValue = 0;
                                                } else {
                                                    const base = cashAmount * 
                                                        ( scale === 'C' ? 0 : scale || 10 );
                                                    newValue = parseInt( base + value );
                                                }
                                                setCashAmount( newValue );
                                                document.getElementById( 'cashInput' ).value = 
                                                    orderItemUtils.formatDollarAmount( newValue );
                                                if ( cx === 0 ) {
                                                    setTimeout( () => {
                                                        document.getElementById( 'cashInputOK' ).click();
                                                    }, 200 );
                                                }
                                            } );
                                            const className = (
                                                cx === 0 ? classes.Prompt : classes.Key 
                                            );
                                            const display = (
                                                cx === 0 ? 
                                                    orderItemUtils.formatDollarAmount( value ) : column
                                            );
                                            return (
                                                <div key={ cx } 
                                                    onClick={ fn } 
                                                    className={ className }
                                                >{ display }</div>
                                            );
                                        }
                                        if ( typeof column === 'string' ) {
                                            if ( column === '00' ) {
                                                return digit( 0, 100 );
                                            }
                                            if ( column >= '0' && column <= '9' ) {
                                                return digit( parseInt( column ) );
                                            }
                                            if ( column === '⌫' ) {
                                                return digit( 0, .1 );
                                            }
                                            if ( column === 'C' ) {
                                                return digit( 0, 'C' );
                                            }
                                            return digit( null );
                                        }
                                        return digit( column, 'C' );
                                    } )
                                } </React.Fragment>
                            ) )
                        } </div>
                    </div>
                    <input id='cashInput' type='text'
                        defaultValue={ orderItemUtils.formatDollarAmount( cashAmount ) } 
                        placeholder='Cash' className={ classes.CashAmountInput }
                        onInput={ processCashEntry } ref={ focusInput }
                    />
                    <Button id='cashInputOK' onClick={ completeCashPayment }
                        className={ classes.Disabled }
                        disabled={ ! cashAmount || cashAmount < paymentTotal }
                    >
                        Amount OK
                    </Button>
                    <Button onClick={ onCancel } >Cancel</Button>
                </div>
            }

            { blockInput && <Modal noX showSpinner /> }

        </React.Fragment>
    )
}

export default CashDialog;