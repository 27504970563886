import { get } from './fetch';

const apiGetOrdersToPrep = async ( setOrdersToPrep, dispatch ) => {
    await get( 'apiOrdersToPrep', '/api/orders/to-prep', 
        setOrdersToPrep,
        dispatch
    );
}

export default apiGetOrdersToPrep
