// props:
//  in            - true - run through enter cycle
//                - false - run through exit cycle
//  timeout       - lengh of transition to final disposition - default 500ms
//  unmountOnExit - remove child component at the end of the exit cycle
//  onEnter       - callback at start of enter cycle
//  onExited      - callback at final exit

import { useState, useEffect } from "react";

const FadeInOut = ( props ) => {
    const mount = props.in;
    const timeout = props.timeout || 500;
    const { onEnter, onExited } = props;

    const [ state, setState ] = useState( "enter" );
    const [ hide, setHide ] = useState( ! props.in );

    useEffect( () => {
        if ( ! mount && state === "enter" ) {
            return; // Don't start the cycle
        }

        const timers = [];
        if ( mount ) {
            switch ( state ) {
                case "enter":
                    onEnter && onEnter();
                    setHide( false );
                    setState( "enter-active" );
                    break;
                case "enter-active": 
                    timers.push( setTimeout( () => setState( "enter-done" ), timeout ) );
                    break;
                default:
                    break;
            }
        } else {
            switch ( state ) {
                case "exit-active": // Dismount child component - no longer needed
                    timers.push(
                        setTimeout( () => {
                            onExited && onExited();
                            setHide( true );
                            setState( "enter" );
                        }, timeout )
                    );
                    break;
                case "exit": // Trigger exit transition class
                    setState( "exit-active" );
                    break;
                default: // Currently in some sort of enter state - start exit cycle
                    setState( "exit" );
                    break;
            }
        }
        return () => timers.map( timer => clearTimeout( timer ) ); // Clean up timers
    }, [state, hide, mount, timeout, onEnter, onExited]);

    return (
        ! mount && hide && props.unmountOnExit ? null : // Exit cycle has completed
        props.children( { state } )
    );
}

export default FadeInOut;