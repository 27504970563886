import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal } from '@fernleaf/util';

import apiGetSquareIds from '../../api/getSquareIds';
import apiCreateTerminalDeviceCode from '../../api/createTerminalDeviceCode';

import classes from './ConnectTerminalDialog.module.css';

const ConnectTerminalDialog = ( { order, squareIds, setSquareIds, swipeCard, tipAmount, children } ) => {
    const dispatch = useDispatch();
    const [ showTerminalDialog, setShowTerminalDialog ] = useState( false );
    const [ first, setFirst ] = useState( true );

    const saveSquareIds = useCallback( ( squareIds ) => {
        setSquareIds( squareIds );
        setShowTerminalDialog( ! squareIds.deviceId );
    }, [ setSquareIds, setShowTerminalDialog ] );

    const createTerminalDeviceCode = useCallback( (e) => {
        e.stopPropagation();
        setFirst( true );
        apiCreateTerminalDeviceCode( saveSquareIds, dispatch );
    }, [ saveSquareIds, dispatch ] );

    const checkTerminalDevice = useCallback( (e) => {
        e.stopPropagation();
        const processResult = ( squareIds ) => {
            saveSquareIds( squareIds );
            setFirst( false );
        }
        apiGetSquareIds( processResult, dispatch );
    }, [ saveSquareIds, dispatch ] );

    const hideTerminalDialog = useCallback( (e) => {
        e.stopPropagation();
        setShowTerminalDialog( false );
    }, [ setShowTerminalDialog ] );

    return (
        <React.Fragment>
            { showTerminalDialog ?
                <Modal onClick={ hideTerminalDialog } >
                    <div onClick={ (e) => e.stopPropagation() } >
                        { squareIds.deviceCodeId &&
                            <div className={ classes.DisplayCode } >Terminal code: 
                                <strong>{ squareIds.deviceCode }</strong>
                            </div>
                        }
                        { ! first && 
                            <div className={ classes.NotConnected } >Terminal is not connected.</div>
                        }
                        <Button onClick={ checkTerminalDevice } >
                            Terminal Connected
                        </Button>
                        <Button onClick={ createTerminalDeviceCode } >
                            Get new code
                        </Button>
                        <Button onClick={ hideTerminalDialog } >Cancel</Button>
                    </div>
                </Modal>
            :
                <React.Fragment>
                    { squareIds.deviceId &&
                        <Button onClick={ swipeCard } 
                            className={ classes.Disabled }
                            disabled={ tipAmount }
                        >
                            { tipAmount ? 'No swipe - tip present' : 'Swipe card' }
                        </Button>
                    }
                    
                    {
                        children
                    }

                    <Button onClick={ createTerminalDeviceCode } className={ classes.Connect } >
                        { squareIds.deviceId ? 'Reconnect' : 'Connect' } terminal
                    </Button>
                </React.Fragment>
            }
        </React.Fragment>
    );
}

export default ConnectTerminalDialog;