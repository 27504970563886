import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './NavigationItem.module.css';

const NavigationItem = ( { onClick, to, external, newTab, children } ) => (
    <li className={ classes.NavigationItem } >
        { external ?
            <a href={ external } onClick={ onClick } target={ newTab ? '_blank' : null } >
                { children }
            </a>
        :
            <NavLink to={ to } exact onClick={ onClick } activeClassName={ classes.active } >
                { children }
            </NavLink>
        }
    </li>
);

export default NavigationItem;