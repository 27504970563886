// General backdrop to be used for overlays - shows by default. 
// props.hide will hide - by not rendering the backdrop
import React from 'react'

import classes from './Backdrop.module.css'

const Backdrop = ( { hide, onClick, noX, relative, front, className } ) => {
    const clss = [ classes.Backdrop ]

    if ( relative ) {
        clss.push( classes.Relative );
    }
    if ( front ) {
        clss.push( classes.Front );
    }
    if ( className ) {
        clss.push( className );
    }
    return hide ? null : (
        <div className={ clss.join(' ') }
            onClick={ (e) => { e.stopPropagation(); onClick && onClick( e ); } } >
            { noX ? null : <div className={ classes.X }>&times;</div> }
        </div>
    );
}

export default React.memo( Backdrop );
