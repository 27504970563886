import React from 'react';
import canUpdate from '../utils/canUpdate';

import classes from './RadioButtons.module.css';

const RadioButtons = ( { 
    thing, field, values, labels, title, noneLabel, noNone, readOnly,
    className, titleClassName, buttonsClassName, buttonClassName,
    children, nestedClass
} ) => {

    if ( thing.isHeader ) {
        return null;
    }

    let update = ! readOnly;
    update = update && canUpdate( thing );

    if ( ! labels ) {
        labels = values;
    }

    if( ! Array.isArray( values ) || ! Array.isArray( labels ) || values.length !== labels.length ) {
        throw new Error( '"values" and "labels" must be arrays of equal length' );
    }

    const classNames = [
        classes.Radio,
        update && classes.CanUpdate,
        className,
        update && thing.isUpdated( field ) && classes.Pending
    ].join( ' ' );

    const buttonsClasses = [ 
        classes.Buttons, buttonsClassName 
    ].join( ' ' );

    const setName = ( title || '' ) + Math.random() * 10000000;

    return (
        <div className={ [ classes.Updatable, classNames ].join( ' ' ) } >
            { title &&
                <div className={ [ classes.Title, titleClassName ].join( ' ' ) } >
                    { title }:
                </div>
            }
            <div className={ buttonsClasses } >
                { children && <div className={ nestedClass } >{ children }</div> }
                { values.map( ( value, index ) =>
                    <label key={ setName + value } >
                        <input type="radio" name={ setName }
                            value={ value } checked={ thing[ field ] === value }
                            onChange={ () => thing[ field ] = value }
                            className={ [ classes.RadioButton, buttonClassName ].join( ' ' ) }
                        />
                        { labels[ index ] }
                    </label>                
                ) }
                { ! noNone && thing[ field ] &&
                    <label>
                        <input type="radio" name={ setName }
                            value="NONE" checked={ ! thing[ field ] }
                            onChange={ () => thing[ field ] = null }
                            className={ [ classes.RadioButton, buttonClassName ].join( ' ' ) }
                        />
                        { noneLabel || 'None' }
                    </label>
                }
            </div>
        </div>
    );
}

export default RadioButtons;