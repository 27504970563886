// Add the activityMonitor middleware to the store. 
// It will perform an activity passed to startMonitor if there is
// no activity in the store for the specified timeout.

// The granularity is 15 seconds to avoid too many setTimeout calls but the
// monitor timeout must be at least 15 seconds

// This is used by the LoginRouter to dispatch a logout when there has been no
// activity for a specified time. It can be used for other things.

const monitors = new Map();

let lastCheck = Date.now();

const newTimeout = ( action, timeout ) => (
    setTimeout( () => {
        monitors.delete( action );
        action();
    }, timeout )
);

const kickTheCans = () => {
    for ( const [ action, monitor ] of monitors ) {
        clearTimeout( monitor.timer );
        monitor.timer = newTimeout( action, monitor.timeout );
    }
}

export const startMonitor = ( action, timeoutInSeconds ) => {
    if ( timeoutInSeconds < 20 ) {
        throw new Error( 'activityMonitor.startMonitor: You must set a timeout of at least 20 (seconds)' );
    }
    const timeout = timeoutInSeconds * 1000;
    if ( monitors.has( action ) ) {
        clearTimeout( monitors.get( action ).timer );
    }
    monitors.set( action, { timer: newTimeout( action, timeout ), timeout } );
}

export const activityMonitor = store => (
    next => (
        action => {
            if ( Date.now() - lastCheck > 15000 ) {
                kickTheCans();
            }
            lastCheck = Date.now();

            return next( action );
        }
    )
)
