import React from 'react';
import { useDispatch } from 'react-redux';

import { setError } from '../store/actions';

import Modal from '../Modal/Modal';
import Button from '../Button/Button';

import classes from './SystemError.module.css';

const SystemError = ( { error } ) => {
    const dispatch = useDispatch();

    return (
        <Modal noX className={ classes.Modal } >
            <h3 className={ classes.Header } >System Error</h3>
            <p>
                Something unexpected happened. 
                Please try again and if you still have a problem then contact the bakery at (207) 286-3376.
            </p>
            <p>Detail: { error }</p>
            <Button onClick={ () => dispatch( setError( null ) ) }>OK</Button>
        </Modal>
    )
};

export default SystemError;