import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal } from '@fernleaf/util';
import apiReportSystemError from '../../api/reportSystemError';
import apiPayOrder from '../../api/payOrder';

import { clearCart, setPickupDate } from '../../store/redux';

import OkNavigationButtons from '../OkNavigationButtons/OkNavigationButtons';

import classes from './SquareWebPaymentForm.module.css';

const UNEXPECTED_ERROR = 'Something unexpected happened when we tried to verify your card. ' +
    'Please try again or contact the bakery at 286-3376';

const decodeError = ( payment, dispatch ) => {
    try {
        if ( payment.error ) {
            return payment.error;
        } else if ( payment.errors ) {
            switch( payment.errors[ 0 ].code ) {
                case 'CVV_FAILURE':
                    return 'There was a problem with the security code.';
                case 'ADDRESS_VERIFICATION_FAILURE':
                    return 'There was a problem with the zip code.';
                case 'INVALID_EXPIRATION':
                case 'EXPIRATION_FAILURE':
                    return 'There was a problem with the expiry date.';
                case 'INSUFFICIENT_FUNDS':
                    return 'The charge exceeded your available balance.';
                case 'GENERIC_DECLINE':
                    return 'The card could not be charged.';
                case 'CARD_EXPIRED':
                    return 'The card has expired.';
                case 'TRANSACTION_LIMIT':
                    return 'The purchase amount could not be authorized.';
                case 'BAD_REQUEST':
                    if ( payment.errors[ 0 ].detail === 'The order is already paid.' ) {
                        console.log( "ORDER ALREADY PAID - DOUBLE CLICK?" );
                        return 'OK. The order was paid.';
                    }
                    break;
                case 'TIMEOUT':
                    return 'TIMEOUT'
                default:
                    break;
            }
        }
        const unexpected = JSON.stringify( payment );
        apiReportSystemError( 
            'SHOP004 - Unexpected issue in Square nonce request', unexpected, dispatch 
        );
        return UNEXPECTED_ERROR;
    } catch( error ) {
        apiReportSystemError( 
            'SHOP005 - Error handling issue in Square nonce request', error.toString(), dispatch 
        );
        return UNEXPECTED_ERROR;
    }
};

const SquareWebPaymentForm = ( { 
    squareIds, order, setOrder, paymentTotal, tipAmount, 
    onCancel
} ) => {
    const dispatch = useDispatch();
    const [ card, setCard ] = useState( null );
    const [ blockInput, setBlockInput ] = useState( false );
    const [ showCompleteModal, setShowCompleteModal ] = useState( false );
    const [ squareError, setSquareError ] = useState( null );

    useEffect( () => {
        if ( ! card ) {
            const getSquareCard = async () => {
                let payments;
                try {
                    payments = window.Square.payments( squareIds.appId, squareIds.locationId );
                } catch( error ) {
                    setSquareError( UNEXPECTED_ERROR );
                    console.log( "ERROR IN PAYMENTS" );
                    console.log( { error } );
                    apiReportSystemError( 'SHOP001 - Square.payments() error', error.toString(), dispatch );
                    return;
                }
                let card;
                try {
                    card = await payments.card( {} )
                } catch( error ) {
                    setSquareError( UNEXPECTED_ERROR );
                    console.log( "ERROR IN CARD" );
                    console.log( { error } );
                    apiReportSystemError( 'SHOP002 - Square.card() error', error.toString(), dispatch );
                    return;
                }
                try {
                    card.attach( '#sq-card' );
                } catch( error ) {
                    setSquareError( UNEXPECTED_ERROR );
                    console.log( "ERROR ATTACHING CARD" );
                    console.log( { error } );
                    apiReportSystemError( 'SHOP003 - card.attach() error', error.toString(), dispatch );
                    return;
                }
                setCard( card );
            }
            getSquareCard();
        }
    }, [ card, setCard, squareIds, dispatch ] );

    useEffect( () => {
        const checkIfLoaded = () => {
            try {
                const squareCardContainer = document.getElementById( 'sq-card' );
                const squareForm = squareCardContainer.querySelector( 'div' );
                const span = squareCardContainer.querySelector( 'span' );
                if ( squareForm ) {
                    span.remove();
                } else {
                    span.innerHTML = span.innerHTML + '.';
                    setTimeout( checkIfLoaded, 250 );
                }
            } catch( error ) {
                console.log( "CHECK LOADED" );
                console.log( error );
            }
        };
        checkIfLoaded();
    }, [] );

    const orderComplete = ( reply ) => {
        if ( reply.error || reply.errors ) {
            setSquareError( decodeError( reply, dispatch ) );
            return;
        }

        dispatch( clearCart() );
        dispatch( setPickupDate( null ) );
        setOrder( reply.order );
        setBlockInput( false );
        setShowCompleteModal( true );
    };

    const tokenizePayment = async ( e ) => {
        e.stopPropagation();
        setBlockInput( true );

        const token = await card.tokenize();
        
        try {
            if ( token.status === "OK" ) {
                if ( token.token.startsWith( 'cnon:' ) ) {
                    apiPayOrder( order._id, paymentTotal, tipAmount, token.token, null, null,
                        orderComplete, dispatch
                    );
                    return;
                } else {
                    setSquareError( UNEXPECTED_ERROR );
                    console.log( "UNKNOWN TOKEN TYPE" );
                    console.log( { token } );
                    apiReportSystemError( 'SHOP007 - card.tokenize() - bad token', token.toString(), dispatch );
                }
            } else {
                setSquareError( UNEXPECTED_ERROR );
                console.log( "BAD TOKEN STATUS" );
                console.log( { token } );
                apiReportSystemError( 'SHOP008 - card.tokenize() - bad status', token.toString(), dispatch );
            }
        } catch( error ) {
            setSquareError( UNEXPECTED_ERROR );
            console.log( "CARD TOKENIZE ERROR" );
            console.log( { error } );
            apiReportSystemError( 'SHOP009 - card.tokenize() - error', error.toString(), dispatch );
        }
        
        setBlockInput( false );
    }

    return (
        <div className={ classes.PaymentForm } onClick={ (e) => e.stopPropagation() } >
            <div id='sq-card' style={{ touchAction: 'none' }}><span>Loading.</span></div>
            <Button onClick={ tokenizePayment }
                className={ classes.PayNow } >
                Pay ${ ( 
                    ( paymentTotal + ( tipAmount || 0 ) ) / 100 
                ).toFixed( 2 ) } now
            </Button>
            <Button onClick={ onCancel } className={ classes.Cancel } >Cancel payment</Button>

            { showCompleteModal &&
                <Modal noX onClick={ e => e.stopPropagation() } >
                    <div>Thank you for your payment.</div>
                    <OkNavigationButtons order={ order } />
                </Modal>
            }
            
            { squareError === 'TIMEOUT' &&
                <Modal onClick={ e => e.stopPropagation() } className={ classes.SquareError } >
                    <div>
                        <h3>Payment TIMED OUT:</h3>
                        <p>There was a problem processing your card.
                        <br />Your card MAY HAVE BEEN charged.
                        </p>
                        <p>Call the bakery at (207) 286-3376 and ask them to check the order in Square.</p>
                        <Button onClick={ onCancel } >UNDERSTOOD</Button>
                    </div>
                </Modal>
            }

            { squareError && squareError !== 'TIMEOUT' &&
                <Modal onClick={ e => e.stopPropagation() } className={ classes.SquareError } >
                    <div>
                        <h3>Payment not completed:</h3>
                        <p>There was a problem processing your card.
                        <br />Your card was not charged.
                        </p>
                        <h3>Details:</h3>
                        { Array.isArray( squareError ) ?
                            <ul>
                                { squareError.map( error => <li key={ error } >{ error }</li> ) }
                            </ul>
                        :
                            <div className={ classes.SquareMessage } >{ squareError }</div>
                        }
                        <Button onClick={ () => setSquareError( null ) } >Retry payment</Button>
                        <Button onClick={ onCancel } >Cancel payment</Button>
                    </div>
                </Modal>
            }

            { blockInput && <Modal noX showSpinner /> }

        </div>
    );
}

export default SquareWebPaymentForm;