import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
    startOfDay, isSameDay,
    addDays, addHours, addMinutes, 
    parseISO , format, addMonths, isToday, differenceInCalendarDays
} from 'date-fns';

import { Button, DatePicker, TimePicker, fieldProxy, Modal, calendarUtils } from '@fernleaf/util';

import { 
    getCart, getCartCount,
    getCalendar, getPickupDate, setPickupDate, 
    getCustomer, getItemOrderControls, getExclusiveDates 
} from '../store/redux';
import { itemsAvailable, itemsAvailableToday } from '../stockControl/stockControl';

import classes from './PickupDate.module.css';

const MINIMUM_LEAD_TIME = { units: 'Minutes', number: 0 };

const compareLeadTimes = ( leadTime1, leadTime2 ) => {
    if ( ! leadTime1 ) {
        return ! leadTime2 ? 0 : -1;
    }
    if ( ! leadTime2 ) {
        return +1;
    }
    if ( leadTime1.units === leadTime2.units ) {
        return leadTime1.number - leadTime2.number;
    }
    return leadTime1.units === 'Days' ? +1 : -2;
}

const greaterLeadTimeOf = ( leadTime1, leadTime2 ) => (
    compareLeadTimes( leadTime1, leadTime2 ) >= 0 ? leadTime1 : leadTime2
);

const getMaxLeadTime = ( orderItems ) => {
    let leadTime = MINIMUM_LEAD_TIME;
    for ( const orderItem of orderItems ) {
        leadTime = greaterLeadTimeOf( 
            leadTime, 
            orderItem.variation.leadTime
        );
    }
    return leadTime;
}

const getFirstAvailableDate = ( leadTime, calendar ) => {
    const now = new Date();
    let earliest = startOfDay( now );
    let days = 0;
    let goToNextDay = false;
    if ( leadTime.units === 'Days' ) {
        if ( now.getHours() > 11 && ! calendarUtils.excludeDates( earliest, calendar ) ) { 
            // Ordering after noon on a business day - add 1 day to lead time
            days++;
        }
        days += leadTime.number;
    } else if ( leadTime.units === 'Minutes' ) {
        const [ openFrom, ] = calendarUtils.getHoursForDate( earliest, calendar );
        if ( openFrom ) { // Is open and not yet closing time
            return earliest;
        }
        goToNextDay = true;
    }
    for ( let i = 0; i < 65; i++ ) { // If there is no available date within 65 days
                                     // this will be caught by the ordering date limits
        if ( goToNextDay || calendarUtils.excludeDates( earliest, calendar ) ) {
            earliest = addDays( earliest, 1 );
            goToNextDay = false;
        } else if ( days > 0 ) {
            days--;
            earliest = addDays( earliest, 1 );
        } else {
            break; // Found an open date that is far enough away
        }
    }
    return earliest;
}

export const getDateRange = ( variation, cart, calendar, override, storeOrder ) => {
    let minDate = startOfDay( addDays( new Date(), storeOrder ? 1 : 0 ) );
    let maxDate = addMonths( minDate, 2 );
    if ( override ) {
        return [ minDate, maxDate, { units: 'Minutes', number: 1 } ];
    }

    // Make sure the new variation, if any, is in the list from the cart
    const orderItems = cart ? [ ...cart ] : [];
    if ( variation ) {
        if ( ! orderItems.some( 
            orderItem => orderItem.variation.squareVariationId === variation.squareVariationId 
        ) ) {
            orderItems.push( { variation: variation } );
        }
    }

    for ( const orderItem of orderItems ) {
        const variation = orderItem.variation;
        if ( variation.availableFrom && 
            ! ( parseISO( variation.availableFrom ) < minDate ) ) 
        {
            minDate = parseISO( variation.availableFrom );
        }
        if ( variation.availableTo && 
            ! ( parseISO( variation.availableTo ) > maxDate ) ) 
        {
            maxDate = parseISO( variation.availableTo );
        }
    }

    const maxLeadTime = getMaxLeadTime( orderItems );
    const firstAvailableDate = getFirstAvailableDate( maxLeadTime, calendar );
    
    if ( firstAvailableDate > maxDate ) {
        return [ null, null, maxLeadTime ];
    }

    return [ firstAvailableDate > minDate ? firstAvailableDate : minDate, maxDate, maxLeadTime ];
}

export const isBlackedOut = ( variation, date, exclusiveDates ) => {
    for ( const [ menuId, blackoutDates ] of Object.entries( exclusiveDates ) ) {
        if ( date < blackoutDates.availableFrom || date > blackoutDates.availableTo ) {
            if ( variation.menuId === menuId ) {
                return true; // From menu but outside of range
            }
        } else {
            if ( variation.menuId !== menuId ) {
                return true; // Not from menu but inside of range
            }
        }
    }
    return false;
}

export const allItemsAvailableToday = ( addVariation, cart, itemOrderControls ) => {
    const availableItems = ( variation ) => (
        ( variation.leadTime.units === 'Minutes' ) 
            ? itemsAvailable( variation.squareVariationId, cart, itemOrderControls )
            : itemsAvailableToday( variation.squareVariationId, itemOrderControls )
    );
    
    let addVariationFound = false;
    const addVariationId = addVariation && addVariation.squareVariationId;

    for ( const orderItem of cart ) {
        const available = availableItems( orderItem.variation );

        if ( orderItem.variation.squareVariationId === addVariationId ) {
            if ( orderItem.count + 1 > available ) {
                return false;
            }
            addVariationFound = true;
        } else {
            if ( orderItem.count > available ) {
                return false;
            }
        }
    }
    
    return (
        ! addVariation || addVariationFound || availableItems( addVariation ) > 0
    );
}

const adjustForNowAndClosing = ( date, startTime, endTime, lastPickup ) => {
    
    let endHours = parseInt( endTime / 100 );
    let endMinutes = endTime % 100;
    while ( endMinutes < lastPickup ) {
        endMinutes += 60;
        endHours--;
        if ( endHours < 0 ) {
            return [ null, null ]; // No available times
        }
    }
    endTime = endHours * 100 + endMinutes - lastPickup;

    const now = new Date();
    let startHour = now.getHours();
    let startMinute = now.getMinutes();
    let nowOpen = false;
    if ( isSameDay( date, now ) && startHour * 100 + startMinute > startTime ) {
        nowOpen = true;
        while ( startMinute % 15 !== 0 ) { // Find next quarter hour
            startMinute++;
        }
        if ( startMinute === 60 ) {
            startHour += 1;
            startMinute = 0;
        }
        startTime = startHour * 100 + startMinute;
    }

    if ( startTime > endTime ) {
        return [ null, null, false ];
    }

    return [ startTime, endTime, nowOpen ];
}

const getPickupTimes = ( date, calendar ) => {
    const [ openFrom, openTo ] = calendarUtils.getHoursForDate( date, calendar );
    if ( openFrom ) {
        return adjustForNowAndClosing(
            date, openFrom, openTo,
            calendar.lastPickupBeforeClosing
        );
    }
    return [ null, null, false ];
}

const dateAndTime = ( date, time ) => (
    date && date instanceof Date ?
        time && typeof time === 'number' ?
            addMinutes( addHours( startOfDay( date ), time / 100 ), time % 100 )
        :
            date
    :
        null
);

const timeFrom = ( date ) => {
    if ( date ) {
        return date.getHours() * 100 + date.getMinutes();
    }
    return '';
}

export const closingTime = ( date, calendar ) => {
    const [ , openTo ] = calendarUtils.getHoursForDate( date, calendar );
    const hour = parseInt( openTo / 100 );
    const minutes = openTo % 100;
    const display = 
        ( hour === 0 ? '12' : hour > 12 ? hour - 12 : hour ) + ':' +
        ( minutes < 10 ? '0' : '' ) + minutes + 
        ( hour < 12 ? ' am' : ' pm' );
    return display;
}

const PickupDate = ( { variation, onDateEntered, onCancel } ) => {
    const dispatch = useDispatch();
    const calendar = useSelector( getCalendar );
    const exclusiveDates = useSelector( getExclusiveDates );
    const cart = useSelector( getCart );
    const cartCount = useSelector( getCartCount );
    const customer = useSelector( getCustomer );
    const pickupDate = useSelector( getPickupDate );
    const itemOrderControls = useSelector( getItemOrderControls );
    const [ infoMessage, setInfoMessage ] = useState( null );
    const [ override, setOverride ] = useState( false );
    const [ confirmDateAndTime, setConfirmDateAndTime ] = useState( false );

    const now = new Date();
    const canOrderNow = ! ( customer && customer.storeOrder ) &&
        calendarUtils.canOrderForToday( calendar ); // Store orders must be next day or later

    let [ minDate, maxDate, maxLeadTime ] = 
        getDateRange( variation, cart, calendar, override, customer );
    const canOrderToday = ( 
        override || 
        ( canOrderNow && allItemsAvailableToday( variation, cart, itemOrderControls ) )
    );
    const firstPickupDate = canOrderToday ? startOfDay( now ) : minDate;
    
    const [ selectedDate, setSelectedDate ] = useState(
        ! pickupDate && ! variation && ( ! cart || cart.length === 0 ) ? null :
        pickupDate && firstPickupDate && ! ( firstPickupDate > pickupDate ) ? startOfDay( pickupDate ) : 
        firstPickupDate && isSameDay( firstPickupDate, now ) ? firstPickupDate : null
    );
    const [ selectedTime, setSelectedTime ] = useState( timeFrom( pickupDate ) );
    
    const [ message, setMessage ] = useState(
        canOrderNow && minDate && maxLeadTime.units === 'Days' && now.getHours() < 11 && 
            isSameDay( addDays( startOfDay( now ), maxLeadTime.number ), minDate ) ?
            'If you complete your order after noon you may need to choose a pickup date after ' +
            format( minDate, `EEEE${ addDays( now, 5 ) < minDate ? ', MMM d' : '' }` ) 
        : null
    );

    const displayPickupDate = useCallback( () => {
        if ( isToday( selectedDate ) ) {
            return 'today ';
        }
        const days = differenceInCalendarDays( selectedDate, Date.now() );
        return `${ 
            days === 7 ? 'next' : 
            days < 7   ? 'this coming' : '' } ${ 
            format( selectedDate, 'EEEE, MMMM d, yyyy ' )
        }`
    }, [ selectedDate ] );
    const displayPickupTime = useCallback( () => {
        if ( selectedTime === 0 ) {
            return 'as soon as ready';
        }
        const hh = parseInt( selectedTime / 100 );
        const mm = selectedTime % 100;
        const amPm = hh < 12 ? 'am' : 'pm';
        return ( hh > 12 ? hh - 12 : hh ) + ':' + ( mm < 10 ? '0' : '' ) + mm + ' ' + amPm;
    }, [ selectedTime ] );

    const acceptDateAndTime = useCallback( (e) => {
        const pickupDate = dateAndTime( selectedDate, selectedTime );
        dispatch( setPickupDate( pickupDate ) );
        onDateEntered( e, pickupDate );
    }, [ dispatch, selectedDate, selectedTime, onDateEntered ] );

    const clearDateAndTime = useCallback( (e) => {
        dispatch( setPickupDate( null ) );
        onCancel( e );
    }, [ dispatch, onCancel ] );

    const showConfirmDateAndTime = useCallback( (e) => {
        e.stopPropagation();
        if ( customer || isToday( selectedDate ) ) {
            // If ordering for a customer or the pickup is today bypass the confirmation popup
            acceptDateAndTime( e );   
        } else {
            setConfirmDateAndTime( true );
        }
    }, [ acceptDateAndTime, setConfirmDateAndTime, customer, selectedDate ] );
    const hideConfirmDateAndTime = useCallback( (e) => {
        e.stopPropagation();
        setConfirmDateAndTime( false );
    }, [ setConfirmDateAndTime ] );

    const excludeDates = useCallback( ( date ) => {
        if ( override ) {
            return false;
        }
        if ( date < minDate && ! isSameDay( date, firstPickupDate ) ) {
            return true;
        }
        if ( variation && isBlackedOut( variation, date, exclusiveDates ) ) {
            return true;
        }
        for ( const orderItem of cart ) {
            if ( isBlackedOut( orderItem.variation, date, exclusiveDates ) ) {
                return true;
            }
        }
        return calendarUtils.excludeDates( date, calendar ) 
    }, [ variation, cart, override, exclusiveDates, firstPickupDate, minDate, calendar ] );

    const pickupMessage = useCallback( () => {
        const days = differenceInCalendarDays( selectedDate, Date.now() );

        if ( days < 2 ) {
            return ( 
                <div className={ classes.PickupSooner } >
                    Your pickup is { days === 0 ? 'TODAY' : 'TOMORROW' }.
                </div>
            )
        }

        if ( days < 7 ) {
            return ( 
                <div className={ classes.PickupLessSoon } >
                    Your pickup is { days } days from today.
                </div>
            )
        }

        if ( days === 7 ) {
            return ( 
                <div className={ classes.PickupLater } >
                    Your pickup is ONE WEEK from today.
                </div>
            )
        }

        return ( 
            <div className={ classes.PickupLater } >
                Your pickup is MORE THAN ONE WEEK from today.
            </div>
        )
    }, [ selectedDate ] );
    
    if ( ! ( itemOrderControls && calendar ) ) {
        return null;
    }

    let startTime;
    let endTime;
    let showSoonest;
    if ( minDate && selectedDate ) {
        [ startTime, endTime, showSoonest ] = override ? 
            [ 600, 2100, true ] : getPickupTimes( selectedDate, calendar );
    }

    const selectedDateProxy = fieldProxy( {
        name: 'selectedDate',
        getter: () => selectedDate,
        setter: ( value ) => {
            setMessage( null );
            setSelectedDate( value && parseISO( value ) );
        }
    } );

    const selectedTimeProxy = fieldProxy( {
        name: 'selectedTime',
        getter: () => selectedTime,
        setter: ( value ) => {
            setMessage( null );
            if ( value === 0 ) {
                setInfoMessage( 'Please allow 10 minutes before picking up. ' +
                                'If there are orders ahead of yours it may be a bit longer.')
            }
            setSelectedTime( value === '' ? null : value );
        }
    } );

    return (
        <Modal show rounded onClick={ onCancel } >
            <div onClick={ (e) => e.stopPropagation() } className={ classes.Dialog } >
                <div className={ classes.Header }>What date do you want to pick up your order?</div>
                { customer && ! customer.storeOrder &&
                    <div>
                        <label>
                            Override date rules?
                            <input type='checkbox' id='override' value={ override }
                                onChange={ () => setOverride( ! override ) }
                            />
                        </label>
                    </div>
                }
                { minDate ?
                    <DatePicker thing={ selectedDateProxy } field='selectedDate' update 
                        minDate={ firstPickupDate } 
                        maxDate={ maxDate || firstPickupDate }
                        excludeDates={ excludeDates }
                        placeholder='Click here to pick date' showDayName
                        className={ classes.PickupDate }
                    />
                :
                    <p>
                        <strong>I'm sorry</strong> the item you're trying to add
                        cannot be ordered today. Please try again tomorrow.
                    </p>
                }

                { startTime ?
                    <React.Fragment>
                        <div>Pickup time:</div>
                        <TimePicker thing={ selectedTimeProxy } field='selectedTime'
                            startTime={ startTime } endTime={ endTime } open update
                            asap={ showSoonest } 
                            className={ classes.OrderTime }
                        />
                        <p />
                        <div className={ classes.ClosingTime } >
                            NOTE: On this day&nbsp;
                            <strong> 
                                the store closes at { closingTime( selectedDate, calendar ) }
                            </strong>.
                            You will not be able to pick up your order if you arrive
                            later than that.
                        </div>
                    </React.Fragment>
                : selectedDate && minDate ?
                    <p>Something went wrong - there are no opening hours for that day.</p>
                :
                    null
                }

                { message || ! selectedDate || selectedTime === '' || ! minDate ? 
                    <React.Fragment>
                        <div className={ classes.Message } >{ message }</div>
                        <Button onClick={ onCancel } >Cancel</Button>
                    </React.Fragment>
                :
                    <React.Fragment>
                        { infoMessage && 
                            <div className={ classes.Message } >{ infoMessage }</div>
                        }
                        { confirmDateAndTime ?
                            <Modal show rounded onClick={ hideConfirmDateAndTime } >
                                { pickupMessage() }
                                <div>You requested a pickup <strong>{ displayPickupDate() }</strong>
                                    at { displayPickupTime() }
                                    <div className={ classes.OkOrClear } >
                                        <Button onClick={ acceptDateAndTime } >KEEP</Button>
                                        <Button onClick={ hideConfirmDateAndTime } >CHANGE</Button>
                                    </div>
                                </div>
                            </Modal>
                        :
                            <div className={ classes.OkOrClear } >
                                <Button onClick={ showConfirmDateAndTime } >Continue</Button>
                                { cartCount === 0 && 
                                    <Button onClick={ clearDateAndTime } >Clear</Button>
                                }
                            </div>
                        }
                    </React.Fragment>
                }
            </div>
        </Modal>
    )
}

export default PickupDate;