import { post } from './fetch';

const apiPollCheckout = async ( orderId, setCheckoutResult, dispatch ) => {
    post( 'apiPollCheckout', 
        '/api/square/terminal/poll-checkout', 
        { orderId },
        setCheckoutResult,
        dispatch
    );
}

export default apiPollCheckout;
